import { defineStore } from "pinia";
import { removeCachedData } from "../utils/cache";

export const useDashboardStore = defineStore("dashboardStore", {
  state: () => ({
    dashboardembedurl: null,
    dashboards: [],
    dashboardIds: {
      summary: "553fced6-2403-46a4-a04a-16c886fbb825",
      seller: "bcc6a044-250c-4e62-8708-0ba197f2ad05",
      vendor: "7899d9c6-721a-4657-9719-b8902f4701d0",
    },
    embeddedDashboard: null,
    currentDashboardId: null,
    currentSellerId: null,
    currentAccountName: null,
    currentDashboardSheet: "Account Overview",
    startDate: null,
    endDate: null,
    comparisonStartDate: null,
    comparisonEndDate: null,
    userGroups: [],
    currencies: ["GBP", "USD", "EUR", "AUD", "MXN", "SEK"],
    marketplaces: {
      GB: "A1F83G8C2ARO7P",
      FR: "A13V1IB3VIYZZH",
      DE: "A1PA6795UKMFR9",
      IT: "APJ6JRA9NG5V4",
      ES: "A1RKKUPIHCS9HS",
      US: "ATVPDKIKX0DER",
    },
    currency: "GBP",
    currentMarketplace: "A1F83G8C2ARO7P",
  }),
  getters: {},
  actions: {
    setDashboardList(dashboards) {
      this.dashboards = dashboards;
    },
    setUserGroups(groups) {
      groups.forEach((group) => {
        // clean group to get name, type, sellerid
        const formattedGroup = this.cleanGroup(group);
        // check if group exists
        const groupIndex = this.userGroups.findIndex(
          (userGroup) => userGroup.GroupName === formattedGroup.GroupName
        );

        // if group does not exist, add it
        if (groupIndex === -1) {
          // add the group with an empty array for groupMembers
          this.userGroups.push({ ...formattedGroup, groupMembers: [] });
        }
      });
    },
    cleanGroup(group) {
      const GroupName = group.GroupName;
      // Splitting the GroupName by '-'
      const parts = GroupName.split("-");
      // Extracting type, name, and sellerid from the split parts
      const [Type, Name, Sellerid] = parts;
      // Replacing '.' with ' ' in the name
      const formattedName = Name.replace(/\./g, " ");
      // Building the desired output object
      const output = {
        GroupName: GroupName,
        Type: Type,
        FormattedGroupName: formattedName,
        Sellerid: Sellerid,
      };
      return output;
    },
    setGroupMembers(groupName, groupMembers) {
      const groupIndex = this.userGroups.findIndex(
        (userGroup) => userGroup.GroupName === groupName
      );
      if (groupIndex !== -1) {
        // loop through group members
        this.userGroups[groupIndex].groupMembers = [];
        groupMembers.forEach((groupMember) => {
          this.userGroups[groupIndex].groupMembers.push(groupMember);
        });
      }
    },
    deleteGroupMember(groupName, groupMember) {
      const groupIndex = this.userGroups.findIndex(
        (userGroup) => userGroup.GroupName === groupName
      );
      if (groupIndex !== -1) {
        // find index of groupMember
        var memberIndex =
          this.userGroups[groupIndex].groupMembers.indexOf(groupMember);
        // remove from list
        if (memberIndex >= 0) {
          this.userGroups[groupIndex].groupMembers.splice(memberIndex, 1);
        }
      }
    },
    setDashboardEmbedUrl(dashboardembedurl) {
      this.dashboardembedurl = dashboardembedurl;
    },
    setEmbeddedDashboard(embeddedDashboard) {
      this.embeddedDashboard = embeddedDashboard;
    },
    setCurrentDashboardId(currentDashboardId) {
      this.currentDashboardId = currentDashboardId;
    },
    setCurrentSellerId(currentSellerId) {
      this.currentSellerId = currentSellerId;
      removeCachedData("access_token");
    },
    setCurrentAccountName(currentAccountName) {
      this.currentAccountName = currentAccountName;
    },
    setCurrentDashboardSheet(currentDashboardSheet) {
      this.currentDashboardSheet = currentDashboardSheet;
    },
    setEmbeddedDashboardSheets(embeddedDashboardSheets) {
      this.embeddedDashboardSheets = embeddedDashboardSheets;
    },
    setStartDate(startDate) {
      this.startDate = startDate;
    },
    setEndDate(endDate) {
      this.endDate = endDate;
    },
    setComparisonStartDate(comparisonStartDate) {
      this.comparisonStartDate = comparisonStartDate;
    },
    setComparisonEndDate(comparisonEndDate) {
      this.comparisonEndDate = comparisonEndDate;
    },
    setCurrency(currency) {
      this.currency = currency;
    },
    setMarketplace(marketplace) {
      this.currentMarketplace = this.marketplaces[marketplace];
    },
  },
});
