<template>
  <div class="dashboard-container flex flex-col">
    <h3 class="text-left pl-4 pt-2 text-xl">{{ sheetName }}</h3>
    <div class="dashboard" id="dashboard"></div>
  </div>
</template>

<script setup>
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import { getDashboardEmbedUrl } from "@/utils/api";
import { useDashboardStore } from "@/stores/DashboardStore";
import { useDashboardLinksStore } from "@/stores/DashboardLinksStore";
import { getCurrentUserSessionDetails } from "@/utils/auth";
import { onMounted, ref, watch, computed } from "vue";

const dashboardStore = useDashboardStore();
const dashboardLinksStore = useDashboardLinksStore();
const sheetName = ref(dashboardStore.currentDashboardSheet);

// Watch for changes in the currentDashboardSheet value to set the sheet title
watch(
  () => dashboardStore.currentDashboardSheet,
  (newValue) => {
    sheetName.value = newValue;
  }
);

const sleep = async (n) => {
  return new Promise((resolve) => setTimeout(resolve, n * 1000));
};

const embedDashboard = async (frameOptions, contentOptions) => {
  const embeddingContext = await createEmbeddingContext();

  const embeddedDashboardExperience = await embeddingContext.embedDashboard(
    frameOptions,
    contentOptions
  );

  return embeddedDashboardExperience;
};

const setDateParams = (params) => {
  const startDate = params.find((param) => param.Name === "StartDate");
  dashboardStore.setStartDate(startDate.Values[0]);
  const endDate = params.find((param) => param.Name === "EndDate");
  dashboardStore.setEndDate(endDate.Values[0]);
  const comparisonStartDate = params.find(
    (param) => param.Name === "ComparisonPeriodStart"
  );
  dashboardStore.setComparisonStartDate(comparisonStartDate.Values[0]);
  const comparisonEndDate = params.find(
    (param) => param.Name === "ComparisonPeriodEnd"
  );
  dashboardStore.setComparisonEndDate(comparisonEndDate.Values[0]);
};

// Get the sheets in the dashboard and set the links in the store & sidebar
const getDashboardSheets = async (embeddedDashboard, dashboardType) => {
  try {
    const sheetIds = await embeddedDashboard.getSheets();
    dashboardStore.setEmbeddedDashboardSheets(sheetIds);
    dashboardLinksStore.setDashboardLinks(sheetIds, dashboardType);
  } catch (error) {
    console.error(error);
    // Need to add a retry mechanism, for empty links list and errors but without allowing infinite retry loops
  }
};

// Get dashboard params and set date params in the store
const getDashboardParams = async (embeddedDashboard) => {
  try {
    const dashboardParams = await embeddedDashboard.getParameters();
    setDateParams(dashboardParams);
  } catch (error) {
    console.error(error);
  }
};

// Waits for embed URL to be set before calling the function to embed the dashboard
onMounted(async () => {
  // Get User Session Details
  const userSessionDetails = await getCurrentUserSessionDetails();

  // Get the dashboard embed URL
  const dashboardEmbedUrl = await getDashboardEmbedUrl({
    queryParams: userSessionDetails,
  });

  // Set the frame and content options for the embedded dashboard
  const frameOptions = {
    url: dashboardEmbedUrl,
    container: document.getElementById("dashboard"),
    withIframePlaceholder: true,
  };

  const contentOptions = {
    toolbarOptions: {
      export: true,
    },
    sheetOptions: {
      singleSheet: true,
    },
    // eslint-disable-next-line no-unused-vars
    onMessage: async (messageEvent, metadata) => {
      switch (messageEvent.eventName) {
        case "EXPERIENCE_INITIALIZED":
          await sleep(3);
          await getDashboardSheets(embeddedDashboard, dashType.value);
          await getDashboardParams(embeddedDashboard);
          break;
        case "NAVIGATE_TO_DASHBOARD": {
          console.log("Message Event: ", messageEvent);
          await sleep(3);
          await getDashboardSheets(embeddedDashboard, dashType.value);
          await getDashboardParams(embeddedDashboard);
          break;
        }
      }
    },
  };
  dashboardStore.setCurrentDashboardId("bcc6a044-250c-4e62-8708-0ba197f2ad05"); // Set the current dashboard ID with the embedding dash ID
  const dashType = computed(() => {
    return dashboardStore.currentDashboardId ===
      "7899d9c6-721a-4657-9719-b8902f4701d0"
      ? "vendor"
      : "seller";
  });
  const embeddedDashboard = await embedDashboard(frameOptions, contentOptions); // Call the function to embed the Dashboard
  dashboardStore.setEmbeddedDashboard(embeddedDashboard); // Set the embedded dashboard object in the store
});
</script>

<style lang="scss" scoped>
.dashboard,
.dashboard-container {
  height: 100%;
  width: 100%;
  background-color: #eee;
}
</style>
