import { defineStore } from "pinia";

export const useDashboardLinksStore = defineStore("dashboardLinksStore", {
  state: () => ({
    dashboardLinks: {
      seller_analytics: {
        label: "Analytics",
        icon: "analytics",
        dashboardType: ["seller"],
        children: [
          {
            name: "Ads Overview",
            label: "Ads Overview",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Campaign Performance",
            label: "Sponsored Campaigns",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Product Performance",
            label: "Ad Product Performance",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Keywords Performance",
            label: "Keywords",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Brand Performance",
            label: "Ad Brand Performance",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Ad Direction Performance",
            label: "Ad Direction",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Traffic & Conversion",
            label: "Traffic & Conversion",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Account Overview",
            label: "Account Overview",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Channel Profit",
            label: "Channel Profit",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller"],
          },
          {
            name: "Product Profit",
            label: "Product Profit",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller"],
          },
          {
            name: "Sales Overview",
            label: "Sales",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
        ],
      },
      vendor_analytics: {
        label: "Analytics",
        icon: "analytics",
        dashboardType: ["vendor"],
        children: [
          {
            name: "Ads Overview",
            label: "Ads Overview",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Campaign Performance",
            label: "Sponsored Campaigns",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Product Performance",
            label: "Ad Product Performance",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Keywords Performance",
            label: "Keywords",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Brand Performance",
            label: "Ad Brand Performance",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Ad Direction Performance",
            label: "Ad Direction",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Traffic & Conversion",
            label: "Traffic & Conversion",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Account Overview",
            label: "Account Overview",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Sales Overview",
            label: "Sales",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
        ],
      },
      catalog: {
        label: "Catalog",
        icon: "menu_book",
        type: "catalog",
        dashboardType: ["seller"],
        children: [
          {
            name: "Catalog",
            label: "Catalog",
            icon: "home",
            type: "catalog",
            dashboardType: ["seller", "vendor"],
          },
        ],
      },
      reporting: {
        label: "Reporting",
        icon: "analytics",
        dashboardType: ["seller", "vendor"],
        children: [],
      },
      overview: {
        label: "Overview",
        icon: "public",
        dashboardType: ["seller"],
        children: [],
      },
      strategy: {
        label: "Strategy",
        icon: "query_stats",
        dashboardType: ["seller", "vendor"],
        children: [
          {
            name: "Data Explorer",
            label: "Data Explorer",
            icon: "home",
            type: "explorer",
            dashboardType: ["seller", "vendor"],
          },
        ],
      },
      seller_stock: {
        label: "Stock Forecasting",
        icon: "inventory",
        dashboardType: ["seller"],
        children: [],
      },
      vendor_stock: {
        label: "Stock Forecasting",
        icon: "inventory",
        dashboardType: ["vendor"],
        children: [
          {
            name: "Stock Forecast",
            label: "Forecast",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Customer Returns",
            label: "Returns",
            icon: "home",
            type: "sheet",
            dashboardType: ["seller", "vendor"],
          },
          {
            name: "Purchase Orders",
            label: "Purchase Orders",
            icon: "home",
            type: "sheet",
            dashboardType: ["vendor"],
          },
          {
            name: "Chargebacks & Disputes",
            label: "Chargebacks & Disputes",
            icon: "home",
            dashboardType: ["vendor"],
          },
        ],
      },
    },
  }),
  getters: {
    sellerLinks() {
      // Filter links based on the dashboardType
      return this.dashboardLinks.seller_analytics.children;
    },
    vendorLinks() {
      // Filter links based on the dashboardType
      return this.dashboardLinks.vendor_analytics.children;
    },
  },
  actions: {
    setDashboardLinks(sheetIds, dashboard) {
      const updateLinkSheetId = (links, sheet) => {
        links.forEach((link) => {
          if (link.type === "sheet" && link.name === sheet.Name) {
            link.sheetId = sheet.SheetId;
          }
        });
      };
      const dashLinks =
        dashboard === "vendor" ? this.vendorLinks : this.sellerLinks;

      sheetIds.forEach((sheet) => {
        updateLinkSheetId(dashLinks, sheet);
      });
    },
  },
});
