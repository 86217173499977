import { defineStore } from "pinia";

export const useDataExplorerStore = defineStore("dataExplorerStore", {
  state: () => ({
    metrics: {
      sellers: {
        ordered_revenue: {
          column: "item-price",
          scope: "asin",
          type: "currency",
          disabled: false,
          table: "seller_orders",
        },
        ordered_units: {
          column: "quantity",
          scope: "asin",
          type: "integer",
          disabled: false,
          table: "seller_orders",
        },
        shipping_revenue: {
          column: "shipping-price",
          scope: "asin",
          type: "currency",
          disabled: false,
          table: "seller_orders",
        },
        gift_wrap_revenue: {
          column: "gift-wrap-price",
          scope: "asin",
          type: "currency",
          disabled: false,
          table: "seller_orders",
        },
        sp_asin_ad_spend: {
          column: "cost",
          joincolumn: "advertisedasin",
          scope: "asin",
          type: "currency",
          disabled: false,
          table: "sp_adproduct",
        },
        sp_campaign_ad_spend: {
          column: "cost",
          joincolumn: "campaignid",
          scope: "campaign",
          type: "currency",
          disabled: false,
          table: "sp_campaign",
        },
        pageviews: {
          column: "trafficbyasin.pageviews",
          joincolumn: "childasin",
          scope: "asin",
          type: "integer",
          disabled: false,
          table: "seller_traffic",
        },
      },
    },
    datagroup_data: {
      sellers: {
        account: {
          datagroup: "seller",
          table: "sellers",
          table_alias: "s",
          filters: ["sellerid", "marketplaceid"],
          metrics: {
            new_to_brand_orders: {
              name: "num_orders",
              composite: false,
            },
            ordered_revenue: {
              name: "item-price",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "quantity",
              composite: false,
            },
            shipping_revenue: {
              name: "shipping-price",
              type: "currency",
              composite: false,
            },
            gift_wrap_revenue: {
              name: "gift-wrap-price",
              type: "currency",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            pageviews: {
              name: "trafficbyasin.pageviews",
              composite: false,
            },
            browser_page_views: {
              name: "trafficbyasin.browserpageviews",
              composite: false,
            },
            mobile_page_views: {
              name: "trafficbyasin.mobilepageviews",
              composite: false,
            },
            afn_total_quantity: {
              name: "afn_total_quantity",
              composite: false,
            },
            shipped_product_sales: {
              name: "shipped_product_sales",
              type: "currency",
              composite: false,
            },
            other_income: {
              name: "other_income",
              type: "currency",
              composite: false,
            },
            promotion_income: {
              name: "promotion",
              type: "currency",
              composite: false,
            },
            refunds: {
              name: "refunds",
              type: "currency",
              composite: false,
            },
            reimbursements: {
              name: "reimbursements",
              type: "currency",
              composite: false,
            },
            fulfilment_and_shipping: {
              name: "fulfilment_and_shipping",
              type: "currency",
              composite: false,
            },
            selling_fees: {
              name: "selling_fees",
              type: "currency",
              composite: false,
            },
            returns_and_refunds: {
              name: "returns_and_refunds",
              type: "currency",
              composite: false,
            },
            sales_tax: {
              name: "sales_tax",
              type: "currency",
              composite: false,
            },
            other_income_tax: {
              name: "other_income_tax",
              type: "currency",
              composite: false,
            },
            ad_cost: {
              name: "right_ad_cost_per_sku",
              type: "currency",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
        ad_type: {
          datagroup: "campaign_type",
          formatted_name: "Ad Type",
          table: "sponsored_campaigns",
          table_alias: "s",
          metrics: {
            clicks: {
              name: "clicks",
              formatted_name: "Clicks",
              type: "integer",
            },
            impressions: {
              name: "impressions",
              formatted_name: "Impressions",
              type: "integer",
            },
            ad_sales: {
              name: "ad_sales",
              formatted_name: "Ad Sales",
              type: "currency",
            },
            ad_spend: {
              name: "cost",
              formatted_name: "Ad Spend",
              type: "currency",
            },
            acos: {
              name: "acos",
              metrics: ["ad_spend", "ad_sales"],
              operation: "division",
              composite: true,
            },
            roas: {
              name: "roas",
              metrics: ["ad_sales", "ad_spend"],
              operation: "division",
              composite: true,
            },
            click_through_rate: {
              name: "click_through_rate",
              metrics: ["clicks", "impressions"],
              operation: "division",
              composite: true,
            },
            cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["ad_spend", "clicks"],
              operation: "division",
              composite: true,
            },
            ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["ad_sales", "clicks"],
              operation: "division",
              composite: true,
            },
          },
        },
        search_term: {
          datagroup: "searchterm",
          formatted_name: "Search Term",
          table: "sp_searchterm",
          table_alias: "s",
          metrics: {
            clicks: {
              name: "clicks",
              formatted_name: "Clicks",
              type: "integer",
            },
            impressions: {
              name: "impressions",
              formatted_name: "Impressions",
              type: "integer",
            },
            ad_sales: {
              name: "sales30d",
              formatted_name: "Ad Sales",
              type: "currency",
            },
            ad_spend: {
              name: "cost",
              formatted_name: "Ad Spend",
              type: "currency",
            },
          },
        },
        asin: {
          datagroup: "asin",
          table: "products",
          table_alias: "p",
          filters: [
            "sellerid",
            "asin",
            " brand",
            "marketplaceid",
            "websitedisplaygroupname",
            "manufacturer",
            "sku",
            "itemclassification",
          ],
          metrics: {
            ordered_revenue: {
              name: "item-price",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "quantity",
              composite: false,
            },
            shipping_revenue: {
              name: "shipping-price",
              type: "currency",
              composite: false,
            },
            gift_wrap_revenue: {
              name: "gift-wrap-price",
              type: "currency",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            pageviews: {
              name: "trafficbyasin.pageviews",
              composite: false,
            },
            browser_page_views: {
              name: "trafficbyasin.browserpageviews",
              composite: false,
            },
            mobile_page_views: {
              name: "trafficbyasin.mobilepageviews",
              composite: false,
            },
            afn_total_quantity: {
              name: "afn_total_quantity",
              composite: false,
            },
            shipped_product_sales: {
              name: "shipped_product_sales",
              type: "currency",
              composite: false,
            },
            other_income: {
              name: "other_income",
              type: "currency",
              composite: false,
            },
            promotion_income: {
              name: "promotion",
              type: "currency",
              composite: false,
            },
            refunds: {
              name: "refunds",
              type: "currency",
              composite: false,
            },
            reimbursements: {
              name: "reimbursements",
              type: "currency",
              composite: false,
            },
            fulfilment_and_shipping: {
              name: "fulfilment_and_shipping",
              type: "currency",
              composite: false,
            },
            selling_fees: {
              name: "selling_fees",
              type: "currency",
              composite: false,
            },
            returns_and_refunds: {
              name: "returns_and_refunds",
              type: "currency",
              composite: false,
            },
            sales_tax: {
              name: "sales_tax",
              type: "currency",
              composite: false,
            },
            other_income_tax: {
              name: "other_income_tax",
              type: "currency",
              composite: false,
            },
            ad_cost: {
              name: "right_ad_cost_per_sku",
              type: "currency",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
        brand: {
          datagroup: "brand",
          table: "products",
          table_alias: "p",
          filters: [
            "sellerid",
            "asin",
            "brand",
            "marketplaceid",
            "websitedisplaygroupname",
            "manufacturer",
            "sku",
            "itemclassification",
          ],
          metrics: {
            ordered_revenue: {
              name: "item-price",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "quantity",
              composite: false,
            },
            shipping_revenue: {
              name: "shipping-price",
              type: "currency",
              composite: false,
            },
            gift_wrap_revenue: {
              name: "gift-wrap-price",
              type: "currency",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            pageviews: {
              name: "trafficbyasin.pageviews",
              composite: false,
            },
            browser_page_views: {
              name: "trafficbyasin.browserpageviews",
              composite: false,
            },
            mobile_page_views: {
              name: "trafficbyasin.mobilepageviews",
              composite: false,
            },
            afn_total_quantity: {
              name: "afn_total_quantity",
              composite: false,
            },
            shipped_product_sales: {
              name: "shipped_product_sales",
              type: "currency",
              composite: false,
            },
            other_income: {
              name: "other_income",
              type: "currency",
              composite: false,
            },
            promotion_income: {
              name: "promotion",
              type: "currency",
              composite: false,
            },
            refunds: {
              name: "refunds",
              type: "currency",
              composite: false,
            },
            reimbursements: {
              name: "reimbursements",
              type: "currency",
              composite: false,
            },
            fulfilment_and_shipping: {
              name: "fulfilment_and_shipping",
              type: "currency",
              composite: false,
            },
            selling_fees: {
              name: "selling_fees",
              type: "currency",
              composite: false,
            },
            returns_and_refunds: {
              name: "returns_and_refunds",
              type: "currency",
              composite: false,
            },
            sales_tax: {
              name: "sales_tax",
              type: "currency",
              composite: false,
            },
            other_income_tax: {
              name: "other_income_tax",
              type: "currency",
              composite: false,
            },
            ad_cost: {
              name: "right_ad_cost_per_sku",
              type: "currency",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
        category: {
          datagroup: "websitedisplaygroupname",
          table: "products",
          table_alias: "p",
          filters: [
            "sellerid",
            "asin",
            "brand",
            "marketplaceid",
            "websitedisplaygroupname",
            "manufacturer",
            "sku",
            "itemclassification",
          ],
          metrics: {
            ordered_revenue: {
              name: "item-price",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "quantity",
              composite: false,
            },
            shipping_revenue: {
              name: "shipping-price",
              type: "currency",
              composite: false,
            },
            gift_wrap_revenue: {
              name: "gift-wrap-price",
              type: "currency",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            pageviews: {
              name: "trafficbyasin.pageviews",
              composite: false,
            },
            browser_page_views: {
              name: "trafficbyasin.browserpageviews",
              composite: false,
            },
            mobile_page_views: {
              name: "trafficbyasin.mobilepageviews",
              composite: false,
            },
            afn_total_quantity: {
              name: "afn_total_quantity",
              composite: false,
            },
            shipped_product_sales: {
              name: "shipped_product_sales",
              type: "currency",
              composite: false,
            },
            other_income: {
              name: "other_income",
              type: "currency",
              composite: false,
            },
            promotion_income: {
              name: "promotion",
              type: "currency",
              composite: false,
            },
            refunds: {
              name: "refunds",
              type: "currency",
              composite: false,
            },
            reimbursements: {
              name: "reimbursements",
              type: "currency",
              composite: false,
            },
            fulfilment_and_shipping: {
              name: "fulfilment_and_shipping",
              type: "currency",
              composite: false,
            },
            selling_fees: {
              name: "selling_fees",
              type: "currency",
              composite: false,
            },
            returns_and_refunds: {
              name: "returns_and_refunds",
              type: "currency",
              composite: false,
            },
            sales_tax: {
              name: "sales_tax",
              type: "currency",
              composite: false,
            },
            other_income_tax: {
              name: "other_income_tax",
              type: "currency",
              composite: false,
            },
            ad_cost: {
              name: "right_ad_cost_per_sku",
              type: "currency",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
        subcategory: {
          datagroup: "browseclassification_displayname",
          table: "products",
          table_alias: "p",
          filters: [
            "sellerid",
            "asin",
            "brand",
            "marketplaceid",
            "websitedisplaygroupname",
            "manufacturer",
            "sku",
            "itemclassification",
          ],
          metrics: {
            ordered_revenue: {
              name: "item-price",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "quantity",
              composite: false,
            },
            shipping_revenue: {
              name: "shipping-price",
              type: "currency",
              composite: false,
            },
            gift_wrap_revenue: {
              name: "gift-wrap-price",
              type: "currency",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            pageviews: {
              name: "trafficbyasin.pageviews",
              composite: false,
            },
            browser_page_views: {
              name: "trafficbyasin.browserpageviews",
              composite: false,
            },
            mobile_page_views: {
              name: "trafficbyasin.mobilepageviews",
              composite: false,
            },
            afn_total_quantity: {
              name: "afn_total_quantity",
              composite: false,
            },
            shipped_product_sales: {
              name: "shipped_product_sales",
              type: "currency",
              composite: false,
            },
            other_income: {
              name: "other_income",
              type: "currency",
              composite: false,
            },
            promotion_income: {
              name: "promotion",
              type: "currency",
              composite: false,
            },
            refunds: {
              name: "refunds",
              type: "currency",
              composite: false,
            },
            reimbursements: {
              name: "reimbursements",
              type: "currency",
              composite: false,
            },
            fulfilment_and_shipping: {
              name: "fulfilment_and_shipping",
              type: "currency",
              composite: false,
            },
            selling_fees: {
              name: "selling_fees",
              type: "currency",
              composite: false,
            },
            returns_and_refunds: {
              name: "returns_and_refunds",
              type: "currency",
              composite: false,
            },
            sales_tax: {
              name: "sales_tax",
              type: "currency",
              composite: false,
            },
            other_income_tax: {
              name: "other_income_tax",
              type: "currency",
              composite: false,
            },
            ad_cost: {
              name: "right_ad_cost_per_sku",
              type: "currency",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
      },
      vendors: {
        account: {
          datagroup: "seller",
          table: "sellers",
          table_alias: "s",
          filters: ["sellerid", "marketplaceid"],
          metrics: {
            ordered_revenue: {
              name: "orderedrevenue_amount",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "orderedunits",
              composite: false,
            },
            shipped_cogs: {
              name: "shipppedcogs_amount",
              type: "currency",
              composite: false,
            },
            shipped_revenue: {
              name: "shippedrevenue_amount",
              type: "currency",
              composite: false,
            },
            shipped_units: {
              name: "shippedunits",
              composite: false,
            },
            customer_returns: {
              name: "customerreturns",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            glance_views: {
              name: "glanceviews",
              composite: false,
            },
            net_received_inventory_cost: {
              name: "netreceivedinventorycost_amount",
              composite: false,
            },
            net_received_inventory_units: {
              name: "netreceivedinventoryunits",
              composite: false,
            },
            open_purchase_order_units: {
              name: "openpurchaseorderunits",
              composite: false,
            },
            unfilled_customer_order_units: {
              name: "unfilledcustomerorderunits",
              composite: false,
            },
            sellable_onhand_inventory_units: {
              name: "sellableonhandinventoryunits",
              composite: false,
            },
            sellable_onhand_inventory_cost: {
              name: "sellableonhandinventorycost_amount",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_units: {
              name: "aged90plussellableinventoryunits",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_cost: {
              name: "aged90plussellableinventorycost_amount",
              composite: false,
            },
            unhealthy_inventory_cost: {
              name: "unhealthyinventorycost_amount",
              composite: false,
            },
            p70_forecasted_units: {
              name: "p70forecastunits",
              composite: false,
            },
            p80_forecasted_units: {
              name: "p80forecastunits",
              composite: false,
            },
            p90_forecasted_units: {
              name: "p90forecastunits",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
        ad_type: {
          datagroup: "campaign_type",
          formatted_name: "Ad Type",
          table: "sponsored_campaigns",
          table_alias: "s",
          metrics: {
            clicks: {
              name: "clicks",
              formatted_name: "Clicks",
              type: "integer",
            },
            impressions: {
              name: "impressions",
              formatted_name: "Impressions",
              type: "integer",
            },
            ad_sales: {
              name: "ad_sales",
              formatted_name: "Ad Sales",
              type: "currency",
            },
            ad_spend: {
              name: "cost",
              formatted_name: "Ad Spend",
              type: "currency",
            },
          },
        },
        search_term: {
          datagroup: "searchterm",
          formatted_name: "Search Term",
          table: "sp_searchterm",
          table_alias: "s",
          metrics: {
            clicks: {
              name: "clicks",
              formatted_name: "Clicks",
              type: "integer",
            },
            impressions: {
              name: "impressions",
              formatted_name: "Impressions",
              type: "integer",
            },
            ad_sales: {
              name: "sales30d",
              formatted_name: "Ad Sales",
              type: "currency",
            },
            ad_spend: {
              name: "cost",
              formatted_name: "Ad Spend",
              type: "currency",
            },
          },
        },
        asin: {
          datagroup: "asin",
          table: "products",
          table_alias: "p",
          filters: [
            "sellerid",
            "asin",
            " brand",
            "marketplaceid",
            "websitedisplaygroupname",
            "manufacturer",
            "sku",
            "itemclassification",
          ],
          metrics: {
            ordered_revenue: {
              name: "orderedrevenue_amount",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "orderedunits",
              composite: false,
            },
            shipping_revenue: {
              name: "shippedrevenue_amount",
              type: "currency",
              composite: false,
            },
            shipped_units: {
              name: "shippedunits",
              type: "integer",
              composite: false,
            },
            shipped_cogs: {
              name: "shippedcogs_amount",
              type: "currency",
              composite: false,
            },
            customer_returns: {
              name: "customerreturns",
              type: "integer",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            glanceviews: {
              name: "glanceviews",
              composite: false,
            },
            net_received_inventory_cost: {
              name: "netreceivedinventorycost_amount",
              type: "currency",
              composite: false,
            },
            net_received_inventory_units: {
              name: "netreceivedinventoryunits",
              composite: false,
            },
            open_purchase_order_units: {
              name: "openpurchaseorderunits",
              composite: false,
            },
            unfilled_customer_order_units: {
              name: "unfilledcustomerorderedunits",
              composite: false,
            },
            sellable_onhand_inventory_units: {
              name: "sellableonhandinventoryunits",
              composite: false,
            },
            sellable_onhand_inventory_cost: {
              name: "sellableonhandinventorycost_amount",
              type: "currency",
              composite: false,
            },
            unsellable_onhand_inventory_units: {
              name: "unsellableonhandinventoryunits",
              composite: false,
            },
            unsellable_onhand_inventory_cost: {
              name: "unsellableonhandinventorycost_amount",
              type: "currency",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_units: {
              name: "aged90plussellableinventoryunits",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_cost: {
              name: "aged90plussellableinventorycost_amount",
              type: "currency",
              composite: false,
            },
            unhealthy_inventory_units: {
              name: "unhealthyinventoryunits",
              composite: false,
            },
            unhealthy_inventory_cost: {
              name: "unhealthyinventorycost_amount",
              type: "currency",
              composite: false,
            },
            p70_forecasted_units: {
              name: "p70forecastunits",
              composite: false,
            },
            p80_forecasted_units: {
              name: "p80forecastunits",
              composite: false,
            },
            p90_forecasted_units: {
              name: "p90forecastunits",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
        brand: {
          datagroup: "brand",
          table: "products",
          table_alias: "p",
          filters: [
            "sellerid",
            "asin",
            "brand",
            "marketplaceid",
            "websitedisplaygroupname",
            "manufacturer",
            "sku",
            "itemclassification",
          ],
          metrics: {
            ordered_revenue: {
              name: "orderedrevenue_amount",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "orderedunits",
              composite: false,
            },
            shipping_revenue: {
              name: "shippedrevenue_amount",
              type: "currency",
              composite: false,
            },
            shipped_units: {
              name: "shippedunits",
              type: "integer",
              composite: false,
            },
            shipped_cogs: {
              name: "shippedcogs_amount",
              type: "currency",
              composite: false,
            },
            customer_returns: {
              name: "customerreturns",
              type: "integer",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            glanceviews: {
              name: "glanceviews",
              composite: false,
            },
            net_received_inventory_cost: {
              name: "netreceivedinventorycost_amount",
              type: "currency",
              composite: false,
            },
            net_received_inventory_units: {
              name: "netreceivedinventoryunits",
              composite: false,
            },
            open_purchase_order_units: {
              name: "openpurchaseorderunits",
              composite: false,
            },
            unfilled_customer_order_units: {
              name: "unfilledcustomerorderedunits",
              composite: false,
            },
            sellable_onhand_inventory_units: {
              name: "sellableonhandinventoryunits",
              composite: false,
            },
            sellable_onhand_inventory_cost: {
              name: "sellableonhandinventorycost_amount",
              type: "currency",
              composite: false,
            },
            unsellable_onhand_inventory_units: {
              name: "unsellableonhandinventoryunits",
              composite: false,
            },
            unsellable_onhand_inventory_cost: {
              name: "unsellableonhandinventorycost_amount",
              type: "currency",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_units: {
              name: "aged90plussellableinventoryunits",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_cost: {
              name: "aged90plussellableinventorycost_amount",
              type: "currency",
              composite: false,
            },
            unhealthy_inventory_units: {
              name: "unhealthyinventoryunits",
              composite: false,
            },
            unhealthy_inventory_cost: {
              name: "unhealthyinventorycost_amount",
              type: "currency",
              composite: false,
            },
            p70_forecasted_units: {
              name: "p70forecastunits",
              composite: false,
            },
            p80_forecasted_units: {
              name: "p80forecastunits",
              composite: false,
            },
            p90_forecasted_units: {
              name: "p90forecastunits",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
        category: {
          datagroup: "websitedisplaygroupname",
          table: "products",
          table_alias: "p",
          filters: [
            "sellerid",
            "asin",
            "brand",
            "marketplaceid",
            "websitedisplaygroupname",
            "manufacturer",
            "sku",
            "itemclassification",
          ],
          metrics: {
            ordered_revenue: {
              name: "orderedrevenue_amount",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "orderedunits",
              composite: false,
            },
            shipping_revenue: {
              name: "shippedrevenue_amount",
              type: "currency",
              composite: false,
            },
            shipped_units: {
              name: "shippedunits",
              type: "integer",
              composite: false,
            },
            shipped_cogs: {
              name: "shippedcogs_amount",
              type: "currency",
              composite: false,
            },
            customer_returns: {
              name: "customerreturns",
              type: "integer",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            glanceviews: {
              name: "glanceviews",
              composite: false,
            },
            net_received_inventory_cost: {
              name: "netreceivedinventorycost_amount",
              type: "currency",
              composite: false,
            },
            net_received_inventory_units: {
              name: "netreceivedinventoryunits",
              composite: false,
            },
            open_purchase_order_units: {
              name: "openpurchaseorderunits",
              composite: false,
            },
            unfilled_customer_order_units: {
              name: "unfilledcustomerorderedunits",
              composite: false,
            },
            sellable_onhand_inventory_units: {
              name: "sellableonhandinventoryunits",
              composite: false,
            },
            sellable_onhand_inventory_cost: {
              name: "sellableonhandinventorycost_amount",
              type: "currency",
              composite: false,
            },
            unsellable_onhand_inventory_units: {
              name: "unsellableonhandinventoryunits",
              composite: false,
            },
            unsellable_onhand_inventory_cost: {
              name: "unsellableonhandinventorycost_amount",
              type: "currency",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_units: {
              name: "aged90plussellableinventoryunits",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_cost: {
              name: "aged90plussellableinventorycost_amount",
              type: "currency",
              composite: false,
            },
            unhealthy_inventory_units: {
              name: "unhealthyinventoryunits",
              composite: false,
            },
            unhealthy_inventory_cost: {
              name: "unhealthyinventorycost_amount",
              type: "currency",
              composite: false,
            },
            p70_forecasted_units: {
              name: "p70forecastunits",
              composite: false,
            },
            p80_forecasted_units: {
              name: "p80forecastunits",
              composite: false,
            },
            p90_forecasted_units: {
              name: "p90forecastunits",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
        subcategory: {
          datagroup: "browseclassification_displayname",
          table: "products",
          table_alias: "p",
          filters: [
            "sellerid",
            "asin",
            "brand",
            "marketplaceid",
            "websitedisplaygroupname",
            "manufacturer",
            "sku",
            "itemclassification",
          ],
          metrics: {
            ordered_revenue: {
              name: "orderedrevenue_amount",
              type: "currency",
              composite: false,
            },
            ordered_units: {
              name: "orderedunits",
              composite: false,
            },
            shipping_revenue: {
              name: "shippedrevenue_amount",
              type: "currency",
              composite: false,
            },
            shipped_units: {
              name: "shippedunits",
              type: "integer",
              composite: false,
            },
            shipped_cogs: {
              name: "shippedcogs_amount",
              type: "currency",
              composite: false,
            },
            customer_returns: {
              name: "customerreturns",
              type: "integer",
              composite: false,
            },
            sp_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sp_ad_sales: {
              name: "sales30d",
              type: "currency",
              composite: false,
            },
            sp_ad_unit_sales: {
              name: "unitsSoldClicks14d",
              composite: false,
            },
            sp_purchases: {
              name: "purchases30d",
              composite: false,
            },
            sp_clicks: {
              name: "clicks",
              composite: false,
            },
            sp_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_ad_spend: {
              name: "cost",
              type: "currency",
              composite: false,
            },
            sd_ad_sales: {
              name: "sales",
              type: "currency",
              composite: false,
            },
            sd_ad_unit_sales: {
              name: "unitsSoldClicks",
              composite: false,
            },
            sd_purchases: {
              name: "purchases",
              composite: false,
            },
            sd_clicks: {
              name: "clicks",
              composite: false,
            },
            sd_impressions: {
              name: "impressions",
              composite: false,
            },
            sd_video_complete_views: {
              name: "videoCompleteViews",
              composite: false,
            },
            sd_video_first_quartile_views: {
              name: "videoFirstQuartileViews",
              composite: false,
            },
            sd_video_midpoint_views: {
              name: "videoMidpointViews",
              composite: false,
            },
            sd_video_third_quartile_views: {
              name: "videoThirdQuartileViews",
              composite: false,
            },
            sd_new_to_brand_purchases: {
              name: "newToBrandPurchases",
              composite: false,
            },
            sd_new_to_brand_sales: {
              name: "newToBrandSales",
              composite: false,
            },
            sd_detail_page_views: {
              name: "detailPageViews",
              composite: false,
            },
            glanceviews: {
              name: "glanceviews",
              composite: false,
            },
            net_received_inventory_cost: {
              name: "netreceivedinventorycost_amount",
              type: "currency",
              composite: false,
            },
            net_received_inventory_units: {
              name: "netreceivedinventoryunits",
              composite: false,
            },
            open_purchase_order_units: {
              name: "openpurchaseorderunits",
              composite: false,
            },
            unfilled_customer_order_units: {
              name: "unfilledcustomerorderedunits",
              composite: false,
            },
            sellable_onhand_inventory_units: {
              name: "sellableonhandinventoryunits",
              composite: false,
            },
            sellable_onhand_inventory_cost: {
              name: "sellableonhandinventorycost_amount",
              type: "currency",
              composite: false,
            },
            unsellable_onhand_inventory_units: {
              name: "unsellableonhandinventoryunits",
              composite: false,
            },
            unsellable_onhand_inventory_cost: {
              name: "unsellableonhandinventorycost_amount",
              type: "currency",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_units: {
              name: "aged90plussellableinventoryunits",
              composite: false,
            },
            aged_90plus_days_sellable_inventory_cost: {
              name: "aged90plussellableinventorycost_amount",
              type: "currency",
              composite: false,
            },
            unhealthy_inventory_units: {
              name: "unhealthyinventoryunits",
              composite: false,
            },
            unhealthy_inventory_cost: {
              name: "unhealthyinventorycost_amount",
              type: "currency",
              composite: false,
            },
            p70_forecasted_units: {
              name: "p70forecastunits",
              composite: false,
            },
            p80_forecasted_units: {
              name: "p80forecastunits",
              composite: false,
            },
            p90_forecasted_units: {
              name: "p90forecastunits",
              composite: false,
            },
            sp_acos: {
              name: "acos",
              metrics: ["sp_ad_spend", "sp_ad_sales"],
              operation: "division",
              composite: true,
            },
            sp_tacos: {
              name: "tacos",
              metrics: ["sp_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sp_roas: {
              name: "roas",
              metrics: ["sp_ad_sales", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sp_ad_spend"],
              operation: "division",
              composite: true,
            },
            sp_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sp_clicks", "sp_impressions"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_sales_per_click: {
              name: "ad_sales_per_click",
              metrics: ["sp_ad_sales", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_ad_conversion_rate: {
              name: "ad_conversion_rate",
              metrics: ["sp_purchases", "sp_clicks"],
              operation: "division",
              composite: true,
            },
            sp_cost_per_acquisition: {
              name: "cost_per_acquisition",
              type: "currency",
              metrics: ["sp_ad_spend", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_purchases"],
              operation: "division",
              composite: true,
            },
            sp_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sp_ad_sales", "sp_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            sd_acos: {
              name: "acos",
              metrics: ["sd_ad_spend", "sd_ad_sales"],
              operation: "division",
              composite: true,
            },
            sd_tacos: {
              name: "tacos",
              metrics: ["sd_ad_spend", "ordered_revenue"],
              operation: "division",
              composite: true,
            },
            sd_roas: {
              name: "roas",
              metrics: ["sd_ad_sales", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_troas: {
              name: "troas",
              metrics: ["ordered_revenue", "sd_ad_spend"],
              operation: "division",
              composite: true,
            },
            sd_click_through_rate: {
              name: "click_through_rate",
              metrics: ["sd_clicks", "sd_impressions"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_click: {
              name: "cost_per_click",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_sales_per_click: {
              name: "sd_ad_sales_per_click",
              metrics: ["sd_ad_sales", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_ad_conversion_rate: {
              name: "sd_ad_conversion_rate",
              metrics: ["sd_purchases", "sd_clicks"],
              operation: "division",
              composite: true,
            },
            sd_cost_per_acquisition: {
              name: "sd_cost_per_acquisition",
              type: "currency",
              metrics: ["sd_ad_spend", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_purchases"],
              operation: "division",
              composite: true,
            },
            sd_average_selling_price: {
              name: "average_selling_price",
              type: "currency",
              metrics: ["sd_ad_sales", "sd_ad_unit_sales"],
              operation: "division",
              composite: true,
            },
            average_order_value: {
              name: "average_order_value",
              type: "currency",
              metrics: ["ordered_revenue", "ordered_units"],
              operation: "division",
              composite: true,
            },
          },
        },
      },
    },
    data: null,
    ASINS: [],
    "ASIN SETS": [],
    BRANDS: [],
    CATEGORIES: [],
    SUBCATEGORIES: [],
    SKUS: [],
    RAW_ASIN_SETS: [],
  }),
  actions: {
    setExplorerData(data) {
      this.data = data;
    },
    setAsins(asins) {
      this.ASINS = asins;
    },
    setAsinSets(asinSets) {
      this.RAW_ASIN_SETS = asinSets;
    },
    // filters the asin sets for the selected sellerid
    setAsinSetsForSellerid(asinSets, sellerid) {
      // check asinsets type
      const asinSetArray = Array.isArray(asinSets)
        ? asinSets
        : Object.values(asinSets);

      // Filter the asinSets array to include only objects with the specified sellerId
      const filteredAsinSets = asinSetArray.filter(
        (asinSet) => asinSet.sellerId === sellerid
      );

      // Transform the filtered array into the desired format
      const result = {};
      filteredAsinSets.forEach((asinSet) => {
        result[asinSet.asinSetName] = asinSet.asins;
      });
      this["ASIN SETS"] = result;
    },
    setBrands(brands) {
      this.BRANDS = brands;
    },
    setCategories(categories) {
      this.CATEGORIES = categories;
    },
    setSubcategories(subcategories) {
      this.SUBCATEGORIES = subcategories;
    },
    setSkus(skus) {
      this.SKUS = skus;
    },
  },
});
