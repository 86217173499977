<template>
  <div class="flex justify-center items-center h-screen">
    <div class="w-full max-w-lg p-8 bg-white shadow-lg rounded-md">
      <div v-if="loading" class="text-center">
        <p>Processing authorization...</p>
      </div>
      <div v-else>
        <div v-if="error" class="text-center">
          <h2 class="text-red-500 font-bold text-2xl mb-4">ERROR!</h2>
          <p class="text-gray-700 mb-6">{{ error }}</p>
          <button
            class="bg-red-500 text-white py-2 px-4 rounded-full"
            @click="retryAuthorization"
          >
            RETRY
          </button>
        </div>
        <div v-else class="text-center">
          <h2 class="text-green-500 font-bold text-2xl mb-4">SUCCESS!</h2>
          <p class="text-gray-700 mb-6">
            You have successfully connected a new account! Your data will be
            available in 3-5 business days.
          </p>
          <button
            class="bg-across-purple text-white py-2 px-4 rounded-full"
            @click="goToMyAccounts"
          >
            MY ACCOUNTS
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getCachedData } from "../utils/cache";
import {
  getCurrentUserSessionDetails,
  authoriseClientAccount,
} from "../utils/auth";

const router = useRouter();
const loading = ref(true);
const error = ref(null);
const clientName = ref(getCachedData("client_name"));
const activeCountries = ref(getCachedData("active_countries"));
const accountType = ref(getCachedData("account_type"));

const sendAuthorizationCodeToBackend = async (
  authorizationCode,
  state,
  sellingPartnerId
) => {
  try {
    // Get user session details
    const userSessionDetails = await getCurrentUserSessionDetails();

    const params = {
      queryParams: {
        username: userSessionDetails.username,
        email: userSessionDetails.email,
        jwtToken: userSessionDetails.jwtToken,
        payloadSub: userSessionDetails.payloadsub,
        clientname: clientName.value,
        accounttype: accountType.value,
        activecountries: activeCountries.value,
        code: authorizationCode,
        state: state,
        sellingPartnerId: sellingPartnerId,
      },
    };

    // Make API call to get product type schema
    const response = await authoriseClientAccount(params);
    return response;
  } catch (error) {
    error.value = "Failed to process authorization. Please try again.";
  }
};

const retryAuthorization = () => {
  router.push("/add-client");
};

const goToMyAccounts = () => {
  router.push("/my-accounts");
};

onMounted(async () => {
  const urlParams = new URLSearchParams(window.location.search);

  const authorizationCode =
    accountType.value === "advertising"
      ? urlParams.get("code")
      : urlParams.get("spapi_oauth_code");

  const state =
    accountType.value === "advertising" ? null : urlParams.get("state");

  const sellingPartnerId =
    accountType.value === "advertising"
      ? null
      : urlParams.get("selling_partner_id");

  const errorDescription = urlParams.get("error_description");

  if (authorizationCode) {
    // Send the authorization code to the backend for token exchange
    await sendAuthorizationCodeToBackend(
      authorizationCode,
      state,
      sellingPartnerId
    );
  } else if (errorDescription) {
    error.value = errorDescription;
  } else {
    error.value = "Authorization code not found in the URL";
  }

  loading.value = false;
});
</script>

<style scoped></style>
