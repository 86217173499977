import { get, put } from "aws-amplify/api";
import { getCurrentUserSessionDetails } from "@/utils/auth";

/**
 * Custom fetcher function to make GET calls to the backend functions through API Gateway
 *
 * @param {string} path - The API endpoint path.
 * @param {object} params - The parameters for the API call.
 * @returns {object|null} - The response from the API call.
 * @throws {Error} - The error from the API call.
 */

async function useCustomFetcher(path, params) {
  try {
    // Get user session details
    const userSessionDetails = await getCurrentUserSessionDetails();

    // Add User Session details to the params
    params.queryParams["username"] = userSessionDetails.username;
    params.queryParams["email"] = userSessionDetails.email;
    params.queryParams["jwtToken"] = userSessionDetails.jwtToken;
    params.queryParams["payloadSub"] = userSessionDetails.payloadsub;
    params.queryParams["organisation"] = userSessionDetails.organisation;

    const data = await get({
      apiName: "quicksightembed",
      path: path,
      options: params,
    });
    // Wait for the promise to resolve and get the response
    const response = await data.response;
    const responseBody = await response.body.json();

    if (response.status_code === 500) {
      throw new Error("Internal Server Error", response.body.json());
    }

    if (responseBody.status_code === 404) {
      return null;
    }

    if (responseBody.status_code === 403) {
      throw new Error("Authorisation Error", responseBody);
    }

    return responseBody;
  } catch (error) {
    console.error("GET call failed: ", error);
    throw error;
  }
}

async function useCustomUpdater(path, params) {
  try {
    // Get user session details
    const userSessionDetails = await getCurrentUserSessionDetails();

    // Add User Session details to the params
    params.queryParams["username"] = userSessionDetails.username;
    params.queryParams["email"] = userSessionDetails.email;
    params.queryParams["jwtToken"] = userSessionDetails.jwtToken;
    params.queryParams["payloadSub"] = userSessionDetails.payloadsub;
    params.queryParams["organisation"] = userSessionDetails.organisation;

    const data = put({
      apiName: "quicksightembed",
      path: path,
      options: params,
    });
    // Wait for the promise to resolve and get the response
    const response = await data.response;

    if (response.statusCode === 500) {
      throw new Error("Internal Server Error", response.body.json());
    }

    return response.body.json();
  } catch (error) {
    console.error("PATCH call failed: ", error);
    throw error;
  }
}

export { useCustomFetcher, useCustomUpdater };
