import { useCustomFetcher } from "./callersForBackendApi";

export async function scanDynamoDb(payload, sellerid) {
  try {
    const params = {
      queryParams: {
        table_name: payload["table_name"],
        filters: JSON.stringify(payload["filters"]),
        sellerid: sellerid,
      },
    };

    const result = await useCustomFetcher("/scanDynamoDb", params);

    if (!result) {
      return [];
    } else {
      return result.body;
    }
  } catch (error) {
    console.error("Error scanning DynamoDB:", error);
  }
}
