<template>
  <div class="p-6">
    <h1 class="text-2xl font-bold mb-4">My Accounts</h1>
    <!-- <div class="flex items-center mb-4">
      <input
        type="text"
        placeholder="Search Account"
        class="flex-grow p-2 border border-gray-300 rounded-l-md"
      />
      <button class="bg-across-purple text-white p-2 rounded-r-md">
        Find Account
      </button>
    </div> -->
    <div class="text-center mt-8">
      <!-- <p class="text-lg font-semibold">No Accounts At The Moment!</p> -->
      <button
        @click="showDialog"
        class="bg-across-purple text-white py-2 px-6 mt-8 rounded-full"
      >
        Connect New Account
      </button>
    </div>
    <AddClientForm v-model:visible="formVisible" />
  </div>
</template>
<script setup>
import { ref } from "vue";
import AddClientForm from "../components/AddClientForm.vue";

const formVisible = ref(false);

const showDialog = () => {
  formVisible.value = true;
};
</script>
<style scoped>
.add-client-page {
  margin-top: 8vh;
  height: 92vh;
  width: 100%;
  display: flex;
}
</style>
