<template>
  <Dialog
    v-model:visible="propsVisible"
    :modal="true"
    :draggable="false"
    class="w-full md:w-2/3 lg:w-1/2"
  >
    <template #header>
      <div class="flex items-center justify-center w-full mb-4">
        <div class="text-center">
          <img
            src="@/assets/AX-black.png"
            alt="Across Analytics"
            class="mx-auto h-20 w-40"
          />
          <p class="text-gray-600">Select your account...</p>
        </div>
      </div>
    </template>
    <div>
      <div class="flex justify-center p-grid">
        <DashboardSelector />
      </div>
      <div class="flex justify-center mt-4">
        <Button
          label="Apply"
          @click="closeDialog"
          class="w-1/5"
          :diasbled="dashboardStore.currentSellerId === null"
          severity="secondary"
        />
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";
import DashboardSelector from "@/components/dashboards/DashboardSelector.vue";
import { useDashboardStore } from "@/stores/DashboardStore";

const props = defineProps(["visible"]);
const emit = defineEmits(["update:visible"]);
const dashboardStore = useDashboardStore();

const propsVisible = ref(props.visible);

const closeDialog = () => {
  propsVisible.value = false;
};

// Watch for changes to the visible prop and update the internal state
watch(
  () => props.visible,
  (newValue) => {
    propsVisible.value = newValue;
  }
);

// Watch for changes to the internal state and emit an update event
watch(propsVisible, (newValue) => {
  emit("update:visible", newValue);
});
</script>

<style scoped></style>
