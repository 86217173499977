import { getCurrentUser } from "aws-amplify/auth";
import { fetchAuthSession } from "aws-amplify/auth";
import { get } from "aws-amplify/api";
import { signOut } from "aws-amplify/auth";

async function currentSession() {
  try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    return { accessToken, idToken };
  } catch (err) {
    console.log(err);
  }
}

async function currentAuthenticatedUser() {
  try {
    const user = await getCurrentUser();
    return !!user; // Returns true if user is authenticated, false otherwise
  } catch (err) {
    console.log(err);
    return false;
  }
}

async function getCurrentUserSessionDetails() {
  const data = await currentSession();

  const userSessionDetails = {
    username: data.accessToken.payload.username,
    jwtToken: data.idToken.toString(),
    payloadSub: data.idToken.payload.sub,
    auth_time: data.idToken.payload.auth_time,
    organisation: data.idToken.payload["custom:Organisation"],
    email: data.accessToken.payload.email,
  };

  // Log out user if authentication time is more than 1 hour ago
  const currentTime = Math.floor(Date.now() / 1000);

  if (currentTime - userSessionDetails.auth_time > 3600) {
    await signOut();
    return false;
  }

  return userSessionDetails;
}

async function authoriseClientAccount(params) {
  const data = await get({
    apiName: "quicksightembed",
    path: "/authoriseClientAccount",
    options: params,
  });
  // Wait for the promise to resolve and get the response
  const response = await data.response;
  const responseBody = await response.body.json();
  return responseBody;
}

export {
  currentSession,
  currentAuthenticatedUser,
  getCurrentUserSessionDetails,
  authoriseClientAccount,
};
