import { get } from "aws-amplify/api";

async function getExplorerData(params) {
  try {
    const data = await get({
      apiName: "quicksightembed",
      path: "/axExplorerQuery",
      options: params,
    });
    // Wait for the promise to resolve and get the response
    const response = (await data.response).body.json();
    return response;
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

async function getFilters(params) {
  try {
    const data = await get({
      apiName: "quicksightembed",
      path: "/explorerFilters",
      options: params,
    });
    // Wait for the promise to resolve and get the response
    const response = (await data.response).body.json();
    return response;
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

export { getExplorerData, getFilters };
