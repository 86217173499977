<template>
  <div class="h-full flex flex-col">
    <button
      class="product-exit flex top-0 left-0 p-2"
      @click="$emit('on-row-unselect')"
    >
      <span class="material-icons">arrow_back</span>
      <span>Back to Catalog</span>
    </button>
    <!-- Panel header has been hidden in the css for the component in presets/lara/panel/index.js -->
    <Panel
      :header="productListing.summaries[0].asin"
      class="flex-shrink-0 px-6"
      toggleable
    >
      <div class="product-header px-6 flex">
        <div>
          <img
            :src="productListing.summaries[0].mainImage.link"
            alt="Product Image"
            class="h-32 w-32"
          />
        </div>
        <div class="flex flex-col gap-2 ml-6">
          <h1 class="text-2xl font-bold text-left">
            {{ productListing.summaries[0].itemName }}
          </h1>
          <div id="product-attributes" class="flex gap-16">
            <div class="flex flex-col gap-2">
              <div class="flex gap-4">
                <p class="text-md">SKU:</p>
                <p class="text-md">{{ sku }}</p>
              </div>
              <div class="flex gap-4">
                <p class="text-md">Status:</p>
                <span
                  v-for="status in productListing.summaries[0].status"
                  :key="status"
                >
                  <Chip :label="status" />
                </span>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <div class="flex gap-4">
                <p class="text-md">ASIN:</p>
                <p class="text-md">{{ productListing.summaries[0].asin }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel>
    <TabView class="flex-grow overflow-scroll m-6 flex flex-col">
      <TabPanel class="flex flex-col h-full overflow-hidden">
        <template #header>
          <div class="flex align-items-center gap-2">
            <span class="pi pi-fw pi-wifi"></span>
            <span class="font-bold white-space-nowrap">Live</span>
          </div>
        </template>
        <div v-if="loading" class="h-full overflow-y-scroll p-6">
          <ProgressSpinner />
        </div>
        <div v-else class="flex-grow overflow-y-auto">
          <LiveListing
            v-if="!loading"
            :sku="sku"
            :vendorCode="vendorCode"
            :currentListing="productListing.attributes"
            :productType="productListing.productType"
            :productSchema="productSchema"
            :propertyGroups="propertyGroups"
          />
        </div>
      </TabPanel>
      <TabPanel class="flex flex-col h-full overflow-hidden">
        <template #header>
          <div class="flex align-items-center gap-2">
            <span class="pi pi-fw pi-pencil"></span>
            <span class="font-bold white-space-nowrap">Edit</span>
          </div>
        </template>
        <div v-if="loading" class="h-full overflow-y-scroll p-6">
          <ProgressSpinner />
        </div>
        <div v-else class="h-full overflow-y-auto px-6">
          <div v-if="listingDrafts.length > 0">
            <DraftsSummary
              v-if="!loading"
              :sku="sku"
              :vendorCode="vendorCode"
              :productListing="productListing.attributes"
              :listingDrafts="listingDrafts"
              :productType="productListing.productType"
              :propertyGroups="propertyGroups"
              :productSchema="productSchema"
              :asin="productListing.summaries[0].asin"
            />
          </div>
          <div v-else>
            <EditListing
              v-if="!loading"
              :sku="sku"
              :vendorCode="vendorCode"
              :currentListing="productListing.attributes"
              :productType="productListing.productType"
              :productSchema="productSchema"
              :propertyGroups="propertyGroups"
              :asin="productListing.summaries[0].asin"
              :draftTitle="null"
            />
          </div>
        </div>
      </TabPanel>
      <TabPanel class="flex flex-col h-full overflow-hidden">
        <template #header>
          <div class="flex align-items-center gap-2">
            <span class="pi pi-fw pi-history"></span>
            <span class="font-bold white-space-nowrap">History</span>
          </div>
        </template>
        <div v-if="loading" class="h-full overflow-y-scroll p-6">
          <ProgressSpinner />
        </div>
        <div v-else class="h-full overflow-y-auto px-6">
          <ListingHistory v-if="!loading" :sku="sku" />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, ref, watch, onMounted } from "vue";
import EditListing from "../listings/EditListing.vue";
import LiveListing from "../listings/LiveListing.vue";
import DraftsSummary from "../listings/DraftsSummary.vue";
import ListingHistory from "../listings/ListingHistory.vue";
import { useDashboardStore } from "@/stores/DashboardStore";
import { useCustomFetcher } from "@/utils/callersForBackendApi";
import { list } from "aws-amplify/storage";

// Toggles visibility of different services on myAccounts page
const emit = defineEmits(["on-row-unselect"]);
const props = defineProps({
  sku: String,
  vendorCode: String,
  productListing: Object,
});

const dashboardStore = useDashboardStore();
const sku = ref(props.sku);
const vendorCode = ref(props.vendorCode);
const productListing = ref(props.productListing);
const loading = ref(true);
const productSchema = ref();
const propertyGroups = ref();
const listingDrafts = ref([]);

// Watch for changes to the product prop and update the reactive variable
watch(
  () => props.sku,
  (newValue) => {
    sku.value = newValue;
  }
);

watch(
  () => props.productListing,
  (newValue) => {
    productListing.value = newValue;
  }
);

watch(
  () => props.vendorCode,
  (newValue) => {
    vendorCode.value = newValue;
  }
);

const getDrafts = async () => {
  try {
    const sellerid = dashboardStore.currentSellerId;
    const response = await list({
      prefix: `listing-drafts/${sellerid}/${sku.value}/`,
    });
    return response.items;
  } catch (error) {
    console.error(error);
  }
};

// Fetch product type schema when the component is mounted
onMounted(async () => {
  try {
    // Fetch product schema and property groupings
    [productSchema.value, propertyGroups.value] = await getProductTypeSchema(
      productListing.value.productType
    );

    listingDrafts.value = await getDrafts();
    loading.value = false;
  } catch (error) {
    console.error(error);
  }
});

const getProductTypeSchema = async (productType) => {
  try {
    // Determine if selected account vendor or seller
    const sellerType =
      dashboardStore.currentDashboardId ===
      "7899d9c6-721a-4657-9719-b8902f4701d0"
        ? "vendor"
        : "seller";

    const params = {
      queryParams: {
        sellerid: dashboardStore.currentSellerId,
        sellerType: sellerType,
        productType: productType,
        marketplaceid: dashboardStore.currentMarketplace,
      },
    };

    // Make API call to get product type schema
    const response = await useCustomFetcher(
      "/getProductTypeDefinition",
      params
    );

    const productSchema = response.productSchema;
    const propertyGroups = response.propertyGroups;

    return [productSchema, propertyGroups];
  } catch (error) {
    console.error("Error fetching product type schema:", error);
    emit("on-row-unselect");
  }
};
</script>

<style lang="scss" scoped></style>
