<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="home">
    <div class="header split-bg">
      <h3>Hello, {{ userStore.user.username }}</h3>
      <DashboardControls :source="'home'" />
    </div>
    <DashboardMain :source="'home'" />
  </div>
</template>

<script setup>
import DashboardControls from "@/components/dashboards/DashboardControls.vue";
import DashboardMain from "@/components/dashboards/DashboardMain";
import { useUserStore } from "@/stores/UserStore";
import { useDashboardStore } from "@/stores/DashboardStore";
import { listQuicksightUserGroups } from "@/utils/api";
import { currentSession } from "../utils/auth";
import { onBeforeMount } from "vue";

const userStore = useUserStore();
const dashboardStore = useDashboardStore();

onBeforeMount(async () => {
  if (dashboardStore.userGroups.length === 0) {
    try {
      const data = await currentSession();
      userStore.setUser(
        data.accessToken.payload.username,
        data.idToken.payload.email,
        data.idToken.toString(),
        data.idToken.payload.sub
      );
      const params = {
        queryParams: userStore.user,
      };
      await listQuicksightUserGroups(params);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
});
</script>

<style lang="scss" scoped>
.home {
  margin-top: 8vh;
  height: 92vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.5rem;
  color: var(--across-white);
  background-color: var(--across-black);
}

h3 {
  margin-left: 1rem;
}

.split-bg {
  background-image: linear-gradient(
    130deg,
    var(--across-black) 40%,
    var(--across-purple) 40%
  );
}
</style>
