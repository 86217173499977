/* eslint-disable vue/multi-word-component-names */
import { createPinia } from "pinia";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/tailwind.css";
import PrimeVue from "primevue/config";
import Lara from "./presets/lara";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import ProgressSpinner from "primevue/progressspinner";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import SelectButton from "primevue/selectbutton";
import Tooltip from "primevue/tooltip";
import Menu from "primevue/menu";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Skeleton from "primevue/skeleton";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Card from "primevue/card";
import Chip from "primevue/chip";
import Tag from "primevue/tag";
import Fieldset from "primevue/fieldset";
import Dialog from "primevue/dialog";
import router from "./router";
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
import InlineMessage from "primevue/inlinemessage";
import InputText from "primevue/inputtext";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Panel from "primevue/panel";
import Badge from "primevue/badge";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import DynamicDialog from "primevue/dynamicdialog";
import Chart from "primevue/chart";
import PickList from "primevue/picklist";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import ChartDataLabels from "chartjs-plugin-datalabels";
import FileUpload from "primevue/fileupload";
import "primeicons/primeicons.css";
import "@jsonforms/vue-vanilla/vanilla.css";

Amplify.configure(config);

const app = createApp(App);

app.use(router);
app.use(createPinia());
app.use(VueTailwindDatepicker);
app.use(PrimeVue, { unstyled: true, pt: Lara });
app.use(ToastService);

app.component("Button", Button);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("Calendar", Calendar);
app.component("Dropdown", Dropdown);
app.component("MultiSelect", MultiSelect);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Checkbox", Checkbox);
app.component("SelectButton", SelectButton);
app.component("InlineMessage", InlineMessage);
app.component("Menu", Menu);
app.component("Skeleton", Skeleton);
app.component("Toast", Toast);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Card", Card);
app.component("Chip", Chip);
app.component("Tag", Tag);
app.component("Fieldset", Fieldset);
app.component("Dialog", Dialog);
app.component("InputText", InputText);
app.component("Splitter", Splitter);
app.component("Panel", Panel);
app.component("SplitterPanel", SplitterPanel);
app.component("Badge", Badge);
app.component("DataView", DataView);
app.component("DataViewLayoutOptions", DataViewLayoutOptions);
app.component("DynamicDialog", DynamicDialog);
app.component("Chart", Chart);
app.component("PickList", PickList);
app.component("IconField", IconField);
app.component("InputIcon", InputIcon);
app.component("ChartDataLabels", ChartDataLabels);
app.component("FileUpload", FileUpload);
app.directive("tooltip", Tooltip);
app.mount("#app");
