<template>
  <div class="my-profile">
    <h4>My Profile</h4>
    <p>Username: {{ userStore.user.username }}</p>
    <p>Email: {{ userStore.user.email }}</p>
    <p>User Id: {{ userStore.user.payloadSub }}</p>
    <div class="group-list h-full overflow-y-scroll">
      <template
        v-for="group in dashboardStore.userGroups"
        :key="group.GroupName"
      >
        <div class="group" v-if="group.GroupName !== ''">
          <div class="summary">
            <div class="group-type">{{ formatGroupType(group.GroupName) }}</div>
            <h3 class="group-name">{{ formatGroupName(group.GroupName) }}</h3>
            <div class="icons">
              <i
                class="material-icons"
                @click="
                  toggleGroup(group.GroupName),
                    listGroupMembers(group.GroupName)
                "
                >edit</i
              >
            </div>
          </div>
          <div class="details">
            <div v-if="expandedGroup === group.GroupName">
              <div class="group-members">
                <h4>Group Members</h4>
                <div
                  class="members"
                  v-for="member in group.groupMembers"
                  :key="member"
                >
                  <div class="member">
                    <p>{{ extractIdFromMemberName(member.MemberName) }}</p>
                    <i
                      v-if="
                        extractIdFromMemberName(member.MemberName) !==
                        userStore.user.payloadSub
                      "
                      class="material-icons"
                      @click="deleteMember(member.MemberName, group.GroupName)"
                      >delete</i
                    >
                  </div>
                </div>
              </div>
              <form @submit.prevent="handleSubmit()">
                <div class="user-form-input">
                  <label>Add User</label>
                  <input
                    v-model="userInput"
                    type="text"
                    placeholder="Enter user id..."
                  />
                </div>
                <div class="user-form-sub">
                  <button>Share With User</button>
                </div>
                <div class="success" v-if="userAdditionSuccessful">
                  <FlashMessage
                    status="success"
                    :text="`User successfully added to ${group.GroupName}`"
                    @click="resetAddUserFlags()"
                  />
                </div>
                <div class="error" v-if="userAdditionFailed">
                  <FlashMessage
                    status="error"
                    :text="`User addition to ${group.GroupName} unsuccessful`"
                    @click="resetAddUserFlags()"
                  />
                </div>
                <div class="success" v-if="userDeletionSuccessful">
                  <FlashMessage
                    status="success"
                    :text="`User successfully removed from ${group.GroupName}`"
                    @click="resetDeleteUserFlags()"
                  />
                </div>
                <div class="error" v-if="userDeletionFailed">
                  <FlashMessage
                    status="error"
                    :text="`User removal from ${group.GroupName} unsuccessful`"
                    @click="resetDeleteUserFlags()"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useUserStore } from "@/stores/UserStore";
import { useDashboardStore } from "@/stores/DashboardStore";
import {
  listQuicksightUserGroups,
  addUserToQuicksightGroup,
  listQuicksightGroupMembers,
  deleteUserFromQuicksightGroup,
} from "@/utils/api";
import { currentSession } from "../utils/auth";
import FlashMessage from "@/components/FlashMessage.vue";

const userStore = useUserStore();
const dashboardStore = useDashboardStore();
const expandedGroup = ref(null);
const userInput = ref("");
const userAdditionSuccessful = ref(false);
const userAdditionFailed = ref(false);
const userDeletionSuccessful = ref(false);
const userDeletionFailed = ref(false);

const resetAddUserFlags = () => {
  userAdditionSuccessful.value = false;
  userAdditionFailed.value = false;
};

const resetDeleteUserFlags = () => {
  userDeletionSuccessful.value = false;
  userDeletionFailed.value = false;
};

const extractIdFromMemberName = (memberName) => {
  const parts = memberName.split("/");
  return parts.length > 1 ? parts[1] : memberName;
};

const formatGroupName = (groupName) => {
  const parts = groupName.split("-");
  const name = parts[1].replace(/\./g, " ");
  return name;
};

const formatGroupType = (groupName) => {
  const parts = groupName.split("-");
  return parts[0];
};

// Use the async setup function to handle asynchronous operations
const asyncSetup = async () => {
  const data = await currentSession();
  userStore.setUser(
    data.accessToken.payload.username,
    data.idToken.payload.email,
    data.idToken.toString(),
    data.idToken.payload.sub
  );
  const params = {
    queryParams: userStore.user,
  };
  await listQuicksightUserGroups(params);
};

// Call the async setup function
asyncSetup();

const toggleGroup = (GroupName) => {
  if (expandedGroup.value === GroupName) {
    expandedGroup.value = null;
  } else {
    expandedGroup.value = GroupName;
  }
};

const handleSubmit = async () => {
  userAdditionSuccessful.value = false;
  userAdditionFailed.value = false;
  const params = {
    queryParams: {
      username: userStore.user.username,
      email: userStore.user.email,
      jwtToken: userStore.user.jwtToken,
      payloadSub: userStore.user.payloadsub,
      groupName: expandedGroup.value,
      userToAdd: userInput.value,
    },
  };
  try {
    const res = await addUserToQuicksightGroup(params);
    if (res.Status === 200) {
      userAdditionSuccessful.value = true;
      userInput.value = "";
    }
  } catch (err) {
    console.error(err);
    userAdditionFailed.value = true;
    userInput.value = "";
  }
};

const listGroupMembers = async (GroupName) => {
  const params = {
    queryParams: {
      username: userStore.user.username,
      email: userStore.user.email,
      jwtToken: userStore.user.jwtToken,
      payloadSub: userStore.user.payloadsub,
      groupName: GroupName,
    },
  };
  try {
    await listQuicksightGroupMembers(params);
  } catch (err) {
    console.error(err);
  }
};

const deleteMember = async (MemberName, GroupName) => {
  const data = await currentSession();
  userStore.setUser(
    data.accessToken.payload.username,
    data.idToken.payload.email,
    data.idToken.toString(),
    data.idToken.payload.sub
  );
  // Display a confirmation dialog
  const shouldDelete = window.confirm(
    `Are you sure you want to delete ${MemberName} from ${GroupName}?`
  );

  if (shouldDelete) {
    const params = {
      queryParams: {
        username: userStore.user.username,
        email: userStore.user.email,
        jwtToken: userStore.user.jwtToken,
        payloadSub: userStore.user.payloadsub,
        groupName: GroupName,
        memberName: MemberName,
      },
    };

    try {
      await deleteUserFromQuicksightGroup(params);
      dashboardStore.deleteGroupMember(GroupName, MemberName);
      userDeletionSuccessful.value = true;
    } catch (err) {
      console.error(err);
      userDeletionFailed.value = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.my-profile {
  margin-top: 8vh;
  height: 92vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 4rem;
  background-color: #eee;
}

.group-list {
  max-width: 640px;
  margin: 20px auto;
}

.group {
  padding: 15px 20px;
  background: #fff;
  margin-top: 20px;
  border-radius: 4px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .group-type {
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 1px;
    color: #aaa;
  }

  .group-name {
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 1px;
    font-weight: bold;
    color: #aaa;
  }
}

.details {
  margin-top: 20px;

  .group-members {
    h4 {
      text-transform: uppercase;
      font-size: 0.8em;
      letter-spacing: 1px;
      color: #aaa;
    }
  }
}

.group h3,
.group .icons {
  display: inline-block;
}

.member {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.material-icons {
  cursor: pointer;
}

form {
  max-width: 420px;
  margin: 30px auto;
  background: #fff;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}

label {
  color: #aaa;
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

input {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}

button {
  background: var(--dark-green);
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  color: white;
  border-radius: 20px;
}

.user-form-sub {
  text-align: center;
}
</style>
