<template>
  <header
    class="navbar navbar-dark sticky-top flex-md-nowrap shadow"
    :class="{ 'scrolled-nav': scrolledNav }"
  >
    <nav>
      <div class="branding">
        <router-link to="/"
          ><img src="@/assets/AX-black.png" alt=""
        /></router-link>
      </div>
      <ul v-show="!mobile" class="navigation">
        <!-- <li>
          <router-link to="/" class="link">Home</router-link>
        </li> -->
        <li>
          <router-link to="/my-accounts" class="link">My Accounts</router-link>
        </li>

        <li class="dropdown">
          <Button
            type="button"
            icon="pi pi-user"
            :label="userStore.user.username"
            text
            @click="toggle"
            aria-haspopup="true"
            aria-controls="overlay_menu"
            style="color: var(--across-black)"
          />
          <Menu
            :model="profileLinks"
            :popup="true"
            id="overlay_menu"
            ref="menu"
          >
            <template #item="{ item, props }">
              <router-link
                v-if="item.route"
                v-slot="{ href, navigate }"
                :to="item.route"
                custom
              >
                <a
                  v-ripple
                  :href="href"
                  v-bind="props.action"
                  @click="navigate"
                >
                  <span :class="item.icon" />
                  <span class="ml-2">{{ item.label }}</span>
                </a>
              </router-link>
              <a
                v-else
                v-ripple
                :href="item.url"
                :target="item.target"
                v-bind="props.action"
              >
                <span :class="item.icon" />
                <span class="ml-2">{{ item.label }}</span>
              </a>
            </template>
          </Menu>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script setup>
import { ref } from "vue";
import { signOut } from "aws-amplify/auth";
import { useUserStore } from "@/stores/UserStore";

const userStore = useUserStore();

async function handleSignOut() {
  try {
    await signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

const profileLinks = [
  {
    items: [
      {
        label: "Add Client",
        icon: "pi pi-plus",
        route: "/add-client",
      },
      {
        label: "Profile",
        icon: "pi pi-user",
        route: "/profile",
      },
      {
        label: "Sign Out",
        icon: "pi pi-user",
        command: () => {
          handleSignOut();
        },
      },
    ],
  },
];

const menu = ref();

// toggles dropdown menu
const toggle = (event) => {
  menu.value.toggle(event);
};
</script>

<style lang="scss" scoped>
header {
  background-color: var(--across-white);
  z-index: 99;
  width: 100%;
  height: 8vh;
  position: fixed;
  top: 0;
  transition: 0.5s ease all;
  color: var(--across-black);

  nav {
    display: flex;
    flex-direction: row;
    transition: 0.5s ease all;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    ul,
    .link,
    &:visited {
      font-weight: 500;
      color: var(--across-black);
      list-style: none;
      text-decoration: none;
      cursor: pointer;
    }

    li {
      text-transform: uppercase;
      padding: 0.25rem;
      margin-left: 16px;
    }

    .link {
      font-size: 14px;
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;

      &:hover {
        color: var(--across-purple);
        border-color: var(--across-purple);
      }
    }

    .router-link-active {
      color: var(--across-purple);
      border-color: var(--across-purple);
    }

    .branding {
      display: flex;
      align-items: center;

      img {
        width: 100px;
        transition: 0.5s ease all;
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }
  }
}
</style>
