import { defineStore } from "pinia";

export const useCatalogStore = defineStore("catalogStore", {
  state: () => ({
    catalogMetrics: null,
    catalogAttributes: null,
  }),
  getters: {},
  actions: {
    setCatalogMetrics(catalogMetrics) {
      this.catalogMetrics = catalogMetrics;
    },
    setCatalogAttributes(catalogAttributes) {
      this.catalogAttributes = catalogAttributes;
    },
  },
});
