<template>
  <div>
    <div
      v-if="message"
      :class="{
        'bg-red-200 text-red-900': message.type === 'error',
        'bg-green-200 text-green-900': message.type === 'success',
      }"
      class="rounded-lg shadow-md p-6 pr-10 relative mb-4 mt-4"
      style="min-width: 240px"
    >
      <button
        @click="closeMessage"
        class="opacity-75 cursor-pointer absolute top-0 right-0 mt-2 mr-2 py-2 px-3 hover:opacity-100"
      >
        &times;
      </button>
      <div class="flex items-center">
        {{ message.text }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["status", "text"]);
const messages = [
  {
    text: props.text,
    type: "success",
  },
  {
    text: props.text,
    type: "error",
  },
];
const message = props.status === "success" ? messages[0] : messages[1];
</script>
