import { isEqual } from "lodash";
// import {
//   PATCH_LISTINGS_ITEM_API_NAME,
//   USE_CASE_TO_REQUIREMENTS,
// } from "@/app/constants/global";
// import { isFullUpdate } from "@/app/utils/listings-item";

// export function convertListingToJsonFeed(
//   sku,
//   productType,
//   listing,
//   useCase,
//   initialListing,
//   sellerId,
//   issueLocale,
//   submissionMode
// ) {
//   const feedHeader = {
//     sellerId: sellerId,
//     version: "2.0",
//     issueLocale: issueLocale,
//   };
//   let feed;
//   if (isFullUpdate(useCase, submissionMode) && Object.keys(listing).length) {
//     feed = {
//       header: feedHeader,
//       messages: [
//         {
//           messageId: 1,
//           sku: sku,
//           operationType: "UPDATE",
//           productType: productType,
//           requirements: USE_CASE_TO_REQUIREMENTS[useCase],
//           attributes: listing,
//         },
//       ],
//     };
//   }

//   if (PATCH_LISTINGS_ITEM_API_NAME === submissionMode) {
//     const patches = computePatches(listing, initialListing);
//     if (!patches.length) {
//       return feed;
//     }

//     feed = {
//       header: feedHeader,
//       messages: [
//         {
//           messageId: 1,
//           sku: sku,
//           operationType: "PATCH",
//           productType: productType,
//           patches: patches,
//         },
//       ],
//     };
//   }
//   return feed;
// }

export function computePatches(currentListing, initialListing) {
  const currentListingKeys = Object.keys(currentListing);
  const initialListingKeys = Object.keys(initialListing);
  const keysAdded = difference(currentListingKeys, initialListingKeys);
  const keysRemoved = difference(initialListingKeys, currentListingKeys);
  const commonKeys = intersection(initialListingKeys, currentListingKeys);

  const patches = [];
  keysAdded.forEach((key) => {
    patches.push({
      op: "add",
      path: `/attributes/${key}`,
      value: currentListing[key],
    });
  });

  keysRemoved.forEach((key) => {
    patches.push({
      op: "delete",
      path: `/attributes/${key}`,
      value: initialListing[key],
    });
  });

  commonKeys.forEach((key) => {
    const currentValue = currentListing[key];
    const initialValue = initialListing[key];
    if (!isEqual(currentValue, initialValue)) {
      patches.push({
        op: "replace",
        path: `/attributes/${key}`,
        value: currentValue,
      });
    }
  });
  return patches;
}

function difference(firstArray, secondArray) {
  const difference = firstArray.filter(function (x) {
    return secondArray.indexOf(x) < 0;
  });
  return Array.from(new Set(difference));
}

function intersection(firstArray, secondArray) {
  const intersection = firstArray.filter(function (x) {
    return secondArray.indexOf(x) >= 0;
  });
  return Array.from(new Set(intersection));
}
