<template>
  <div v-if="loading" class="h-full overflow-y-scroll p-6">
    <ProgressSpinner />
  </div>
  <div v-else class="">
    <template v-if="!history || history.length == 0">
      <h1>No changes found</h1>
      <div class="flex justify-center items-center">
        <Button
          label="Refresh Data"
          icon="pi pi-refresh"
          class="p-button-secondary mb-2"
          @click="refreshData"
        />
      </div>
    </template>
    <template v-else>
      <div class="card h-full overflow-y-scroll">
        <div class="flex justify-end items-center">
          <Button
            label="Refresh Data"
            icon="pi pi-refresh"
            class="p-button-secondary mb-2"
            @click="refreshData"
          />
        </div>
        <DataTable
          v-model:expandedRows="expandedRows"
          v-model:selection="selectedChange"
          selection-mode="single"
          :value="history"
          dataKey="id.S"
          class="p-datatable-gridlines"
          scrollable
        >
          <Column expander style="width: 3em"></Column>
          <Column field="title.S" header="Title" sortable></Column>
          <Column field="description.S" header="Description"></Column>
          <Column field="asin.S" header="ASIN"></Column>
          <Column
            field="timestamp.N"
            header="Update Time"
            :sortable="true"
            :defaultSortOrder="1"
          >
            >
            <template #body="slotProps">
              {{ timestampToDate(slotProps.data.timestamp.N) }}
            </template>
          </Column>
          <Column field="username.S" header="Author"></Column>
          <Column field="update_status.S" header="Status"></Column>
          <Column :exportable="false" style="min-width: 3rem">
            <template #body="slotProps">
              <Button
                outlined
                rounded
                class="mr-2"
                @click="onRowSelect(slotProps)"
                >View Impact</Button
              >
            </template>
          </Column>

          <template #expansion="slotProps">
            <Splitter style="height: 200px" class="overflow-y-scroll">
              <SplitterPanel size="{50}">
                <h4>Original</h4>
                <div class="flex items-center justify-center">
                  <div
                    v-for="item in slotProps.data.revert_patch.M.patches.L"
                    :key="item"
                  >
                    <p>Attribute: {{ item.M.path.S }}</p>
                    <span v-for="value in item.M.value.L" :key="value">
                      <p>Value: {{ value.M }}</p>
                    </span>
                  </div>
                </div>
              </SplitterPanel>
              <SplitterPanel size="{50}">
                <h4>Changes</h4>
                <div class="flex items-center justify-center">
                  <div
                    v-for="item in slotProps.data.patch_data.M.patches.L"
                    :key="item"
                  >
                    <p>Attribute: {{ item.M.path.S }}</p>
                    <span v-for="value in item.M.value.L" :key="value">
                      <p>Value: {{ value.M }}</p>
                    </span>
                  </div>
                </div>
              </SplitterPanel>
            </Splitter>
          </template>
        </DataTable>
      </div>
      <ListingChangeImpactChart
        v-if="selectedChange"
        v-model:visible="visible"
        :listingChangeEvent="selectedChange"
      />
    </template>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from "vue";
import { scanDynamoDb } from "@/utils/dynamodb_functions";
import { useDashboardStore } from "@/stores/DashboardStore";
import ListingChangeImpactChart from "./ListingChangeImpactChart.vue";
import { useToast } from "primevue/usetoast";

const props = defineProps({
  sku: String,
  productType: String,
  currentListing: Object,
  productSchema: Object,
});

const toast = useToast();
const dashboardStore = useDashboardStore();
const sku = ref(props.sku);
const history = ref();
const loading = ref(false);
const expandedRows = ref(null);
const visible = ref(false);
const selectedChange = ref(null);

const showDialog = () => {
  visible.value = true;
};

watch(visible, (newValue) => {
  if (!newValue) {
    selectedChange.value = null;
  }
});

const DataRefreshError = () => {
  toast.add({
    severity: "error",
    summary: "Error refreshing data",
    detail:
      "There was an error refreshing the data. Please check the seller, marketplace combination and try again.",
    life: 6000,
  });
};

const onRowSelect = async (event) => {
  try {
    selectedChange.value = {
      asin: [event.data.asin.S],
      marketplaceid: event.data.marketplaceid.S,
      seller_type: event.data.seller_type.S,
      sellerid: event.data.sellerid.S,
      sku: event.data.sku.S,
      timestamp: event.data.timestamp.N,
    };
    showDialog();
  } catch (error) {
    console.error("Error selecting product:", error);
  }
};

// Fetch product type schema when component is mounted
onMounted(async () => {
  await fetchHistory();
});

watch(
  () => dashboardStore.currentSellerId,
  async () => {
    // When the seller ID changes, fetch listing history
    loading.value = true;
    history.value = await scanDynamoDb(
      createGetChangeHistoryDynamoDbScanPayload(),
      dashboardStore.currentSellerId
    );
    loading.value = false;
  }
);

const fetchHistory = async () => {
  loading.value = true;
  history.value = await scanDynamoDb(
    createGetChangeHistoryDynamoDbScanPayload(),
    dashboardStore.currentSellerId
  );
  loading.value = false;
};

const refreshData = async () => {
  try {
    await fetchHistory();
  } catch (error) {
    DataRefreshError(error);
  }
};

const createGetChangeHistoryDynamoDbScanPayload = () => {
  const table_name = "listings_change_history";
  let filters;
  if (!sku.value) {
    filters = {
      sellerid: dashboardStore.currentSellerId,
    };
  } else {
    filters = {
      sellerid: dashboardStore.currentSellerId,
      sku: sku.value,
    };
  }

  return {
    table_name: table_name,
    filters: filters,
  };
};

const timestampToDate = (timestamp) => {
  return new Date(parseInt(timestamp)).toLocaleString();
};
</script>

<style lang="scss" scoped></style>
