import { put } from "aws-amplify/api";

async function patchListingsItem(params) {
  try {
    const data = put({
      apiName: "quicksightembed",
      path: "/updateListing",
      options: params,
    });
    // Wait for the promise to resolve and get the response
    const response = await data.response;
    const responseBody = await response.body.json();

    if (response.status_code === 500) {
      throw new Error("Amazon Server Error", response.body.json());
    }

    if (responseBody.status_code === 400) {
      throw new Error("Bad Request", responseBody);
    }

    if (responseBody.status_code === 403) {
      throw new Error("Authorisation Error", responseBody);
    }

    return responseBody;
  } catch (error) {
    console.error("PATCH call failed: ", error);
    throw error;
  }
}

export { patchListingsItem };
