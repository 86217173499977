<template>
  <div class="my-accounts">
    <DashboardSidebar
      @toggle-data-explorer="toggleDataExplorer"
      @toggle-analytics="toggleAnalytics"
      @toggle-catalog="toggleCatalog"
      dashboardType="seller"
    />
    <div class="my-accounts-body h-full overflow-y-hidden">
      <div class="controls">
        <DashboardControls :dataExplorerActive="dataExplorerVisible" />
      </div>
      <!-- Divs for different services toggled by events emitted by DashboardSidebar -->
      <div v-show="analyticsVisible" class="dashboard overflow-y-scroll">
        <DashboardMain :source="'accounts'" />
      </div>
      <div v-show="dataExplorerVisible" class="data-explorer overflow-y-scroll">
        <DataExplorer />
      </div>
      <div v-show="catalogVisible" class="catalog overflow-y-hidden p-2">
        <CatalogSummary />
      </div>
      <AccountSelector v-model:visible="formVisible" />
    </div>
  </div>
</template>

<script setup>
import DashboardSidebar from "../components/dashboards/DashboardSidebar";
import DashboardMain from "@/components/dashboards/DashboardMain";
import DashboardControls from "@/components/dashboards/DashboardControls";
import DataExplorer from "@/components/DataExplorer";
import CatalogSummary from "@/components/catalog/CatalogSummary";
import AccountSelector from "@/components/AccountSelector";
import { listQuicksightUserGroups } from "@/utils/api";
import { currentSession } from "@/utils/auth";
import { useDashboardStore } from "@/stores/DashboardStore";
import { useUserStore } from "@/stores/UserStore";
import { useDataExplorerStore } from "@/stores/DataExplorerStore";
import { onBeforeMount, ref, onMounted } from "vue";
import { useCustomFetcher } from "@/utils/callersForBackendApi";
import { getCurrentUserSessionDetails } from "@/utils/auth";

const dashboardStore = useDashboardStore();
const userStore = useUserStore();
const dataExplorerStore = useDataExplorerStore();
const dataExplorerVisible = ref(true);
const analyticsVisible = ref(false);
const catalogVisible = ref(false);
const formVisible = ref(true);

const toggleDataExplorer = (state) => {
  dataExplorerVisible.value = state;
};

const toggleAnalytics = (state) => {
  analyticsVisible.value = state;
};

const toggleCatalog = (state) => {
  catalogVisible.value = state;
};

const getAsinSets = async () => {
  try {
    const userSessionDetails = await getCurrentUserSessionDetails();

    const params = {
      queryParams: {
        username: userSessionDetails.username,
        email: userSessionDetails.email,
        jwtToken: userSessionDetails.jwtToken,
        payloadSub: userSessionDetails.payloadsub,
      },
    };

    const result = await useCustomFetcher("/getAsinSets", params);
    return result;
  } catch (error) {
    console.error("Error fetching Asin Sets:", error);
  }
};

onBeforeMount(async () => {
  if (dashboardStore.userGroups.length === 0) {
    try {
      const data = await currentSession();
      userStore.setUser(
        data.accessToken.payload.username,
        data.idToken.payload.email,
        data.idToken.toString(),
        data.idToken.payload.sub
      );
      const params = {
        queryParams: userStore.user,
      };
      await listQuicksightUserGroups(params);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
});

onMounted(async () => {
  const asinSets = await getAsinSets();
  dataExplorerStore.setAsinSets(asinSets);
});
</script>

<style lang="scss" scoped>
.my-accounts {
  margin-top: 8vh;
  height: 92vh;
  width: 100%;
  display: flex;
}
.my-accounts-body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dashboard,
.data-explorer,
.catalog {
  width: 100%;
  height: 100%;
}

.data-explorer {
  overflow-x: scroll;
}
</style>
