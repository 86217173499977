<template>
  <Dialog
    v-model:visible="propsVisible"
    header="Listing Change Impact"
    modal="true"
    :closable="true"
    :draggable="false"
    @update:visible="handleDialogClose"
    class="w-[60%] h-[70%]"
  >
    <div v-if="changeLoading">
      <ProgressSpinner />
    </div>
    <div class="h-full" v-else>
      <div class="flex">
        <SelectButton
          v-model="selectedMetric"
          :options="['Revenue', 'Conversion']"
          aria-labelledby="basic"
          @update:modelValue="updateChartData"
          class="mr-3"
        />
        <SelectButton
          v-model="selectedComparison"
          :options="['Last Period', 'Last Year']"
          aria-labelledby="basic"
          @update:modelValue="updateChartComparisonPeriod"
        />
      </div>
      <div class="card h-2/3" ref="chartContainer">
        <Chart
          type="bar"
          :data="chartData"
          :options="chartOptions"
          :plugins="plugins"
          class="h-full mt-6"
        />
        <div class="info-boxes grid grid-cols-3 gap-10 ml-[13%]">
          <Card
            v-for="index in 3"
            :key="index"
            class="info-box bg-across-grey border-l-4 border-across-grey p-4 rounded shadow text-center w-29 h-16 flex items-center justify-center"
          >
            <template #content>
              <p>
                <i
                  :class="metricIcon(chartData.comparisonData.pct[index - 1])"
                  :style="{
                    color: metricIconColour(
                      chartData.comparisonData.pct[index - 1]
                    ),
                  }"
                ></i>
                {{ chartData.comparisonData.pct[index - 1].toFixed(0) }}%
              </p>
              <p>
                {{ metricSymbol(chartData.comparisonData.actual[index - 1]) }}
                {{ currencySymbol }}
                {{
                  Math.abs(chartData.comparisonData.actual[index - 1]).toFixed(
                    2
                  )
                }}
                / day
              </p>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, defineProps, onMounted, defineEmits, watch, computed } from "vue";
import { useCustomFetcher } from "@/utils/callersForBackendApi";
import { useDashboardStore } from "@/stores/DashboardStore";
// import ChartDataLabels from "chartjs-plugin-datalabels";

const props = defineProps({
  listingChangeEvent: Object,
  visible: Boolean,
});

const emit = defineEmits(["update:visible"]);

const dashboardStore = useDashboardStore();
const changeLoading = ref(false);
const metrics = ref(); // Change impact metrics for selected asin
const chartData = ref();
// const chartOptions = ref();
const selectedMetric = ref("Revenue");
const selectedComparison = ref("Last Period");
const propsVisible = ref(props.visible);
// const plugins = [ChartDataLabels];
const yAxisTitle = computed(() => {
  return selectedMetric.value === "Revenue"
    ? "Average Daily Revenue"
    : "Conversion (%)";
});

// const metricComparisonSymbol = computed(() => {
//   return selectedMetric.value === "Revenue" ? "%" : "bps";
// });

const currencySymbol = computed(() => {
  switch (dashboardStore.currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return dashboardStore.currency; // Return the currency code if it's not USD, EUR, or GBP
  }
});

const yAxisLabel = computed(() => {
  return selectedMetric.value === "Revenue" ? currencySymbol.value : "";
});

const metricIcon = (metric) => {
  if (metric === 0) {
    return "pi pi-arrow-right";
  } else if (metric > 0) {
    return "pi pi-arrow-up-right";
  } else {
    return "pi pi-arrow-down-right";
  }
};

const metricSymbol = (metric) => {
  if (metric === 0) {
    return "";
  } else if (metric > 0) {
    return "+";
  } else {
    return "-";
  }
};

const metricIconColour = (metric) => {
  if (metric === 0) {
    return "#e5e7eb";
  } else if (metric > 0) {
    return "#81ed8d";
  } else {
    return "#ff3b30";
  }
};

watch(
  () => props.visible,
  (value) => {
    propsVisible.value = value;
  }
);

watch(propsVisible, (value) => {
  console.log("Visible: ", value);
  emit("update:visible", value);
});

// Stops the selected metric from being set to null
watch(selectedMetric, (newValue, oldValue) => {
  if (!newValue) {
    selectedMetric.value = oldValue;
  }
});

// Stops the selected comparison period from being set to null
watch(selectedComparison, (newValue, oldValue) => {
  if (!newValue) {
    selectedComparison.value = oldValue;
  }
});

const handleDialogClose = () => {
  console.log("Closing dialog");
  selectedMetric.value = "Revenue";
  selectedComparison.value = "Last Period";
  chartData.value = null;
  // chartOptions.value = null;
  metrics.value = null;
};

const calculateDaysSince = (timestamp) => {
  const now = new Date();
  const changeDate = new Date(parseInt(timestamp));
  const diffTime = Math.abs(now - changeDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

const updateChartData = (value) => {
  // Stops the selected metric from being set to null
  if (value == null) {
    return;
  }
  changeLoading.value = true;
  chartData.value = setChartData(
    metrics.value,
    value,
    selectedComparison.value
  );
  // chartOptions.value = setChartOptions();
  console.log("Updated Chart Data: ", chartData.value);
  changeLoading.value = false;
};

const updateChartComparisonPeriod = (value) => {
  // Stops the selected comparison period from being set to null
  if (value == null) {
    return;
  }
  changeLoading.value = true;
  chartData.value = setChartData(metrics.value, selectedMetric.value, value);
  // chartOptions.value = setChartOptions();
  console.log("Updated Chart Data: ", chartData.value);
  changeLoading.value = false;
};

const setChartData = (metrics, chosenMetric, chosenPeriod) => {
  const documentStyle = getComputedStyle(document.documentElement);
  const daysSince = calculateDaysSince(props.listingChangeEvent.timestamp); // used to calulate average daily revenue (in case 84 days havent past)

  chosenMetric = chosenMetric.toLowerCase();
  chosenPeriod = chosenPeriod.toLowerCase();

  // Group periods and corresponding revenues
  const groupedData = {
    Revenue: {
      "Four Weeks": { minus: null, plus: null, last_year: null },
      "Eight Weeks": { minus: null, plus: null, last_year: null },
      "Twelve Weeks": { minus: null, plus: null, last_year: null },
    },
    Conversion: {
      "Four Weeks": { minus: null, plus: null, last_year: null },
      "Eight Weeks": { minus: null, plus: null, last_year: null },
      "Twelve Weeks": { minus: null, plus: null, last_year: null },
    },
  };

  metrics.forEach((item) => {
    const rev = item.average_ordered_revenue || 0;
    const conv = (item.total_units_sold / item.total_page_views) * 100 || 0;

    if (item.period === "PREVIOUS_12_WEEKS") {
      groupedData.Revenue["Twelve Weeks"].minus = rev / 84;
      groupedData.Conversion["Twelve Weeks"].minus = conv;
    } else if (item.period === "PREVIOUS_8_WEEKS") {
      groupedData.Revenue["Eight Weeks"].minus = rev / 56;
      groupedData.Conversion["Eight Weeks"].minus = conv;
    } else if (item.period === "PREVIOUS_4_WEEKS") {
      groupedData.Revenue["Four Weeks"].minus = rev / 28;
      groupedData.Conversion["Four Weeks"].minus = conv;
    } else if (item.period === "POST_4_WEEKS") {
      const divisor = daysSince > 84 ? 84 : daysSince;
      groupedData.Revenue["Four Weeks"].plus = rev / divisor;
      groupedData.Conversion["Four Weeks"].plus = conv;
    } else if (item.period === "POST_8_WEEKS") {
      const divisor = daysSince > 56 ? 56 : daysSince;
      groupedData.Revenue["Eight Weeks"].plus = rev / divisor;
      groupedData.Conversion["Eight Weeks"].plus = conv;
    } else if (item.period === "POST_12_WEEKS") {
      const divisor = daysSince > 84 ? 84 : daysSince;
      groupedData.Revenue["Twelve Weeks"].plus = rev / divisor;
      groupedData.Conversion["Twelve Weeks"].plus = conv;
    } else if (item.period === "LASTYEAR_12_WEEKS") {
      groupedData.Revenue["Twelve Weeks"].last_year = rev / 84;
      groupedData.Conversion["Twelve Weeks"].last_year = conv;
    } else if (item.period === "LASTYEAR_8_WEEKS") {
      groupedData.Revenue["Eight Weeks"].last_year = rev / 56;
      groupedData.Conversion["Eight Weeks"].last_year = conv;
    } else if (item.period === "LASTYEAR_4_WEEKS") {
      groupedData.Revenue["Four Weeks"].last_year = rev / 28;
      groupedData.Conversion["Four Weeks"].last_year = conv;
    }
  });

  // Extract labels and data for the chart
  const labels = Object.keys(groupedData.Revenue);

  const revenueMinusData = labels.map(
    (label) => groupedData.Revenue[label].minus
  ); // Data for preceding period, e.g. minus[0] = last 4 weeks

  const conversionMinusData = labels.map(
    (label) => groupedData.Conversion[label].minus
  ); // Data for preceding period, e.g. minus[0] = last 4 weeks

  const revenuePlusData = labels.map(
    (label) => groupedData.Revenue[label].plus
  ); // Data for post period, e.g. plus[0] = next 4 weeks

  const conversionPlusData = labels.map(
    (label) => groupedData.Conversion[label].plus
  ); // Data for post period, e.g. plus[0] = next 4 weeks

  const revenueLastYearData = labels.map(
    (label) => groupedData.Revenue[label].last_year
  ); // Data for last year period, e.g. lastYearData[0] = last year same 4 weeks as post period

  const conversionLastYearData = labels.map(
    (label) => groupedData.Conversion[label].last_year
  ); // Data for last year period, e.g. lastYearData[0] = last year same 4 weeks as post period

  // Calculate % uplift/drop in metric
  const revenueLastYearComparisonPct = revenueLastYearData.map(
    (value, index) => {
      return ((revenuePlusData[index] - value) / value) * 100;
    }
  );

  const revenueLastPeriodComparisonPct = revenueMinusData.map(
    (value, index) => {
      return ((revenuePlusData[index] - value) / value) * 100;
    }
  );

  // Calculate actual uplift/drop in metric (not percentage)
  const revenueLastYearComparisonActual = revenueLastYearData.map(
    (value, index) => {
      return revenuePlusData[index] - value;
    }
  );

  const revenueLastPeriodComparisonActual = revenueMinusData.map(
    (value, index) => {
      return revenuePlusData[index] - value;
    }
  );

  // Calculate % uplift/drop in metric
  const conversionLastYearComparisonPct = conversionLastYearData.map(
    (value, index) => {
      return ((conversionPlusData[index] - value) / value) * 100;
    }
  );
  const conversionLastPeriodComparisonPct = conversionMinusData.map(
    (value, index) => {
      return ((conversionPlusData[index] - value) / value) * 100;
    }
  );

  if (chosenPeriod === "last period" && chosenMetric === "revenue") {
    return {
      labels: labels,
      datasets: [
        {
          label: "Before Content Change",
          backgroundColor: documentStyle.getPropertyValue("--across-purple"),
          borderColor: documentStyle.getPropertyValue("--across-purple"),
          data: revenueMinusData,
        },
        {
          label: "After Content Change",
          backgroundColor: documentStyle.getPropertyValue("--across-blue"),
          borderColor: documentStyle.getPropertyValue("--across-blue"),
          data: revenuePlusData,
        },
      ],
      comparisonData: {
        pct: revenueLastPeriodComparisonPct,
        actual: revenueLastPeriodComparisonActual,
      },
    };
  } else if (chosenPeriod === "last year" && chosenMetric === "revenue") {
    return {
      labels: labels,
      datasets: [
        {
          label: "Before Content Change",
          backgroundColor: documentStyle.getPropertyValue("--across-purple"),
          borderColor: documentStyle.getPropertyValue("--across-purple"),
          data: revenueLastYearData,
        },
        {
          label: "After Content Change",
          backgroundColor: documentStyle.getPropertyValue("--across-blue"),
          borderColor: documentStyle.getPropertyValue("--across-blue"),
          data: revenuePlusData,
        },
      ],
      comparisonData: {
        pct: revenueLastYearComparisonPct,
        actual: revenueLastYearComparisonActual,
      },
    };
  } else if (chosenPeriod === "last period" && chosenMetric === "conversion") {
    return {
      labels: labels,
      datasets: [
        {
          label: "Before Content Change",
          backgroundColor: documentStyle.getPropertyValue("--across-purple"),
          borderColor: documentStyle.getPropertyValue("--across-purple"),
          data: conversionMinusData,
        },
        {
          label: "After Content Change",
          backgroundColor: documentStyle.getPropertyValue("--across-blue"),
          borderColor: documentStyle.getPropertyValue("--across-blue"),
          data: conversionPlusData,
        },
      ],
      comparisonData: {
        pct: conversionLastPeriodComparisonPct,
        actual: revenueLastPeriodComparisonActual,
      },
    };
  } else {
    return {
      labels: labels,
      datasets: [
        {
          label: "Before Content Change",
          backgroundColor: documentStyle.getPropertyValue("--across-purple"),
          borderColor: documentStyle.getPropertyValue("--across-purple"),
          data: conversionLastYearData,
        },
        {
          label: "After Content Change",
          backgroundColor: documentStyle.getPropertyValue("--across-blue"),
          borderColor: documentStyle.getPropertyValue("--across-blue"),
          data: conversionPlusData,
        },
      ],
      comparisonData: {
        pct: conversionLastYearComparisonPct,
        actual: revenueLastYearComparisonActual,
      },
    };
  }
};

const chartOptions = computed(() => {
  return {
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
      legend: {
        labels: {
          color: "#000000",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#000000",
          font: {
            weight: 500,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: true,
          text: "Timeframe",
          color: "#000000",
          font: {
            weight: 500,
            size: 16,
          },
          padding: {
            top: 20,
            bottom: 10,
          },
        },
      },
      y: {
        ticks: {
          color: "#000000",
          callback: function (value) {
            return `${yAxisLabel.value}${value}`;
          },
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: yAxisTitle.value,
          color: "#000000",
          font: {
            weight: 500,
            size: 16,
          },
          padding: {
            top: 10,
            bottom: 20,
          },
        },
      },
    },
  };
});

const getChangeImpactMetrics = async (asin, timestamp, marketplaceid) => {
  try {
    const sellerType =
      dashboardStore.currentDashboardId ===
      "7899d9c6-721a-4657-9719-b8902f4701d0"
        ? "vendor"
        : "seller";

    const params = {
      queryParams: {
        asin: asin,
        timestamp: timestamp,
        marketplaceid: marketplaceid,
        accountType: sellerType,
      },
    };

    const result = await useCustomFetcher("/getListingChangeImpact", params);
    const response = JSON.parse(result.body);
    return response;
  } catch (error) {
    console.error("Error fetching metrics:", error);
  } finally {
    // filtersLoading.value = false;
  }
};

onMounted(async () => {
  changeLoading.value = true;
  metrics.value = await getChangeImpactMetrics(
    props.listingChangeEvent.asin,
    props.listingChangeEvent.timestamp,
    props.listingChangeEvent.marketplaceid
  );
  console.log("Metrics: ", metrics.value);
  console.log("listingChangeEvent: ", props.listingChangeEvent);
  chartData.value = setChartData(metrics.value, "revenue", "last period");
  // chartOptions.value = setChartOptions();
  changeLoading.value = false;
});
</script>
