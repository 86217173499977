<template>
  <div v-if="selectedDraft">
    <EditListing
      :sku="sku"
      :vendorCode="vendorCode"
      :currentListing="selectedDraft.value"
      :productType="productType"
      :productSchema="productSchema"
      :propertyGroups="propertyGroups"
      :asin="asin"
      :draftTitle="getFileName(selectedDraft.key)"
      @close="selectedDraft = null"
    />
  </div>
  <div v-else class="flex flex-col">
    <div class="flex justify-between">
      <h3 class="text-lg font-bold">Drafts</h3>
      <div class="flex justify-end">
        <Button
          icon="pi pi-refresh"
          class="p-button-secondary mr-2"
          @click="getDrafts"
          :loading="loading"
        />
        <Button label="Edit Listing" icon="pi pi-pencil" @click="createDraft" />
      </div>
    </div>
    <div class="flex">
      <Card
        v-for="(draft, index) in drafts"
        :key="draft"
        class="overflow-hidden mr-4"
      >
        <!-- <template #header>
        <img alt="draft image" src="/images/usercard.png" />
      </template> -->
        <template #title>
          <div
            class="flex justify-start cursor-pointer"
            @click="openDraft(draft.key)"
          >
            {{ getFileName(draft.key) }}
          </div>
        </template>
        <template #content>
          <div class="flex justify-start text-left">
            Updated on {{ draft.lastModified }}
          </div>
        </template>
        <template #footer>
          <div class="flex justify-end">
            <Button
              label="Publish"
              severity="secondary"
              @click="openDraft(draft.key)"
              class="mr-2"
            />
            <button
              @click="(event) => toggle(event, index)"
              aria-haspopup="true"
              :aria-controls="`config_menu_${index}`"
            >
              <span class="pi pi-cog"></span>
            </button>
            <Menu
              :ref="
                (el) => {
                  if (el) menuRefs[index] = el;
                }
              "
              :id="`config_menu_${index}`"
              :model="getMenuItems(index)"
              :popup="true"
            />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup>
import {
  downloadData,
  remove,
  copy,
  getProperties,
  list,
} from "aws-amplify/storage";
import { defineProps, ref } from "vue";
import EditListing from "./EditListing.vue";
import { useDashboardStore } from "@/stores/DashboardStore";

const props = defineProps({
  sku: String,
  vendorCode: String,
  listingDrafts: Array,
  productType: String,
  productListing: Object,
  productSchema: Object,
  propertyGroups: Array,
  asin: String,
});

const dashboardStore = useDashboardStore();
const menuRefs = ref([]);
const selectedDraft = ref(null);
const sku = ref(props.sku);
const vendorCode = ref(props.vendorCode);
const asin = ref(props.asin);
const liveListing = ref(props.productListing);
const productType = ref(props.productType);
const productSchema = ref(props.productSchema);
const propertyGroups = ref(props.propertyGroups);
const loading = ref(false);
// const sku = ref(props.sku);
const drafts = ref(props.listingDrafts);

const toggle = (event, index) => {
  menuRefs.value[index].toggle(event);
};

const getMenuItems = (index) => {
  return [
    {
      label: "Edit",
      icon: "pi pi-pencil",
      command: async () => {
        await openDraft(drafts.value[index].key);
      },
    },
    {
      label: "Duplicate",
      icon: "pi pi-copy",
      command: async () => {
        const draftCopy = await duplicateDraft(drafts.value[index].key); // Copy object from S3
        const draftCopyDetails = await getDraftProperties(draftCopy.key); // Fetch properties of copied object
        drafts.value.push(draftCopyDetails); // Add copied object to local array
      },
    },
    {
      separator: true,
    },
    {
      label: "Delete",
      icon: "pi pi-times",
      command: async () => {
        await removeDraft(drafts.value[index].key); // Delete object from S3
        drafts.value.splice(index, 1); // Remove object from local array
      },
    },
  ];
};

const openDraft = async (draftkey) => {
  const draft = await getDraft(draftkey);
  selectedDraft.value = {
    value: draft,
    key: draftkey,
  };
};

// Allows the user to create a new draft from the live listing or quick edit
const createDraft = () => {
  selectedDraft.value = {
    value: liveListing.value,
    key: "",
  };
};

const getFileName = (filePath) => {
  return filePath.split("/").pop().replace(".json", "");
};

const getDraft = async (draft) => {
  try {
    const downloadResult = await downloadData({
      key: draft,
    }).result;
    const text = downloadResult.body;
    const textBody = await new Response(text).json();
    return textBody;
  } catch (error) {
    console.log("Error : ", error);
  }
};

const removeDraft = async (draft) => {
  try {
    const result = await remove({
      key: draft,
    }).result;
    console.log("Delete Result", result);
  } catch (error) {
    console.log("Error : ", error);
  }
};

const duplicateDraft = async (draft) => {
  try {
    const response = await copy({
      source: {
        key: draft,
      },
      destination: {
        key: draft.replace(".json", "-copy.json"),
      },
    });
    return response;
  } catch (error) {
    console.log("Error : ", error);
  }
};

const getDraftProperties = async (draft) => {
  try {
    const response = await getProperties({
      key: draft,
    });
    return response;
  } catch (error) {
    console.log("Error : ", error);
  }
};

const getDrafts = async () => {
  try {
    loading.value = true;
    const sellerid = dashboardStore.currentSellerId;
    const response = await list({
      prefix: `listing-drafts/${sellerid}/${sku.value}/`,
    });
    drafts.value = response.items;
    loading.value = false;
  } catch (error) {
    loading.value = false;
    console.error(error);
  }
};
</script>

<style lang="scss" scoped></style>
