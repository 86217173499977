export const marketplaces = {
  A2EUQ1WTGCTBG2: {
    country: "Canada",
    code: "CA",
  },
  ATVPDKIKX0DER: {
    country: "United States",
    code: "US",
  },
  A1AM78C64UM0Y8: {
    country: "Mexico",
    code: "MX",
  },
  A2Q3Y263D00KWC: {
    country: "Brazil",
    code: "BR",
  },
  A1RKKUPIHCS9HS: {
    country: "Spain",
    code: "ES",
  },
  A1F83G8C2ARO7P: {
    country: "United Kingdom",
    code: "UK",
  },
  A13V1IB3VIYZZH: {
    country: "France",
    code: "FR",
  },
  AMEN7PMS3EDWL: {
    country: "Belgium",
    code: "BE",
  },
  A1805IZSGTT6HS: {
    country: "Netherlands",
    code: "NL",
  },
  A1PA6795UKMFR9: {
    country: "Germany",
    code: "DE",
  },
  APJ6JRA9NG5V4: {
    country: "Italy",
    code: "IT",
  },
  A2NODRKZP88ZB9: {
    country: "Sweden",
    code: "SE",
  },
  AE08WJ6YKNBMC: {
    country: "South Africa",
    code: "ZA",
  },
  A1C3SOZRARQ6R3: {
    country: "Poland",
    code: "PL",
  },
  ARBP9OOSHTCHU: {
    country: "Egypt",
    code: "EG",
  },
  A33AVAJ2PDY3EV: {
    country: "Turkey",
    code: "TR",
  },
  A17E79C6D8DWNP: {
    country: "Saudi Arabia",
    code: "SA",
  },
  A2VIGQ35RCS4UG: {
    country: "United Arab Emirates",
    code: "AE",
  },
  A21TJRUUN4KGV: {
    country: "India",
    code: "IN",
  },
  A39IBJ37TRP1C6: {
    country: "Australia",
    code: "AU",
  },
  A1VC38T7YXB528: {
    country: "Japan",
    code: "JP",
  },
};

export const sellerCentralUrls = {
  US: "https://sellercentral.amazon.com",
  CA: "https://sellercentral.amazon.ca",
  MX: "https://sellercentral.amazon.com.mx",
  BR: "https://sellercentral.amazon.com.br",
  ES: "https://sellercentral-europe.amazon.com",
  UK: "https://sellercentral-europe.amazon.com",
  FR: "https://sellercentral-europe.amazon.com",
  BE: "https://sellercentral.amazon.com.be",
  NL: "https://sellercentral.amazon.nl",
  DE: "https://sellercentral-europe.amazon.com",
  IT: "https://sellercentral-europe.amazon.com",
  SE: "https://sellercentral.amazon.se",
  ZA: "https://sellercentral.amazon.co.za",
  PL: "https://sellercentral.amazon.pl",
  EG: "https://sellercentral.amazon.eg",
  TR: "https://sellercentral.amazon.com.tr",
  SA: "https://sellercentral.amazon.sa",
  AE: "https://sellercentral.amazon.ae",
  IN: "https://sellercentral.amazon.in",
  AU: "https://sellercentral.amazon.com.au",
  JP: "https://sellercentral.amazon.co.jp",
  SG: "https://sellercentral.amazon.sg",
};

export const vendorCentralUrls = {
  US: "https://vendorcentral.amazon.com",
  CA: "https://vendorcentral.amazon.ca",
  MX: "https://vendorcentral.amazon.com.mx",
  BR: "https://vendorcentral.amazon.com.br",
  ES: "https://vendorcentral.amazon.es",
  UK: "https://vendorcentral.amazon.co.uk",
  FR: "https://vendorcentral.amazon.fr",
  BE: "https://vendorcentral.amazon.com.be",
  NL: "https://vendorcentral.amazon.nl",
  DE: "https://vendorcentral.amazon.de",
  IT: "https://vendorcentral.amazon.it",
  SE: "https://vendorcentral.amazon.se",
  ZA: "https://vendorcentral.amazon.co.za",
  PL: "https://vendorcentral.amazon.pl",
  EG: "https://vendorcentral.amazon.me",
  TR: "https://vendorcentral.amazon.com.tr",
  SA: "https://vendorcentral.amazon.me",
  AE: "https://vendorcentral.amazon.me",
  IN: "https://vendorcentral.amazon.in",
  AU: "https://vendorcentral.amazon.com.au",
  JP: "https://vendorcentral.amazon.co.jp",
  SG: "https://vendorcentral.amazon.com.sg",
};

export const advertisingConsoleUrls = {
  US: "https://www.amazon.com/ap/oa",
  CA: "https://www.amazon.com/ap/oa",
  MX: "https://www.amazon.com/ap/oa",
  BR: "https://www.amazon.com/ap/oa",
  ES: "https://eu.account.amazon.com/ap/oa",
  UK: "https://eu.account.amazon.com/ap/oa",
  FR: "https://eu.account.amazon.com/ap/oa",
  BE: "https://eu.account.amazon.com/ap/oa",
  NL: "https://eu.account.amazon.com/ap/oa",
  DE: "https://eu.account.amazon.com/ap/oa",
  IT: "https://eu.account.amazon.com/ap/oa",
  SE: "https://eu.account.amazon.com/ap/oa",
  ZA: "https://eu.account.amazon.com/ap/oa",
  PL: "https://eu.account.amazon.com/ap/oa",
  EG: "https://eu.account.amazon.com/ap/oa",
  TR: "https://eu.account.amazon.com/ap/oa",
  SA: "https://eu.account.amazon.com/ap/oa",
  AE: "https://eu.account.amazon.com/ap/oa",
  IN: "https://eu.account.amazon.com/ap/oa",
  JP: "https://apac.account.amazon.com/ap/oa",
};

export const advertisingLwaClientId =
  "amzn1.application-oa2-client.d12a51660f4b49b4918ec333a53270fe";

export const spapiLwaClientId =
  "amzn1.sp.solution.4e5cb73d-38a8-4463-9212-f9040005684a";

export const currencyCodes = {
  USD: "$",
  CAD: "CA$",
  MXN: "MX$",
  BRL: "R$",
  EUR: "€",
  GBP: "£",
  SEK: "kr",
  ZAR: "R",
  PLN: "zł",
  EGP: "LE",
  TRY: "₺",
  SAR: "﷼",
  AED: "د.إ",
  INR: "₹",
  AUD: "A$",
};
