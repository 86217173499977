<template>
  <div>
    <template v-if="!listing">
      <h1>No SKU found</h1>
    </template>
    <template v-else>
      <div class="flex justify-between">
        <div class="flex p-3">
          <InputText v-model="draftTitle" placeholder="Draft Title" />
        </div>
        <div class="flex w-1/4 justify-end">
          <!-- <ListingUpdateValidationButton
            class="mr-2"
            :sku="sku"
            :productType="productType"
            :currentListing="listing"
            :initialListing="props.currentListing"
          /> -->
          <div class="card flex justify-center m-3">
            <Button
              label="Cancel"
              class="mr-2"
              severity="info"
              @click="() => $emit('close')"
            />
          </div>
          <div
            v-tooltip.bottom="{ value: 'Please enter a title for your draft' }"
            class="card flex justify-center m-3"
          >
            <Button
              type="button"
              label="Save Draft"
              :loading="loading"
              @click="saveDraft"
              :disabled="isDisabled"
            />
          </div>
          <ListingUpdateSubmissionButton
            :sku="sku"
            :vendorCode="vendorCode"
            :asin="asin"
            :productType="productType"
            :currentListing="listing"
            :initialListing="props.currentListing"
            :currentIssues="currentIssues"
          />
        </div>
      </div>
      <div v-if="originalSchema" class="flex flex-col h-full overflow-y-scroll">
        <ImageUploader
          :images="imagesObject"
          :editable="true"
          :sku="sku"
          @update:images="handleImageUpdate"
          class="mb-3"
        />
        <json-forms
          :ajv="schemaValidator"
          :data="listing"
          :renderers="renderers"
          :schema="originalSchema"
          :uischema="uiSchema"
          @change="(data, errors) => setListing(data.data)"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, defineProps, provide, onMounted, onUpdated, computed } from "vue";
import ImageUploader from "@/components/listings/ImageUploader.vue";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import { generateGroupedUISchema } from "@/utils/schema";
import ListingUpdateSubmissionButton from "./ListingUpdateSubmissionButton.vue";
// import ListingUpdateValidationButton from "./ListingUpdateValidationButton.vue";
import { JsonForms } from "@jsonforms/vue";
import {
  defaultStyles,
  mergeStyles,
  vanillaRenderers,
} from "@jsonforms/vue-vanilla";
import "@/assets/json-forms.css";
import { useDashboardStore } from "@/stores/DashboardStore";
import { uploadData } from "aws-amplify/storage";

// Inject the styles provided by the parent component
// mergeStyles combines all classes from both styles definitions into one
const myStyles = mergeStyles(defaultStyles, {
  control: {
    label:
      "block w-full pb-1 text-md font-bold text-left font-bold text-[#121826]",
    input: "border border-gray-300 shadow px-3 py-2 w-full rounded-md",
    select:
      "appearance-none border border-gray-300 shadow px-3 py-2 w-full rounded-md text-[#121826]",
    root: "text-[#121826]",
    error: "text-red-500 text-start mt-1",
    description: "text-start text-sm my-1 !min-h-fit",
  },
  arrayList: {
    legend:
      "w-full bg-white flex-row-reverse bg-white flex justify-between items-center  border-0",
    label: "text-[16px] font-semibold text-[#121826]",
    addButton: "text-[28px] font-medium",
    itemWrapper: "p-3 border-2 rounded-lg mb-3 text-[#121826]",
    itemLabel:
      "flex-1 w-fit h-fit text-start font-medium text-[16px] !bg-transparent line-clamp-1",
    itemToolbar: "flex ",
    itemMoveUp:
      "ml-auto py-1 mr-1 px-2 text-black disabled:hidden text-[14px] bg-gray-200 hover:bg-gray-300 duration-300 rounded-lg size-[30px]",
    itemMoveDown:
      "py-1 px-2 mr-1 text-black disabled:hidden text-[14px] bg-gray-200 hover:bg-gray-300 duration-300 rounded-lg size-[30px]",
    itemDelete:
      "py-1 px-2 disabled:hidden text-[14px] rounded-lg bg-red-600 text-red-100 hover:bg-red-700 duration-300 size-[30px]",
    itemExpanded: "[&>.array-list-item-label]:line-clamp-6",
  },
  group: {
    label: "font-bold text-[20px] underline mb-3",
    root: "",
  },
  horizontalLayout: {
    root: "gap-5",
    item: "px-5 py-3 mb-5 shadow-md border-2 rounded-xl",
  },
});

// Provide the styles
provide("styles", myStyles);

onUpdated(() => {
  const controlElements = document.querySelectorAll(".control");
  const imgElements = document.querySelectorAll(".array-list-item-label");

  controlElements.forEach((controlElement) => {
    if (
      (controlElement.id && controlElement.id.includes("language_tag")) ||
      controlElement.id.includes("marketplace_id")
    ) {
      controlElement.style.display = "none";
    }
  });
  imgElements.forEach((imgElement) => {
    if (
      imgElement.innerHTML &&
      imgElement.innerHTML.includes("/images") &&
      !imgElement.innerHTML.includes("<img")
    ) {
      imgElement.innerHTML = `<img class='w-[100px] h-[150px] object-cover' alt='img'  src=${imgElement.innerHTML} >`;
    }
  });
});

const props = defineProps({
  sku: String,
  vendorCode: String,
  productType: String,
  currentListing: Object,
  productSchema: Object,
  propertyGroups: Object,
  asin: String,
  draftTitle: String,
});

const dashboardStore = useDashboardStore();

const sku = ref(props.sku);
const vendorCode = ref(props.vendorCode);
const asin = ref(props.asin);
const productType = ref(props.productType);
const listing = ref(JSON.parse(JSON.stringify(props.currentListing)));
const imagesObject = ref();
// const currentIssues = ref(props.currentIssues);
const productSchema = ref(props.productSchema);
const propertyGroups = ref(props.propertyGroups);
const uiSchema = ref();
const originalSchema = ref();
const draftTitle = ref(props.draftTitle);

const renderers = Object.freeze([
  ...vanillaRenderers,
  // here you can add custom renderers
]);

// Create a new Ajv instance with the required options (used for validating JSON schema)
const schemaValidator = new Ajv({
  allErrors: true,
  verbose: true,
  strict: false,
  validateSchema: false,
});
addFormats(schemaValidator);

const setListing = (newListing) => {
  if (newListing !== listing.value) {
    listing.value = JSON.parse(JSON.stringify(newListing));
  }
};

const isDisabled = computed(() => {
  return !draftTitle.value || draftTitle.value.trim() === "";
});

const createImagesObject = (listings) => {
  const imageKeys = [
    "main_product_image_locator",
    "other_product_image_locator_1",
    "other_product_image_locator_2",
    "other_product_image_locator_3",
    "other_product_image_locator_4",
    "other_product_image_locator_5",
    "other_product_image_locator_6",
    "other_product_image_locator_7",
    "other_product_image_locator_8",
    "swatch_product_image_locator",
  ];

  const images = {};

  imageKeys.forEach((key) => {
    images[key] =
      listings[key] && listings[key][0] && listings[key][0].media_location
        ? listings[key][0].media_location
        : null;
  });

  return images;
};

const handleImageUpdate = (updatedImages) => {
  Object.keys(updatedImages).forEach((key) => {
    if (!updatedImages[key] || updatedImages[key] === null) {
      delete listing.value[key];
    } else {
      if (listing.value[key] && listing.value[key][0]) {
        listing.value[key][0].media_location = updatedImages[key];
      } else {
        listing.value[key] = [
          {
            marketplace_id: dashboardStore.currentMarketplace,
            media_location: updatedImages[key],
          },
        ];
      }
    }
  });
};

// Fetch product type schema when component is mounted
onMounted(async () => {
  try {
    setListing(props.currentListing);
    imagesObject.value = createImagesObject(listing.value);
    originalSchema.value = productSchema.value;
    uiSchema.value = generateGroupedUISchema(propertyGroups.value);
  } catch (error) {
    console.error("Error fetching product type definition:", error);
  }
});

// Store the image in S3 and return the URL using amplify storage
async function uploadDraftToS3() {
  const sellerid = dashboardStore.currentSellerId;

  try {
    const result = await uploadData({
      key: `listing-drafts/${sellerid}/${sku.value}/${draftTitle.value}.json`,
      data: JSON.stringify(listing.value),
    }).result;

    const url = `https://listingimages100253-main.s3.eu-west-2.amazonaws.com/public/${sku.value}/${result.key}`;
    // const url = `https://listingimages111029-dev.s3.eu-west-2.amazonaws.com/public/${result.key}`;
    return url;
  } catch (error) {
    console.error("Error updating product listing:", error);
  }
}

async function saveDraft() {
  // Check if title and description are filled in
  if (!draftTitle.value.trim()) {
    return; // End the function execution
  }
  // If title available, save the draft
  await uploadDraftToS3();
  draftTitle.value = "";
}
</script>

<style lang="scss" scoped></style>
