import { Generate } from "@jsonforms/core";
import { cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";

const ID_SUFFIX = "/Augmented";

/**
 * This function removes some elements from the schema returned by the API in
 * order to generate the UI properly.
 * @param schema
 */
export function cleanUpSchemaForPresentation(schema) {
  try {
    const clonedSchema = cloneDeep(schema);
    delete clonedSchema.allOf;
    delete clonedSchema.anyOf;
    delete clonedSchema.oneOf;
    const schemaId = clonedSchema.$id || uuid();
    if (!schemaId.includes(ID_SUFFIX)) {
      clonedSchema.$id = schemaId + ID_SUFFIX;
    }
    return clonedSchema;
  } catch (error) {
    console.error("Error while cleaning up schema for presentation", error);
    return schema;
  }
}

/**
 * Generates the UI schema for the given schema.
 * @param schema
 */
export function generateUISchema(schema) {
  try {
    const res = Generate.uiSchema(cleanUpSchemaForPresentation(schema));
    const ui = {
      type: "Group",
      label: "Grouping Listing Attributes",
      elements: [res],
    };
    return ui;
  } catch (error) {
    console.error("Error while generating UI schema", error);
    return {};
  }
}

export function generateGroupedUISchema(propertyGroups) {
  try {
    const verticalLayout = {
      type: "VerticalLayout",
      elements: [],
    };

    // Define the specific order in which you want the group names to appear
    const orderedGroupNames = [
      "product_identity",
      "product_details",
      "shipping",
      "variations",
      "offer",
      "safety_and_compliance",
    ];

    // Map the group names to their data using a lookup
    const groupsMap = new Map(Object.entries(propertyGroups));

    // Helper function to create group elements
    const createGroupElement = (groupData) => ({
      type: "Group",
      label: groupData.title,
      elements: groupData.propertyNames.map((propertyName) => ({
        type: "Control",
        scope: `#/properties/${propertyName}`,
      })),
    });

    // Create groups based on the predefined order and wrap each pair in a HorizontalLayout
    for (let i = 0; i < orderedGroupNames.length; i += 2) {
      const horizontalLayout = {
        type: "HorizontalLayout",
        elements: [],
      };

      const groupName1 = orderedGroupNames[i];
      const groupData1 = groupsMap.get(groupName1);
      if (groupData1) {
        horizontalLayout.elements.push(createGroupElement(groupData1));
      }

      if (i + 1 < orderedGroupNames.length) {
        const groupName2 = orderedGroupNames[i + 1];
        const groupData2 = groupsMap.get(groupName2);
        if (groupData2) {
          horizontalLayout.elements.push(createGroupElement(groupData2));
        }
      }

      verticalLayout.elements.push(horizontalLayout);
    }
    return verticalLayout;
  } catch (error) {
    console.error("Error while generating grouped UI schema", error);
    return {};
  }
}

/**
 * Cleans up the listing in the Attribute form.
 * @param data
 */
export function cleanUpListing(data) {
  try {
    if (data === null || data === undefined) {
      return undefined;
    }

    if (typeof data === "string") {
      return !data?.length ? undefined : data;
    }

    if (Array.isArray(data)) {
      const filteredItems = (data || [])
        .map((value) => cleanUpListing(value))
        .filter((value) => value !== undefined);
      return !filteredItems?.length ? undefined : Array.from(filteredItems);
    }

    if (data instanceof Object) {
      const filteredEntries = Object.entries(data)
        .map(([key, value]) => [key, cleanUpListing(value)])
        // eslint-disable-next-line no-unused-vars
        .filter(([_key, value]) => value !== undefined);
      return !filteredEntries?.length
        ? undefined
        : Object.fromEntries(filteredEntries);
    }
  } catch (error) {
    console.error("Error while cleaning up listing", error);
  }

  return data;
}

/**
 * A common function which is used to clone and cleanup a listing. This method
 * is used by both the Json Listing Feed and Listings Item API submission
 * scenarios.
 * @param data the listing data.
 */
export function cloneAndCleanupListing(data) {
  try {
    return cleanUpListing(cloneDeep(data));
  } catch (error) {
    console.error("Error while cloning and cleaning up listing", error);
  }
}

/**
 * Validates the given data with the given schema using the given validator.
 * @param validator the Ajv validator for the validation.
 * @param schema the schema for the validation.
 * @param data the data to be validated.
 */
export function validateDataWithSchema(validator, schema, data) {
  try {
    const validateFunction = validator.getSchema(schema.$id || "");
    if (validateFunction) {
      // This implies the schema is already compiled by the validator.
      // Validate the data using the helper function from the compiled schema.
      return !validateFunction(data) && validateFunction.errors
        ? validateFunction.errors
        : [];
    } else {
      // This implies the schema is not yet compiled by the validator.
      // Invoke the validate method which compiles the schema and validates the
      // data.
      return !validator.validate(schema, data) && validator.errors
        ? validator.errors
        : [];
    }
  } catch (error) {
    console.error("Error while validating data with schema", error);
    return [];
  }
}
