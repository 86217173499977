<template>
  <div class="h-full overflow-y-scroll">
    <template v-if="!listing">
      <h1>No SKU found</h1>
    </template>
    <template v-else>
      <div v-if="originalSchema" class="flex flex-col h-full overflow-y-scroll">
        <ImageUploader :images="imagesObject" :editable="false" class="mb-3" />
        <json-forms
          :ajv="schemaValidator"
          :data="listing"
          :schema="originalSchema"
          :uischema="uiSchema"
          :renderers="renderers"
          readonly
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, defineProps, provide, onMounted } from "vue";
import ImageUploader from "@/components/listings/ImageUploader.vue";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import { generateGroupedUISchema } from "@/utils/schema";
import { JsonForms } from "@jsonforms/vue";
import {
  defaultStyles,
  mergeStyles,
  vanillaRenderers,
} from "@jsonforms/vue-vanilla";
import "@/assets/json-forms.css";

// Inject the styles provided by the parent component
// mergeStyles combines all classes from both styles definitions into one
const myStyles = mergeStyles(defaultStyles, {
  control: {
    label:
      "block w-full pb-1 text-md font-bold text-left font-bold text-[#121826]",
    input: "border border-gray-300 shadow px-3 py-2 w-full rounded-md",
    select:
      "appearance-none border border-gray-300 shadow px-3 py-2 w-full rounded-md text-[#121826]",
    root: "text-[#121826]",
    error: "text-red-500 text-start mt-1",
    description: "text-start text-sm my-1 !min-h-fit",
  },
  arrayList: {
    legend:
      "w-full bg-white flex-row-reverse bg-white flex justify-between items-center  border-0",
    label: "text-[16px] font-semibold text-[#121826]",
    addButton: "text-[28px] font-medium",
    itemWrapper: "p-3 border-2 rounded-lg mb-3 text-[#121826]",
    itemLabel:
      "flex-1 w-fit h-fit text-start font-medium text-[16px] !bg-transparent line-clamp-1",
    itemToolbar: "flex ",
    itemMoveUp:
      "ml-auto py-1 mr-1 px-2 text-black disabled:hidden text-[14px] bg-gray-200 hover:bg-gray-300 duration-300 rounded-lg size-[30px]",
    itemMoveDown:
      "py-1 px-2 mr-1 text-black disabled:hidden text-[14px] bg-gray-200 hover:bg-gray-300 duration-300 rounded-lg size-[30px]",
    itemDelete:
      "py-1 px-2 disabled:hidden text-[14px] rounded-lg bg-red-600 text-red-100 hover:bg-red-700 duration-300 size-[30px]",
    itemExpanded: "[&>.array-list-item-label]:line-clamp-6",
  },
  group: {
    label: "font-bold text-[20px] underline mb-3",
    root: "",
  },
  horizontalLayout: {
    root: "gap-5",
    item: "px-5 py-3 mb-5 shadow-md border-2 rounded-xl",
  },
});
// Provide the styles
provide("styles", myStyles);

// onUpdated(() => {
//   const controlElements = document.querySelectorAll(".control");
//   const imgElements = document.querySelectorAll(".array-list-item-label");

//   controlElements.forEach((controlElement) => {
//     if (
//       (controlElement.id && controlElement.id.includes("language_tag")) ||
//       controlElement.id.includes("marketplace_id")
//     ) {
//       controlElement.style.display = "none";
//     }
//   });
//   imgElements.forEach((imgElement) => {
//     if (imgElement.innerHTML && imgElement.innerHTML.includes("/images")) {
//       imgElement.innerHTML = `<img src=${imgElement.innerHTML} alt='img' width='100px'/>`;
//     }
//   });
// });

const props = defineProps({
  sku: String,
  productType: String,
  currentListing: Object,
  productSchema: Object,
  propertyGroups: Object,
});

// const sku = ref(props.sku);
// const productType = ref(props.productType);
const listing = ref(props.currentListing);
const productSchema = ref(props.productSchema);
const propertyGroups = ref(props.propertyGroups);
// const currentIssues = ref(props.currentIssues);
const originalSchema = ref();
const uiSchema = ref();
const imagesObject = ref();
const renderers = Object.freeze([
  ...vanillaRenderers,
  // here you can add custom renderers
]);

// Create a new Ajv instance with the required options (used for validating JSON schema)
const schemaValidator = new Ajv({
  allErrors: true,
  verbose: true,
  strict: false,
  validateSchema: true,
});
addFormats(schemaValidator);

// Fetch product type schema when component is mounted
onMounted(async () => {
  try {
    originalSchema.value = productSchema.value;
    uiSchema.value = generateGroupedUISchema(propertyGroups.value);
    imagesObject.value = createImagesObject(listing.value);
  } catch (error) {
    console.error("Error fetching product type definition:", error);
  }
});

const createImagesObject = (listings) => {
  const imageKeys = [
    "main_product_image_locator",
    "other_product_image_locator_1",
    "other_product_image_locator_2",
    "other_product_image_locator_3",
    "other_product_image_locator_4",
    "other_product_image_locator_5",
    "other_product_image_locator_6",
    "other_product_image_locator_7",
    "other_product_image_locator_8",
    "swatch_product_image_locator",
  ];

  const images = {};

  imageKeys.forEach((key) => {
    images[key] =
      listings[key] && listings[key][0] && listings[key][0].media_location
        ? listings[key][0].media_location
        : null;
  });

  return images;
};
</script>

<style lang="scss" scoped></style>
