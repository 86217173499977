<template>
  <Dialog
    v-model:visible="propsVisible"
    :modal="true"
    :closable="true"
    :draggable="false"
    header="Create New ASIN Set"
    class="flex flex-col h-full"
  >
    <div class="flex flex-col flex-grow overflow-hidden">
      <div class="mb-4">
        <label for="groupName" class="block text-sm font-medium text-gray-700"
          >ASIN SET NAME *</label
        >
        <InputText id="groupName" v-model="groupName" class="w-full mt-1" />
        <InlineMessage v-if="groupNameError" class="text-red-500"
          >ASIN Set Name is required.</InlineMessage
        >
        <label for="asinGroup" class="block text-sm font-medium text-gray-700"
          >ASIN INPUT</label
        >
        <InputText
          v-model="asinInputs"
          id="asinGroup"
          class="w-full mt-1 mb-4"
          placeholder="Enter ASINs separated by commas to quickly add a list..."
        />
        <Button label="ADD ASINS" class="w-full" @click="processAsins" />
      </div>
      <div class="flex-grow overflow-y-auto">
        <PickList
          v-model="sourceAsins"
          v-selection="targetAsins"
          sourceStyle="min-height: 300px; height: 100%;"
          targetStyle="min-height: 300px; height: 100%;"
        >
          <template #sourceheader>
            Available
            <Dropdown
              :value="null"
              :options="sourceAsins[0]"
              optionLabel="name"
              optionValue="asin"
              placeholder="Search available ASINs"
              class="w-full mt-2"
              filter
              @change="onAsinSelect"
            />
          </template>
          <template #targetheader>Selected</template>
          <template #item="slotProps">
            <div class="flex items-center gap-4 p-2">
              <img
                :src="slotProps.item.image"
                alt="Product Image"
                class="w-12 h-12"
              />
              <div class="flex-1">
                <a
                  :href="`/product/${slotProps.item.asin}`"
                  class="text-blue-500 hover:underline"
                  >{{ slotProps.item.name }}</a
                >
                <div class="text-sm text-gray-500">
                  ASIN: {{ slotProps.item.asin }} | SKU:
                  {{ slotProps.item.sku }}
                </div>
              </div>
            </div>
          </template>
        </PickList>
      </div>
    </div>
    <div class="mt-4">
      <Button
        :loading="loading"
        label="CREATE SET"
        @click="saveAsinSet"
        class="w-full"
      />
    </div>
  </Dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";
import {
  useCustomUpdater,
  useCustomFetcher,
} from "../utils/callersForBackendApi";
import { useDashboardStore } from "../stores/DashboardStore";
import { useDataExplorerStore } from "../stores/DataExplorerStore";

const props = defineProps({
  visible: Boolean,
  catalogSummary: Array,
});
const emit = defineEmits(["update:visible", "saveAsinSet"]);

const dashboardStore = useDashboardStore();
const dataExplorerStore = useDataExplorerStore();

const propsVisible = ref(props.visible);
const groupName = ref("");
const sourceAsins = ref([props.catalogSummary, []]);
const loading = ref(false);
const groupNameError = ref(false);
const asinInputs = ref("");

// Watch for changes to the visible prop and update the internal state
watch(
  () => props.visible,
  (newValue) => {
    propsVisible.value = newValue;
  }
);

const createAsinSet = async (asinSetName, asinSet, sharedWith) => {
  try {
    const params = {
      queryParams: {
        body: JSON.stringify({
          sellerid: dashboardStore.currentSellerId,
          marketplaceid: dashboardStore.currentMarketplace,
          asinSetName: asinSetName,
          asinSet: asinSet,
          sharedWith: sharedWith,
        }),
      },
    };
    const result = await useCustomUpdater("/createAsinSet", params);
    return result;
  } catch (error) {
    console.error("Error creating asin set:", error);
  }
};

const getAsinSets = async () => {
  try {
    const params = {
      queryParams: {},
    };

    const result = await useCustomFetcher("/getAsinSets", params);
    return result;
  } catch (error) {
    console.error("Error fetching Asin Sets:", error);
  }
};

// Watch for changes to the internal state and emit an update event
watch(propsVisible, (newValue) => {
  emit("update:visible", newValue);
});

const saveAsinSet = async () => {
  try {
    if (!groupName.value) {
      groupNameError.value = true;
      return;
    }
    // Perform your save operation here
    loading.value = true;

    const asins = sourceAsins.value[1].map((asin) => asin.asin);
    const res = await createAsinSet(groupName.value, asins, []);
    if (res !== "Successfully created Asin Set!") {
      throw new Error("Error creating ASIN Set");
    }
    const asinSets = await getAsinSets();

    // Update the store with the new ASIN sets & filter for seller
    dataExplorerStore.setAsinSets(asinSets);
    dataExplorerStore.setAsinSetsForSellerid(
      asinSets,
      dashboardStore.currentSellerId
    );
    loading.value = false;
    propsVisible.value = false;
  } catch (error) {
    console.error("Error saving ASIN Set:", error);
  }
};

const moveToSelected = (asin) => {
  const asinIndex = sourceAsins.value[0].findIndex(
    (item) => item.asin === asin
  );
  if (asinIndex !== -1) {
    const item = sourceAsins.value[0][asinIndex];
    sourceAsins.value[0].splice(asinIndex, 1);
    sourceAsins.value[1].push(item);
  }
};

// Function to quickly add a list of ASINs
const processAsins = () => {
  const asins = asinInputs.value.split(",").map((asin) => asin.trim());
  asins.forEach((asin) => {
    try {
      moveToSelected(asin);
    } catch (error) {
      console.error("Error moving ASIN to selected:", error);
    }
  });
};

const onAsinSelect = (event) => {
  moveToSelected(event.value);
};
</script>

<style scoped></style>
