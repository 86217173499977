import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home";
import MyAccounts from "../views/MyAccounts";
import AddClient from "../views/AddClient";
import MyProfile from "../views/MyProfile";
import AuthComponent from "../views/Auth";
import AuthCallback from "../views/AuthCallback";
import NotFound from "../views/NotFound";
import { Hub } from "aws-amplify/utils";
import {
  currentAuthenticatedUser,
  getCurrentUserSessionDetails,
} from "../utils/auth";
import { getDashboardList, listQuicksightUserGroups } from "@/utils/api";
import { signOut } from "aws-amplify/auth";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { auth: true },
    beforeEnter: async (to, from, next) => {
      const isAuthenticated = await currentAuthenticatedUser();
      if (isAuthenticated) {
        next({ name: "MyAccounts" }); // Redirect to MyAccounts route
      } else {
        next(); // Proceed with navigation
      }
    },
  },
  {
    path: "/my-accounts",
    name: "MyAccounts",
    component: MyAccounts,
    meta: { auth: true },
  },
  {
    path: "/add-client",
    name: "AddClient",
    component: AddClient,
  },
  {
    path: "/profile",
    name: "Profile",
    component: MyProfile,
    meta: { auth: true },
  },
  {
    path: "/auth",
    name: "Auth",
    component: AuthComponent,
  },
  {
    path: "/auth/callback",
    name: "AuthCallback",
    component: AuthCallback,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve(async (to, from, next) => {
  if (to.meta.auth) {
    const isAuthenticated = await currentAuthenticatedUser();
    if (isAuthenticated) {
      next();
    } else {
      console.log("User is not logged in");
      next({
        name: "Auth",
      });
    }
  } else {
    next();
  }
});

Hub.listen("auth", async ({ payload }) => {
  switch (payload.event) {
    case "signedIn":
      try {
        // Get User Session Details
        const userSessionDetails = await getCurrentUserSessionDetails();
        const params = {
          queryParams: userSessionDetails,
        };
        getDashboardList(params);
        listQuicksightUserGroups(params);
      } catch (error) {
        console.error("Error fetching tokens:", error);
      }
      router.push({ name: "MyAccounts" });
      break;
    case "signedOut":
      router.push({ name: "Auth" });
      break;
    case "sessionExpired":
      await signOut();
      router.push({ name: "Auth" });
      break;
    default:
      break;
  }
});

export default router;
