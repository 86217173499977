import { defineStore } from "pinia";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    user: {
      username: "",
      email: "",
      jwtToken: "",
      payloadSub: "",
    },
    addedClient: {
      clientName: "",
      activeMarketplaces: [],
      accountType: "",
    },
  }),
  actions: {
    setUser(username, email, jwtToken, payloadSub) {
      this.user.username = username;
      this.user.email = email;
      this.user.jwtToken = jwtToken;
      this.user.payloadSub = payloadSub;
    },
    setAddedClient(clientName, activeMarketplaces, accountType) {
      this.addedClient.clientName = clientName;
      this.addedClient.activeMarketplaces = activeMarketplaces;
      this.addedClient.accountType = accountType;
    },
  },
});
