<template>
  <div v-show="catalogVisible" class="catalog h-full overflow-y-hidden">
    <div v-if="!catalogSummaryData" class="border-t border-b px-4 py-3">
      <div
        v-if="!dashboardStore.currentSellerId"
        class="flex justify-center items-center h-full"
      >
        Please select an account to view catalog data.
      </div>
      <div v-else class="flex justify-center items-center h-full">
        <ProgressSpinner />
      </div>
    </div>
    <div v-else class="catalog-body p-4 h-full overflow-y-scroll">
      <div v-if="productLoading">
        <div class="flex justify-center items-center h-full">
          <ProgressSpinner />
        </div>
      </div>
      <div v-else class="h-full overflow-sroll">
        <div class="flex w-full h-1/3 mx-6">
          <div
            class="card flex justify-center w-1/3 bg-across-white p-3 rounded"
          >
            <Chart
              type="pie"
              :data="asinChartData"
              :options="asinChartOptions"
              class="w-full h-full"
            />
          </div>
          <div class="card flex justify-center w-1/2"></div>
        </div>
        <TabView class="flex-grow overflow-scroll m-6 flex flex-col">
          <TabPanel class="flex flex-col h-full overflow-hidden">
            <template #header>
              <div class="flex align-items-center gap-2">
                <span class="pi pi-fw pi-book"></span>
                <span class="font-bold white-space-nowrap">Catalog</span>
              </div>
            </template>
            <div v-if="loading" class="h-full overflow-y-scroll p-6">
              <ProgressSpinner />
            </div>
            <div v-else class="h-full overflow-y-scroll px-6">
              <template v-if="catalogSummaryData.length === 0">
                <h1>
                  No products found for this account/marketplace combination.
                </h1>
              </template>
              <template v-else>
                <DataTable
                  v-model:selection="selectedProduct"
                  v-model:filters="filters"
                  :value="catalogSummaryData"
                  selectionMode="single"
                  dataKey="asin"
                  :metaKeySelection="false"
                  @rowSelect="onRowSelect"
                  @rowUnselect="onRowUnselect"
                  ref="dt"
                  scrollable
                  scrollHeight="500px"
                  tableStyle="min-width:
        50rem"
                  paginator
                  :rows="20"
                  :rowsPerPageOptions="[25, 50, 100]"
                  :globalFilterFields="['asin', 'name']"
                >
                  <template #header>
                    <div class="flex justify-between mb-2">
                      <div>
                        <Button
                          label="ASIN Set"
                          icon="pi pi-chevron-down"
                          class="p-button p-component p-button-text p-button-plain p-0 focus:outline-none"
                          @click="toggleAsinSetMenu"
                          aria-controls="overlay_menu"
                          aria-haspopup="true"
                          severity="info"
                        />
                        <Menu
                          :model="asinSets"
                          popup
                          ref="asinSetsMenu"
                          class="w-56 mt-2 origin-top-right bg-white border border-gray-300 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
                        />
                      </div>
                      <span class="relative">
                        <i
                          class="pi pi-search absolute top-2/4 -mt-2 left-3 text-surface-400 dark:text-surface-600"
                        />
                        <InputText
                          v-model="filters['global'].value"
                          placeholder="Search by ASIN or name"
                          class="pl-10 font-normal"
                        />
                      </span>
                    </div>
                  </template>
                  <template #empty>No products found.</template>
                  <Column field="image" header="Image" style="min-width: 5rem">
                    <template #body="slotProps">
                      <img
                        :src="slotProps.data.image"
                        alt="Product Image"
                        class="w-12 h-12"
                      />
                    </template>
                  </Column>
                  <Column field="asin" header="ASIN" style="min-width: 6rem">
                    <template #body="slotProps">
                      {{ slotProps.data.asin }}
                    </template>
                  </Column>
                  <Column field="name" header="Name" style="min-width: 10rem">
                    <template #body="slotProps">
                      {{ slotProps.data.name }}
                    </template>
                  </Column>
                  <Column field="sku" header="SKU" style="min-width: 3rem">
                    <template #body="slotProps">
                      {{ slotProps.data.sku }}
                    </template>
                  </Column>
                  <Column
                    field="ordered revenue"
                    header="Ordered Revenue"
                    style="min-width: 6rem"
                  >
                    <template #body="slotProps">
                      {{ currencySymbol
                      }}{{ slotProps.data["ordered revenue"] }}
                    </template>
                  </Column>
                  <Column
                    field="ordered units"
                    header="Ordered Units"
                    style="min-width: 6rem"
                  >
                    <template #body="slotProps">
                      {{ slotProps.data["ordered units"] }}
                    </template>
                  </Column>
                  <Column
                    field="glance views"
                    header="Glance Views"
                    style="min-width: 6rem"
                  >
                    <template #body="slotProps">
                      {{ slotProps.data["glance views"] }}
                    </template>
                  </Column>
                  <Column
                    field="conversion"
                    header="Conversion"
                    style="min-width: 6rem"
                  >
                    <template #body="slotProps">
                      {{ Math.round(slotProps.data.conversion * 100) }}%
                    </template>
                  </Column>
                  <Column
                    field="contribution to sales"
                    header="Contribution to Sales"
                    style="min-width: 6rem"
                  >
                    <template #body="slotProps">
                      {{
                        Math.round(
                          slotProps.data["contribution to sales"] * 100
                        )
                      }}%
                    </template>
                  </Column>
                  <Column
                    field="profit"
                    header="Profit"
                    style="min-width: 6rem"
                  >
                    <template #body="slotProps">
                      {{ Math.round(slotProps.data.profit * 100) }}%
                    </template>
                  </Column>
                  <Column :exportable="false" style="min-width: 3rem">
                    <template #body="slotProps">
                      <Button
                        icon="pi pi-pencil"
                        outlined
                        rounded
                        class="mr-2"
                        @click="onRowSelect(slotProps)"
                      />
                    </template>
                  </Column>
                </DataTable>
              </template>
            </div>
          </TabPanel>
          <TabPanel class="flex flex-col h-full overflow-hidden">
            <template #header>
              <div class="flex align-items-center gap-2">
                <span class="pi pi-fw pi-history"></span>
                <span class="font-bold white-space-nowrap">History</span>
              </div>
            </template>
            <div v-if="loading" class="h-full overflow-y-scroll p-6">
              <ProgressSpinner />
            </div>
            <div v-else class="h-full overflow-y-scroll px-6">
              <ListingHistory :sku="null" />
            </div>
          </TabPanel>
          <TabPanel class="flex flex-col h-full overflow-hidden">
            <template #header>
              <div class="flex align-items-center gap-2">
                <span class="pi pi-fw pi-chart-line"></span>
                <span class="font-bold white-space-nowrap"
                  >Change Impact Analysis</span
                >
              </div>
            </template>
            <div class="h-full overflow-y-scroll px-6">
              <ListingsChangeImpactAnalyser />
            </div>
          </TabPanel>
          <TabPanel class="flex flex-col h-full overflow-hidden">
            <template #header>
              <div class="flex align-items-center gap-2">
                <span class="pi pi-fw pi-bell"></span>
                <span class="font-bold white-space-nowrap">Notifications</span>
                <Badge :value="notificationCount" class="ml-4" />
              </div>
            </template>
            <div v-if="loading" class="h-full overflow-y-scroll p-6">
              <ProgressSpinner />
            </div>
            <div v-else class="h-full overflow-y-scroll px-6">
              <NotificationsList
                :asin="null"
                @notificationCount="handleNotificationCount"
              />
            </div>
          </TabPanel>
        </TabView>
      </div>
      <CreateAsinSet
        v-if="createAsinSetFormVisible"
        v-model:visible="createAsinSetFormVisible"
        :catalogSummary="catalogSummaryData"
      />
      <EditAsinSet
        v-if="editAsinSetFormVisible"
        v-model:visible="editAsinSetFormVisible"
        :targetAsins="targetAsins"
        :sourceAsins="sourceAsins"
        :asinSet="selectedAsinSet"
      />
    </div>
  </div>
  <div class="product-details h-full flex flex-col" v-show="productVisible">
    <ProductPage
      v-if="sku && currentListing"
      :sku="sku"
      :vendorCode="vendorCode"
      :productListing="currentListing"
      @on-row-unselect="onRowUnselect"
    />
  </div>
</template>

<script setup>
import ProductPage from "@/components/catalog/ProductPage.vue";
import ListingHistory from "@/components/listings/ListingHistory.vue";
import ListingsChangeImpactAnalyser from "@/components/listings/ListingsChangeImpactAnalyser.vue";
import NotificationsList from "@/components/catalog/NotificationsList.vue";
import CreateAsinSet from "@/components/CreateAsinSet.vue";
import EditAsinSet from "@/components/EditAsinSet.vue";
import { useCustomFetcher } from "@/utils/callersForBackendApi";
import { getCachedData, setCachedData } from "@/utils/cache";
import { getLastFullMonthDates } from "@/utils/utils";
import { useToast } from "primevue/usetoast";
import { useCatalogStore } from "@/stores/CatalogStore";
import { useDashboardStore } from "@/stores/DashboardStore";
import { useDataExplorerStore } from "@/stores/DataExplorerStore";
import { watch, ref, watchEffect, computed } from "vue";
import { FilterMatchMode } from "primevue/api";

const catalogStore = useCatalogStore();
const dashboardStore = useDashboardStore();
const dataExplorerStore = useDataExplorerStore();
const toast = useToast();

const catalogVisible = ref(true);
const productVisible = ref(false);
const catalogSummaryData = ref();
const columns = ref([]);
const dt = ref();
const selectedColumns = ref(columns.value);
const sku = ref(null);
const vendorCode = ref(null);
const currentListing = ref(null);
const productLoading = ref(false);
const loading = ref(false);
const notificationCount = ref(0);
const asinSetsMenu = ref();
const createAsinSetFormVisible = ref(false);
const editAsinSetFormVisible = ref(false);
const selectedAsinSet = ref(null);
const targetAsins = ref(null);
const sourceAsins = ref(null);
const asinChartData = ref();
const asinChartOptions = ref();

const setAsinChartData = (metrics, maxDataPoints = 10) => {
  // Sort metrics by contribution to sales in descending order
  metrics.sort(
    (a, b) => b["contribution to sales"] - a["contribution to sales"]
  );

  let labels = [];
  let data = [];
  let otherSum = 0;

  metrics.forEach((item, index) => {
    if (index < maxDataPoints - 1) {
      labels.push(item.asin);
      data.push(Math.round(parseFloat(item["contribution to sales"]) * 100));
    } else {
      otherSum += Math.round(parseFloat(item["contribution to sales"]) * 100);
    }
  });

  // Add the "Others" category if there are more than maxDataPoints
  if (otherSum > 0) {
    labels.push("Others");
    data.push(otherSum);
  }

  return {
    labels: labels,
    datasets: [
      {
        label: "Contribution to Sales",
        data: data,
      },
    ],
  };
};

const setAsinChartOptions = () => {
  return {
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
      title: {
        display: true,
        align: "center",
        text: "Revenue Distribution by ASIN",
      },
      legend: {
        display: false,
        position: "right",
        align: "center",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed + "%";
            }
            return label;
          },
        },
      },
    },
  };
};

const currencySymbol = computed(() => {
  switch (dashboardStore.currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return dashboardStore.currency; // Return the currency code if it's not USD, EUR, or GBP
  }
});

const handleAsinSetEdit = (key) => {
  selectedAsinSet.value = dataExplorerStore.RAW_ASIN_SETS.find(
    (set) => set.asinSetName === key
  );

  if (selectedAsinSet.value) {
    // Extract ASINs from selectedAsinSet
    const asinSetAsins = selectedAsinSet.value.asins;

    // Populate targetAsins with items from catalogSummary that match the ASINs in asinSet
    targetAsins.value = catalogSummaryData.value.filter((item) =>
      asinSetAsins.includes(item.asin)
    );

    // Populate sourceAsins with remaining items from catalogSummary
    sourceAsins.value = catalogSummaryData.value.filter(
      (item) => !asinSetAsins.includes(item.asin)
    );
  }

  editAsinSetFormVisible.value = true;
};

const createNewSet = () => {
  createAsinSetFormVisible.value = true;
};

const filters = ref({
  global: {
    value: null,
    matchMode: FilterMatchMode.CONTAINS,
  },
});

const toggleAsinSetMenu = (event) => {
  asinSetsMenu.value.toggle(event);
};

const asinSets = computed(() => {
  return Object.keys(dataExplorerStore["ASIN SETS"])
    .map((key) => ({
      label: key,
      icon: "pi pi-fw pi-pencil",
      command: () => handleAsinSetEdit(key),
    }))
    .concat(
      {
        separator: true,
      },
      {
        label: "Create New ASIN Set",
        icon: "pi pi-plus",
        command: createNewSet,
      }
    );
});

const handleNotificationCount = (count) => {
  notificationCount.value = count;
};

const ProductNotFound = () => {
  productLoading.value = false;
  toast.add({
    severity: "error",
    summary: "Error fetching product",
    detail:
      "There was an error fetching the product details. Please check that this seller has this product listed in this marketplace and try again.",
    life: 6000,
  });
};

const CatalogNotFound = () => {
  loading.value = false;
  toast.add({
    severity: "error",
    summary: "Error fetching catalog",
    detail:
      "There was an error fetching the catalog data. Please check the seller, marketplace combination and try again.",
    life: 6000,
  });
};

const onRowSelect = async (event) => {
  try {
    productLoading.value = true;
    sku.value = event.data.sku;
    vendorCode.value = event.data["vendor code"];
    const listing = await getProductListingDetails(sku.value, vendorCode.value);
    productLoading.value = false;
    currentListing.value = listing;
    productVisible.value = true;
    catalogVisible.value = false;
  } catch {
    productLoading.value = false;
    sku.value = null;
    vendorCode.value = null;
    currentListing.value = null;
    productVisible.value = false;
    catalogVisible.value = true;
  }
};

const onRowUnselect = () => {
  productVisible.value = false;
  catalogVisible.value = true;
  sku.value = null;
  vendorCode.value = null;
};

const getCatalog = async () => {
  try {
    const sellerType =
      dashboardStore.currentDashboardId ===
      "7899d9c6-721a-4657-9719-b8902f4701d0"
        ? "vendor"
        : "seller";

    const [startdate, enddate] = getLastFullMonthDates();

    // Get access token from cache, if unavailable returns null
    const access_token = getCachedData("access_token");

    const params = {
      queryParams: {
        sellerid: dashboardStore.currentSellerId,
        startdate: startdate,
        enddate: enddate,
        currency: dashboardStore.currency,
        sellerType: sellerType,
        marketplaceid: dashboardStore.currentMarketplace,
        access_token: access_token,
      },
    };

    const result = await useCustomFetcher("/getCatalogMetrics", params);
    catalogStore.setCatalogMetrics(result);
    return result;
  } catch (error) {
    CatalogNotFound(error);
  }
};

const getProductListingDetails = async (sku, vendorCode) => {
  try {
    const seller_type =
      dashboardStore.currentDashboardId ===
      "7899d9c6-721a-4657-9719-b8902f4701d0"
        ? "vendor"
        : "seller";

    // Get access token from cache, if unavailable returns null
    const access_token = getCachedData("access_token");

    const params = {
      queryParams: {
        sellerid: dashboardStore.currentSellerId,
        vendorCode: vendorCode,
        sellerType: seller_type,
        sku: sku,
        marketplaceid: dashboardStore.currentMarketplace,
        access_token: access_token,
      },
    };

    // Make API call to get product details from SP API
    let productDetails = await useCustomFetcher("/getProductDetails", params);

    if (productDetails.status_code !== 200) {
      throw new Error(productDetails.message);
    }

    productDetails = productDetails.body;

    // Update access token in cache if new token is returned
    if (productDetails.newAccessToken) {
      setCachedData("access_token", productDetails.accessToken, 60);
    }

    return productDetails;
  } catch (error) {
    ProductNotFound(error);
    throw error;
  }
};

watch(
  () => dashboardStore.currentSellerId,
  async (newVal) => {
    try {
      // Call getFilters function when currentSellerId changes
      loading.value = true;
      sku.value = null;
      currentListing.value = null;
      productVisible.value = false;
      catalogVisible.value = true;
      const catalogMetrics = await getCatalog(newVal);
      asinChartData.value = setAsinChartData(catalogMetrics);
      asinChartOptions.value = setAsinChartOptions();
      loading.value = false;
    } catch (error) {
      console.error("Error fetching catalog:", error);
      loading.value = false;
    }
  }
);

watch(
  () => dashboardStore.currentMarketplace,
  async (newVal) => {
    try {
      // Call getFilters function when currentSellerId changes
      loading.value = true;
      sku.value = null;
      currentListing.value = null;
      productVisible.value = false;
      catalogVisible.value = true;
      const catalogMetrics = await getCatalog(newVal);
      asinChartData.value = setAsinChartData(catalogMetrics);
      asinChartOptions.value = setAsinChartOptions();
      loading.value = false;
    } catch (error) {
      console.error("Error fetching catalog:", error);
      loading.value = false;
    }
  }
);

watchEffect(() => {
  // Update catalogSummaryData when data in the store changes
  catalogSummaryData.value = catalogStore.catalogMetrics;

  if (catalogSummaryData.value && catalogSummaryData.value.length > 0) {
    columns.value = Object.keys(catalogSummaryData.value[0]).map((col) => {
      return {
        field: col,
        header: col,
      };
    });
    selectedColumns.value = columns.value;
  } else {
    columns.value = [];
    selectedColumns.value = [];
  }
});
</script>
