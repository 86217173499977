<template>
  <Dialog
    v-model:visible="propsVisible"
    :modal="true"
    :closable="true"
    :draggable="false"
    header="Add New Client"
  >
    <p class="font-bold text-across-pink">
      Please ensure you have selected the correct account in the Amazon platform
      BEFORE you attempt to connect the account
    </p>
    <form class="p-6" @submit.prevent="handleSubmit">
      <div class="flex justify-between mb-4">
        <div class="w-1/2 mr-2">
          <label for="clientName" class="block mb-1">Client Name</label>
          <InputText
            id="clientName"
            v-model="clientName"
            placeholder="Client Name"
            class="w-full p-inputtext-sm"
            :invalid="clientNameError"
          />
        </div>
      </div>
      <div class="mb-4">
        <label class="block mb-2">Active Countries</label>
        <div class="flex flex-wrap gap-4">
          <div
            v-for="country in countries"
            :key="country.id"
            class="flex items-center"
          >
            <Checkbox
              v-model="activeCountries"
              :value="country.code"
              class="p-checkbox-md mr-2"
            />
            {{ country.code }}
          </div>
        </div>
        <InlineMessage v-if="activeCountriesError" severity="error" class="mt-2"
          >Please select at least one country.</InlineMessage
        >
      </div>
      <div class="mb-4">
        <label class="block mb-2">Connect Account with</label>
        <div class="flex flex-wrap gap-4 items-center">
          <Checkbox
            v-model="accountType"
            value="vendor"
            class="p-checkbox-md mr-2"
          />
          <img
            src="../assets/amazonvendorcentral.png"
            alt="Vendor Central"
            class="h-8 transform scale-150"
          />
          <Checkbox
            v-model="accountType"
            value="advertising"
            class="p-checkbox-md"
          />
          <img
            src="../assets/Amazon-Ads-logo.png"
            alt="Amazon Ads"
            class="h-8"
          />
          <Checkbox
            v-model="accountType"
            value="seller"
            class="p-checkbox-md mr-2"
          />
          <img
            src="../assets/amazonsellercentral.png"
            alt="Seller Central"
            class="h-8 transform scale-150"
          />
        </div>
        <InlineMessage v-if="accountTypeError" severity="error" class="mt-2"
          >Please select at least one account type.</InlineMessage
        >
      </div>
      <div class="text-center">
        <Button
          label="Log In with Amazon"
          class="bg-across-purple text-white p-2 rounded-full w-full mt-4"
          severity="secondary"
          @click="handleSubmit"
        />
      </div>
      <p v-if="formError" class="text-red-500 text-center mt-4">
        {{ formError }}
      </p>
    </form>
  </Dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";
import { setCachedData } from "@/utils/cache";
import {
  spapiLwaClientId,
  sellerCentralUrls,
  vendorCentralUrls,
  advertisingLwaClientId,
  advertisingConsoleUrls,
  marketplaces,
} from "../constants/index";

const props = defineProps(["visible"]);
const emit = defineEmits(["update:visible"]);

const countries = Object.entries(marketplaces).map(([id, data]) => ({
  id,
  ...data,
}));

const propsVisible = ref(props.visible);
const clientName = ref("");
const accountType = ref("");
const activeCountries = ref([]);
const formError = ref("");
const clientNameError = ref(false);
const accountTypeError = ref(false);
const activeCountriesError = ref(false);

// Watch for changes to the visible prop and update the internal state
watch(
  () => props.visible,
  (newValue) => {
    propsVisible.value = newValue;
  }
);

// Watch for changes to the internal state and emit an update event
watch(propsVisible, (newValue) => {
  emit("update:visible", newValue);
});

const handleSubmit = () => {
  clientNameError.value = !clientName.value;
  accountTypeError.value = !accountType.value;
  activeCountriesError.value = activeCountries.value.length === 0;

  if (
    clientNameError.value ||
    accountTypeError.value ||
    activeCountriesError.value
  ) {
    formError.value = "Please fill in all required fields.";
    return;
  }
  setCachedData("client_name", clientName.value, 5);
  setCachedData("active_countries", activeCountries.value, 5);
  setCachedData("account_type", accountType.value, 5);

  // Start OAuth flow here
  if (accountType.value[0] === "advertising") {
    const adsOauthUri = constructAdsOauthUri();
    window.location.href = adsOauthUri;
  } else {
    const oauthUri = constructOAuthUri();
    // Redirect to the OAuth URI
    window.location.href = oauthUri;
  }
  propsVisible.value = false; // Close the dialog after form submission
};

const constructOAuthUri = () => {
  const redirectUri = `${window.location.origin}/auth/callback`;
  const clientId = spapiLwaClientId;
  const state = "random-state-string";

  const url =
    accountType.value[0] === "vendor"
      ? vendorCentralUrls[activeCountries.value[0]]
      : sellerCentralUrls[activeCountries.value[0]];
  return `${url}/apps/authorize/consent?application_id=${clientId}&state=${state}&redirect_uri=${redirectUri}&version=beta`;
};

const constructAdsOauthUri = () => {
  const redirectUri = `${window.location.origin}/auth/callback`;
  const clientId = advertisingLwaClientId;

  const url = advertisingConsoleUrls[activeCountries.value[0]];
  console.log(url);

  return `${url}?client_id=${clientId}&scope=advertising::campaign_management&response_type=code&redirect_uri=${redirectUri}`;
};
</script>

<style scoped></style>
