// Function to set cached data with expiration time
export function setCachedData(key, data, expirationMinutes) {
  const expirationTime = new Date().getTime() + expirationMinutes * 60 * 1000;
  const item = { data, expirationTime };
  localStorage.setItem(key, JSON.stringify(item));
}

// Function to get cached data
export function getCachedData(key) {
  const item = localStorage.getItem(key);
  if (!item) {
    return null; // No cached data
  }

  const parsedItem = JSON.parse(item);

  if (parsedItem.expirationTime < new Date().getTime()) {
    localStorage.removeItem(key);
    return null; // Cached data expired
  }

  return parsedItem.data; // Return cached data
}

// Function to remove cached data
export function removeCachedData(key) {
  localStorage.removeItem(key);
}
