<template>
  <div class="p-6">
    <h1 class="text-2xl font-bold mb-4">Change Impact Analysis</h1>
    <div class="p-4 bg-white rounded-lg shadow-md mb-6">
      <div class="flex items-center justify-center gap-4 mb-4">
        <div class="flex items-center gap-2">
          <span class="font-medium">Select Type:</span>
          <SelectButton
            v-model="selectedType"
            :options="typeOptions"
            class="mr-3"
          />
        </div>
        <div v-if="errorMessages.type" class="text-red-500">
          {{ errorMessages.type }}
        </div>

        <div class="flex items-center gap-2" v-if="selectedType === 'ASIN'">
          <Dropdown
            v-model="selectedAsin"
            :options="asinOptions"
            optionLabel="value"
            optionValue="key"
            placeholder="Select ASIN"
            class="w-48"
          />
        </div>
        <div v-if="errorMessages.asin" class="text-red-500">
          {{ errorMessages.asin }}
        </div>

        <div class="flex items-center gap-2" v-if="selectedType === 'ASIN SET'">
          <Dropdown
            v-model="selectedAsinSet"
            :options="asinSetOptions"
            placeholder="Select ASIN Set"
            class="w-48"
          />
        </div>
        <div v-if="errorMessages.asinSet" class="text-red-500">
          {{ errorMessages.asinSet }}
        </div>

        <div class="flex items-center gap-2">
          <Calendar
            v-model="date"
            placeholder="Select Change Date"
            class="w-48"
          />
        </div>
        <div v-if="errorMessages.date" class="text-red-500">
          {{ errorMessages.date }}
        </div>

        <Button label="Run Analysis" @click="runAnalysis" />
      </div>
    </div>
    <ListingChangeImpactChart
      v-if="analysisObject"
      v-model:visible="visible"
      :listingChangeEvent="analysisObject"
    />
  </div>
</template>

<script setup>
import ListingChangeImpactChart from "./ListingChangeImpactChart.vue";
import { useDashboardStore } from "@/stores/DashboardStore";
import { useDataExplorerStore } from "@/stores/DataExplorerStore";
import { ref, computed, watch } from "vue";

const dashboardStore = useDashboardStore();
const dataExplorerStore = useDataExplorerStore();

const visible = ref(false);
const analysisObject = ref(null);
const typeOptions = ["ASIN", "ASIN SET"];
const selectedType = ref("ASIN");
const selectedAsin = ref(null);
const selectedAsinSet = ref(null);
const asinSetOptions = computed(() => {
  return Object.keys(dataExplorerStore["ASIN SETS"]);
});
const asinOptions = computed(() => {
  return dataExplorerStore.ASINS;
});
const date = ref(null);
const errorMessages = ref({
  type: null,
  asin: null,
  asinSet: null,
  date: null,
});

const showDialog = () => {
  visible.value = true;
};

const dateToTimestamp = (date) => {
  const dateObj = new Date(date);
  return dateObj.getTime();
};

const runAnalysis = () => {
  // Clear previous errors
  errorMessages.value = {
    type: null,
    asin: null,
    asinSet: null,
    date: null,
  };

  let hasError = false;

  // Validation checks
  if (!selectedType.value) {
    errorMessages.value.type = "Please select a type.";
    hasError = true;
  }

  if (selectedType.value === "ASIN" && !selectedAsin.value) {
    errorMessages.value.asin = "Please select an ASIN.";
    hasError = true;
  }

  if (selectedType.value === "ASIN SET" && !selectedAsinSet.value) {
    errorMessages.value.asinSet = "Please select an ASIN Set.";
    hasError = true;
  }

  if (!date.value) {
    errorMessages.value.date = "Please select a change date.";
    hasError = true;
  }

  if (hasError) {
    return;
  }

  if (selectedType.value === "ASIN SET") {
    const key = selectedAsinSet.value;
    selectedAsinSet.value = dataExplorerStore.RAW_ASIN_SETS.find(
      (set) => set.asinSetName === key
    );
  }
  analysisObject.value = {
    asin:
      selectedType.value === "ASIN"
        ? [selectedAsin.value]
        : selectedAsinSet.value.asins,
    marketplaceid: dashboardStore.currentMarketplace,
    sellerid: dashboardStore.currentSellerId,
    timestamp: dateToTimestamp(date.value),
  };
  showDialog();
};

watch(visible, (newValue) => {
  if (!newValue) {
    analysisObject.value = null;
  }
});
</script>

<style lang="scss" scoped></style>
