<template>
  <div class="p-4 border rounded-lg">
    <h2 class="font-bold text-[20px] underline mb-3">Images</h2>
    <div class="grid grid-cols-5 2xl:grid-cols-10 gap-1">
      <div
        v-for="(slot, index) in imageSlots"
        :key="slot.key"
        class="flex flex-col items-center"
      >
        <label class="font-semibold mb-2">{{ slot.label }}</label>
        <div class="border p-4 rounded-lg relative">
          <template v-if="localImages[slot.key]">
            <img
              :src="localImages[slot.key]"
              alt="Product Image"
              class="h-32 w-32 object-cover mb-2"
            />
            <div
              v-if="editable"
              class="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex gap-2"
            >
              <button @click="deleteImage(slot.key)" class="text-blue-500">
                <i class="pi pi-trash"></i>
              </button>
              <button
                @click="moveLeft(index)"
                class="text-blue-500"
                :disabled="index === 0"
              >
                <i class="pi pi-chevron-left"></i>
              </button>
              <button
                @click="moveRight(index)"
                class="text-blue-500"
                :disabled="index === imageSlots.length - 1"
              >
                <i class="pi pi-chevron-right"></i>
              </button>
            </div>
          </template>
          <template v-else-if="editable">
            <FileUpload
              mode="basic"
              name="image"
              @select="onUpload($event, slot.key)"
              choose-label="Upload"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { useDashboardStore } from "@/stores/DashboardStore";
import { uploadData } from "aws-amplify/storage";

const props = defineProps({
  images: {
    type: Object,
    required: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  sku: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["update:images"]);

const dashboardStore = useDashboardStore();
const localImages = ref({ ...props.images });
const editable = ref(props.editable);
const sku = ref(props.sku);
const loading = ref(false);

const imageSlots = [
  { key: "main_product_image_locator", label: "Main Product Image" },
  { key: "other_product_image_locator_1", label: "Image 1" },
  { key: "other_product_image_locator_2", label: "Image 2" },
  { key: "other_product_image_locator_3", label: "Image 3" },
  { key: "other_product_image_locator_4", label: "Image 4" },
  { key: "other_product_image_locator_5", label: "Image 5" },
  { key: "other_product_image_locator_6", label: "Image 6" },
  { key: "other_product_image_locator_7", label: "Image 7" },
  { key: "other_product_image_locator_8", label: "Image 8" },
  { key: "swatch_product_image_locator", label: "Swatch Image" },
];

const onUpload = async (event, key) => {
  try {
    const file = event.files[0];
    const imgUrl = await uploadImageToS3(file);
    const updatedImages = { ...localImages.value };
    updatedImages[key] = imgUrl;
    localImages.value = updatedImages;
    emit("update:images", updatedImages);
  } catch (error) {
    console.error("Error uploading image:", error);
  }
};

const deleteImage = (key) => {
  const updatedImages = { ...localImages.value };
  updatedImages[key] = null;
  localImages.value = updatedImages;
  emit("update:images", updatedImages);
};

const moveLeft = (index) => {
  if (index > 0) {
    const updatedImages = { ...localImages.value };
    const prevKey = imageSlots[index - 1].key;
    const currKey = imageSlots[index].key;
    [updatedImages[prevKey], updatedImages[currKey]] = [
      updatedImages[currKey],
      updatedImages[prevKey],
    ];
    localImages.value = updatedImages;
    emit("update:images", updatedImages);
  }
};

const moveRight = (index) => {
  if (index < imageSlots.length - 1) {
    const updatedImages = { ...localImages.value };
    const nextKey = imageSlots[index + 1].key;
    const currKey = imageSlots[index].key;
    [updatedImages[nextKey], updatedImages[currKey]] = [
      updatedImages[currKey],
      updatedImages[nextKey],
    ];
    localImages.value = updatedImages;
    emit("update:images", updatedImages);
  }
};

// Store the image in S3 and return the URL using amplify storage
async function uploadImageToS3(file) {
  loading.value = true;

  const sellerid = dashboardStore.currentSellerId;

  try {
    // Upload the image to S3, public/images subfoler is publicly accessible so Amazon can use
    const result = await uploadData({
      key: `images/${sellerid}/${sku.value}/${file.name}_${file.lastModified}.png`,
      data: file,
    }).result;

    const url = `https://listingimages100253-main.s3.eu-west-2.amazonaws.com/public/${result.key}`;
    // const url = `https://listingimages111029-dev.s3.eu-west-2.amazonaws.com/public/${result.key}`;
    return url;
  } catch (error) {
    console.error("Error updating product listing:", error);
  }
}
</script>

<style scoped>
/* Additional styles if necessary */
</style>
