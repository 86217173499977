<template>
  <div class="card flex justify-center m-3">
    <Button
      type="button"
      label="Publish"
      :loading="loading"
      severity="secondary"
      @click="visible = true"
    />
  </div>
  <Dialog
    v-model:visible="visible"
    modal
    header="Update Submission"
    :style="{ width: '25rem' }"
  >
    <span class="p-text-secondary block mb-5">Update your listing.</span>
    <div class="flex flex-col align-items-center gap-3 mb-3">
      <label for="title" class="font-semibold w-6rem">Title *</label>
      <InputText
        v-model="submissiontitle"
        id="title"
        class="flex-auto"
        autocomplete="off"
        :invalid="titleError"
      />
      <InlineMessage v-show="titleError">Title is required</InlineMessage>
    </div>
    <div class="flex flex-col align-items-center gap-3 mb-5">
      <label for="description" class="font-semibold w-6rem"
        >Description *</label
      >
      <InputText
        v-model="submissiondescription"
        id="description"
        class="flex-auto"
        autocomplete="off"
        :invalid="descriptionError"
      />
      <InlineMessage v-show="descriptionError"
        >Description is required</InlineMessage
      >
    </div>
    <div class="flex justify-content-end gap-2">
      <Button
        type="button"
        label="Cancel"
        severity="secondary"
        @click="visible = false"
      ></Button>
      <Button
        type="button"
        :loading="loading"
        label="Publish"
        @click="
          checkAndSubmitPatchListingsItem(
            productType,
            initialListing,
            currentListing,
            sku
          )
        "
      ></Button>
    </div>
  </Dialog>
  <Dialog
    v-model:visible="dialogvisible"
    modal
    :header="alert_header"
    :style="{ width: '25rem' }"
  >
    <span class="p-text-secondary block mb-5">{{ alert_message }}</span>
  </Dialog>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
import {
  PatchOperation,
  ListingsItemPatchRequest,
} from "@/utils/listingsitemssdk.js";
import { computePatches } from "@/utils/json-feed.js";
import { patchListingsItem } from "@/utils/catalog.js";
import { getCurrentUserSessionDetails } from "@/utils/auth.js";
import { getCachedData, setCachedData } from "@/utils/cache.js";
import { useDashboardStore } from "@/stores/DashboardStore";

const dashboardStore = useDashboardStore();

const props = defineProps({
  sku: String,
  vendorCode: String,
  productType: String,
  currentListing: Object,
  initialListing: Object,
  currentIssues: Array,
  asin: String,
});

const sku = ref(props.sku);
const vendorCode = ref(props.vendorCode);
const asin = ref(props.asin);
const productType = ref(props.productType);
const currentListing = ref(props.currentListing);
const initialListing = ref(props.initialListing);
const currentIssues = ref(props.currentIssues);
const loading = ref(false);

// Alert Dialog
const dialogvisible = ref(false);
const alert_message = ref("");
const alert_header = ref("");

const visible = ref(false);
const submissiontitle = ref("");
const titleError = ref(false);
const submissiondescription = ref("");
const descriptionError = ref(false);

watch(
  () => props.sku,
  (newValue) => {
    sku.value = newValue;
  }
);

watch(
  () => props.vendorCode,
  (newValue) => {
    vendorCode.value = newValue;
  }
);

watch(
  () => props.productType,
  (newValue) => {
    productType.value = newValue;
  }
);

watch(
  () => props.currentListing,
  (newValue) => {
    currentListing.value = newValue;
  }
);

watch(
  () => props.initialListing,
  (newValue) => {
    initialListing.value = newValue;
  }
);

async function invokePatchListingItem(
  productType,
  initialListing,
  currentListing,
  sku,
  vendorCode
) {
  const listingsItemPatchRequest = constructListingsItemPatchRequest(
    productType,
    initialListing,
    currentListing
  );

  const revertListingsItemPatchRequest = constructListingsItemPatchRequest(
    productType,
    currentListing,
    initialListing
  );

  // Check if listingsItemPatchRequest is null (no changes detected)
  if (listingsItemPatchRequest === null) {
    return; // End the function execution
  }

  try {
    await submitPatchListingsItem(
      sku,
      vendorCode,
      listingsItemPatchRequest,
      revertListingsItemPatchRequest
    );
  } catch (error) {
    console.error("Error patching listing item:", error);
  }
}

function constructListingsItemPatchRequest(
  productType,
  initialListing,
  currentListing
) {
  const patchOperationObject = computePatches(
    currentListing,
    initialListing
  ).map((data) => PatchOperation.constructFromObject(data, undefined));
  const listingPatchRequestObject = {
    productType: productType,
    patches: patchOperationObject,
  };
  if (patchOperationObject.length === 0) {
    alert_header.value = "Conent Unchanged Error";
    alert_message.value = "No changes detected";
    dialogvisible.value = true;
    return null;
  }

  return ListingsItemPatchRequest.constructFromObject(
    listingPatchRequestObject,
    undefined
  );
}

async function submitPatchListingsItem(
  sku,
  vendorCode,
  listingsItemPatchRequest,
  revertListingsItemPatchRequest
) {
  try {
    loading.value = true;
    // Determine if the selected account is a vendor or seller
    const sellerType =
      dashboardStore.currentDashboardId ===
      "7899d9c6-721a-4657-9719-b8902f4701d0"
        ? "vendor"
        : "seller";

    // Get User Session Details
    const userSessionDetails = await getCurrentUserSessionDetails();

    // Get access token from cache, if unavailable returns null
    const access_token = getCachedData("access_token");

    const params = {
      body: {
        username: userSessionDetails.username,
        email: userSessionDetails.email,
        organisation: userSessionDetails.organisation,
        jwtToken: userSessionDetails.jwtToken,
        payloadSub: userSessionDetails.payloadsub,
        organisationId: userSessionDetails.organisation,
        sku: sku,
        vendorCode: vendorCode,
        asin: asin.value,
        sellerid: dashboardStore.currentSellerId,
        sellerType: sellerType,
        product_type: productType,
        marketplaceid: dashboardStore.currentMarketplace,
        access_token: access_token,
        title: submissiontitle.value,
        description: submissiondescription.value,
        originalListing: JSON.stringify(initialListing.value),
        newListing: JSON.stringify(currentListing.value),
        currentIssues: currentIssues.value,
        body: JSON.stringify({
          changePatches: JSON.stringify(listingsItemPatchRequest), // this is used to update the listing
          toOriginal: JSON.stringify(revertListingsItemPatchRequest), // this is used to revert the listing
        }),
      },
    };

    const res = await patchListingsItem(params);

    if (res.newAccessToken) {
      // Update access token in cache
      setCachedData("access_token", res.accessToken, 60);
    }

    // Raise error when submission rejected
    if (res.status === "INVALID") {
      const errorMessages = [];
      res.issues.forEach((issue) => {
        errorMessages.push(issue.message);
      });
      throw errorMessages;
    }

    loading.value = false;
    alert_header.value = res.status;
    alert_message.value =
      res.sku +
      " update submitted with status: " +
      res.status +
      "\n Submission ID: " +
      res.submissionId;
    dialogvisible.value = true;
  } catch (error) {
    alert_header.value = "Error updating product listing";
    alert_message.value = error;
    dialogvisible.value = true;
    loading.value = false;
  }
}

async function checkAndSubmitPatchListingsItem(
  productType,
  initialListing,
  currentListing,
  sku
) {
  // Check if title and description are filled in
  if (!submissiontitle.value.trim() || !submissiondescription.value.trim()) {
    titleError.value = !submissiontitle.value.trim();
    descriptionError.value = !submissiondescription.value.trim();
    return; // End the function execution
  }
  // If title and description are filled in, proceed with patch request
  await invokePatchListingItem(
    productType,
    initialListing,
    currentListing,
    sku,
    vendorCode.value
  );
  visible.value = false;
  submissiontitle.value = "";
  submissiondescription.value = "";
  titleError.value = false;
  descriptionError.value = false;
  loading.value = false;
}
</script>

<style lang="scss" scoped></style>
