<template>
  <div class="dashboard-controls split-bg">
    <div class="controls-container w-full">
      <div class="dashboard-selector py-1">
        <DashboardSelector />
      </div>
      <div class="currency-selector py-1">
        <Dropdown
          v-model="selectedCurrency"
          :options="dashboardStore.currencies"
          placeholder="GBP"
          class="h-full"
          @change="selectCurrency()"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex items-center">
              <span class="mr-2">{{ currencyCodes[slotProps.value] }}</span>
              <div>{{ slotProps.value }}</div>
            </div>
            <div v-else class="flex items-center">
              <span class="mr-2">{{
                currencyCodes[slotProps.placeholder]
              }}</span>
              <div>{{ slotProps.placeholder }}</div>
            </div>
          </template>
          <template #option="slotProps">
            <div class="flex items-center">
              <span class="mr-2">{{ currencyCodes[slotProps.option] }}</span>
              <div>{{ slotProps.option }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="country-selector py-1">
        <Dropdown
          v-model="selectedMarketplace"
          :options="Object.keys(dashboardStore.marketplaces)"
          placeholder="GB"
          class="h-full"
          @change="selectMarketplace()"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex items-center">
              <span
                :class="`fi fi-${slotProps.value.toLowerCase()} fis mr-2`"
              ></span>
              <div>{{ slotProps.value }}</div>
            </div>
            <span v-else>
              <span
                :class="`fi fi-${slotProps.placeholder.toLowerCase()} fis mr-2`"
              ></span>
              <div>{{ slotProps.placeholder }}</div>
            </span>
          </template>
          <template #option="slotProps">
            <div class="flex items-center">
              <span
                :class="`fi fi-${slotProps.option.toLowerCase()} fis mr-2`"
              ></span>
              <div>{{ slotProps.option }}</div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="date-picker">
        <span class="date-picker">Select Dates</span>
        <DatePicker ref="datePicker" />
      </div>
      <div class="h-full flex items-center mr-2 period-picker">
        <span>Show Comparison</span>
        <Checkbox v-model="showComparison" :binary="true" class="pt-2" />
      </div>
      <div class="comparison-picker">
        <span class="comparison-picker">Comparison Dates</span>
        <ComparisonDatePicker :selected-dates="datePickerDate" />
      </div>
      <div v-show="dataExplorerActive" class="h-full flex items-end pt-3">
        <DataExplorerControls />
      </div>
    </div>
  </div>
</template>

<script setup>
import DashboardSelector from "@/components/dashboards/DashboardSelector.vue";
import DataExplorerControls from "../DataExplorerControls.vue";
import DatePicker from "@/components/DatePicker.vue";
import ComparisonDatePicker from "@/components/ComparisonDatePicker.vue";
import { useDashboardStore } from "@/stores/DashboardStore";
import { ref, computed, defineProps, watch } from "vue";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { currencyCodes } from "@/constants/";

const props = defineProps(["source", "dataExplorerActive"]);
const dashboardStore = useDashboardStore();
const datePicker = ref(null);
const showComparison = ref(false);
const selectedCurrency = ref(null);
const selectedMarketplace = ref("GB");
const ordRevIsChecked = ref(false);
const dataExplorerActive = computed(() => props.dataExplorerActive);

const selectCurrency = () => {
  const dashboard = dashboardStore.embeddedDashboard;
  dashboard.setParameters([
    {
      Name: "Currency",
      Values: [selectedCurrency.value],
    },
  ]);
  dashboardStore.setCurrency(selectedCurrency.value);
};

const selectMarketplace = () => {
  dashboardStore.setMarketplace(selectedMarketplace.value);
};

const toggleShowComparison = () => {
  const dashboard = dashboardStore.embeddedDashboard;
  dashboard.setParameters([
    {
      Name: "showComparison",
      Values: [showComparison.value ? "1" : "0"],
    },
  ]);
};

const datePickerDate = computed(() => {
  return datePicker.value?.dateValue;
});

watch(ordRevIsChecked, (newVal) => {
  const dashboard = dashboardStore.embeddedDashboard;
  dashboard.setParameters([
    {
      Name: "RevenueMetric",
      Values: [newVal ? "Shipped Revenue" : "Ordered Revenue"],
    },
  ]);
});

watch(showComparison, () => {
  toggleShowComparison();
});
</script>

<style lang="scss" scoped>
.dashboard-controls {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  height: 4rem;
  max-height: 100%;
}

.split-bg {
  background-image: linear-gradient(
    130deg,
    var(--across-black) 20%,
    var(--across-purple) 40%
  );
}

.controls-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  z-index: 10;
  padding: 0.5rem;
}

.country-selector {
  margin-right: auto !important;
}

.dashboard-selector,
.currency-selector,
.country-selector,
.date-picker,
.comparison-picker {
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  margin-right: 0.25rem;
}

.period-picker,
.currency-picker {
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  font-size: 0.5rem;
}

.date-picker span,
.comparison-picker span,
.period-picker span {
  width: 100%;
  text-align: left;
  color: var(--across-white);
  font-size: 0.5rem;
}
</style>
