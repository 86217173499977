<template>
  <div class="data-explorer-controls split-bg">
    <Button
      @click="toggleModal"
      class="h-full bg-across-yellow pr-3 pl-3 py-2.5 relative text-sm rounded-lg tracking-wider duration-300 active:text-across-black text-across-black truncate"
    >
      Start Exploring
    </Button>
  </div>
  <FilterModal :modalActive="modalActive" @close-modal="toggleModal">
    <div class="flex w-full">
      <div class="filters mb-4 mr-4 max-w-sm">
        <span class="filters-control w-full text-sm">Filters (optional)</span>
        <Dropdown
          v-model="selectedFilter"
          :options="filters"
          placeholder="Select Filter"
          class="w-full max-w-sm"
        />
        <MultiSelect
          v-if="
            selectedFilter &&
            selectedFilter !== 'NONE' &&
            selectedFilter !== 'ASIN SETS'
          "
          v-model="selectedFilterValues"
          :options="dataExplorerStore[selectedFilter]"
          optionLabel="value"
          optionValue="key"
          filter
          placeholder="Select Filters"
          display="chip"
          class="flex flex-wrap w-full max-w-sm truncate"
        />
        <Dropdown
          v-if="selectedFilter === 'ASIN SETS'"
          v-model="selectedFilterValues"
          :options="asinSetOptions"
          optionLabel="label"
          optionValue="value"
          placeholder="Select ASIN Set"
          class="w-full max-w-sm"
        />
      </div>
      <div class="data-groups mb-4 mr-4 max-w-sm">
        <span class="data-groups-control w-full text-sm">Data Groups</span>
        <Dropdown
          v-model="selectedDataGroup"
          :options="dataGroups"
          placeholder="Select Data Group"
          class="w-full mb-2"
        />
        <InlineMessage v-if="!selectedDataGroup && formSubmitted"
          >Please select a data group</InlineMessage
        >
        <div class="flex flex-col">
          <div class="seebydate-container">
            <Checkbox
              v-model="seeByDate"
              :binary="true"
              class=""
              :disabled="showComparison"
              @click="selectedMetrics = []"
              v-tooltip.left="'Please ensure Comparison Data is unchecked'"
            />
            <span class="date-data-groups-control text-sm">See by date</span>
          </div>
          <div class="comparisoncheck-container">
            <Checkbox
              v-model="showComparison"
              :binary="true"
              class=""
              :disabled="seeByDate"
              @click="selectedMetrics = []"
              v-tooltip.left="'Please ensure See by Date is unchecked'"
            />
            <span class="date-data-groups-control text-sm"
              >Show comparison data</span
            >
          </div>
        </div>
      </div>
      <div class="metrics mb-4 mr-4 flex flex-col max-w-sm">
        <span class="metrics-control w-full text-sm">Metrics</span>
        <div class="w-full" v-if="seeByDate">
          <Dropdown
            v-model="selectedMetrics"
            :options="filteredSeeDateMetrics"
            placeholder="Select Metric"
            class="w-full"
          />
          <InlineMessage v-if="selectedMetrics.length === 0 && formSubmitted"
            >Please select a metric</InlineMessage
          >
        </div>
        <div class="w-full" v-else>
          <MultiSelect
            v-model="selectedMetrics"
            :options="filteredMetrics"
            filter
            placeholder="Select Metrics"
            display="chip"
            class="flex flex-wrap w-full"
          />
          <InlineMessage v-if="selectedMetrics.length === 0 && formSubmitted"
            >Please select at least one metric</InlineMessage
          >
          <div
            class="selected-metrics mb-4 flex flex-wrap w-full overflow-y-auto max-h-28"
          >
            <div
              v-for="metric in selectedMetrics"
              :key="metric"
              class="bg-across-white text-across-black flex justify-center items-center pr-3 pl-2 py-2.5 relative text-sm rounded-lg tracking-wider duration-300 active:text-white truncate m-1 cursor-pointer"
              @click="selectMetric(metric)"
            >
              {{ metric }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="seeByDate" class="date-granularity mb-4 flex flex-col">
        <span class="w-full text-sm">Date Granularity</span>
        <Dropdown
          v-model="selectedDateGranularity"
          :options="dateGranularity"
          placeholder="Select Date Granularity"
          class="w-full"
        />
      </div>
    </div>
    <div class="apply-button mb-4 w-full">
      <InlineMessage
        v-if="
          !dashboardStore.startDate && !dashboardStore.endDate && formSubmitted
        "
        >Please select a date range</InlineMessage
      >
      <InlineMessage
        v-if="
          !dashboardStore.comparisonStartDate &&
          !dashboardStore.comparisonEndDate &&
          showComparison &&
          formSubmitted
        "
        >Please select a comparison date range</InlineMessage
      >
      <InlineMessage v-if="!dashboardStore.currentSellerId && formSubmitted"
        >Please select a seller</InlineMessage
      >
      <button
        @click="validateAndStartQuery(), toggleModal"
        class="bg-across-purple text-across-white flex justify-center pr-3 pl-3 mb-2 py-2.5 relative w-full text-sm rounded-lg tracking-wider duration-300 active:text-white truncate"
      >
        <span v-if="loading">
          <ProgressSpinner
            v-if="loading"
            style="width: 50px; height: 50px"
            strokeWidth="8"
            class="fill-surface-0 dark:fill-surface-800"
            animationDuration=".5s"
            aria-label="Custom ProgressSpinner"
          />
        </span>
        <span v-else>Start Exploring</span>
      </button>
      <button
        @click="clearQuery"
        class="bg-across-yellow text-across-white flex justify-center pr-3 pl-3 py-2.5 relative w-full text-sm rounded-lg tracking-wider duration-300 active:text-white truncate"
      >
        Clear Query
      </button>
    </div>
  </FilterModal>
</template>

<script setup>
// import DashboardSelector from "@/components/dashboards/DashboardSelector.vue";
import FilterModal from "@/components/dashboards/FilterModal";
import { getExplorerData, getFilters } from "@/utils/axExplorerApi";
import { getCurrentUserSessionDetails } from "@/utils/auth";
import { useDataExplorerStore } from "@/stores/DataExplorerStore";
import { useDashboardStore } from "@/stores/DashboardStore";
import { ref, computed, watch } from "vue";
import { format } from "date-fns";

const dataExplorerStore = useDataExplorerStore();
const dashboardStore = useDashboardStore();

const dataGroups = computed(() => {
  if (
    dashboardStore.currentDashboardId == "7899d9c6-721a-4657-9719-b8902f4701d0"
  ) {
    return Object.keys(dataExplorerStore.datagroup_data.vendors);
  } else {
    return Object.keys(dataExplorerStore.datagroup_data.sellers);
  }
});
const modalActive = ref(null);
const sellerType = ref("sellers");
const selectedDataGroup = ref(null);
const selectedDateGranularity = ref(null);
const seeByDate = ref(false);
const showComparison = ref(false);
const selectedMetrics = ref([]);
const selectedFilter = ref(null);
const selectedFilterValues = ref([]);
const selectedCompositeMetrics = ref([]);
const loading = ref(false);
// const filtersLoading = ref(false);
const formSubmitted = ref(false);

const dateGranularity = ["Day"];
// const currencies = ["GBP", "USD", "EUR", "AUD", "MXN"];
const filters = [
  "NONE",
  "ASINS",
  "ASIN SETS",
  "BRANDS",
  "CATEGORIES",
  "SUBCATEGORIES",
  "SKUS",
];

// Compute the options for the ASIN sets
const asinSetOptions = computed(() => {
  const asinSets = dataExplorerStore["ASIN SETS"];
  const ret = Object.keys(asinSets).map((key) => ({
    label: key,
    value: asinSets[key],
  }));
  return ret;
});

const toggleModal = () => {
  modalActive.value = !modalActive.value;
};

const filteredMetrics = computed(() => {
  if (!selectedDataGroup.value) return [];
  const metrics =
    dataExplorerStore.datagroup_data[sellerType.value][selectedDataGroup.value]
      .metrics;
  return Object.keys(metrics);
});

// Metrics for when see date is selected (filters out composites)
const filteredSeeDateMetrics = computed(() => {
  if (!selectedDataGroup.value) return [];
  const metrics =
    dataExplorerStore.datagroup_data[sellerType.value][selectedDataGroup.value]
      .metrics;
  return Object.keys(metrics).filter((key) => !metrics[key].composite);
});

const selectMetric = (metric) => {
  if (!selectedMetrics.value.includes(metric)) {
    selectedMetrics.value.push(metric);
  } else {
    selectedMetrics.value = selectedMetrics.value.filter(
      (selectedMetric) => selectedMetric !== metric
    );
  }
};

// Moves composite metrics from selectedMetrics to selectedCompositeMetrics
const selectCompostiteMetrics = (metrics, compositeList) => {
  try {
    for (let i = 0; i < metrics.length; i++) {
      if (
        dataExplorerStore.datagroup_data[sellerType.value][
          selectedDataGroup.value
        ].metrics[metrics[i]].composite
      ) {
        compositeList.push(metrics[i]);
        selectedMetrics.value = selectedMetrics.value.filter(
          (selectedMetric) => selectedMetric !== metrics[i]
        );
      }
    }
  } catch (error) {
    console.error("Error selecting composite metrics:", error);
  }
};

const updateFilterValues = (results) => {
  const asins = [];
  const brands = [];
  const categories = [];
  const subcategories = [];
  const skus = [];

  results.forEach((result) => {
    // Create objects with key-value pairs for ASINs
    const asinObj = {
      key: result.asin,
      value: `${result.asin} - ${result.name}`,
    };
    const brandObj = {
      key: result.brand,
      value: result.brand,
    };
    const categoryObj = {
      key: result.category,
      value: result.category,
    };
    const subcategoryObj = {
      key: result.subcategory,
      value: result.subcategory,
    };
    const skuObj = {
      key: result.sku,
      value: result.sku,
    };

    if (!asins.some((item) => item.key === asinObj.key)) {
      asins.push(asinObj);
    }
    if (!brands.some((item) => item.key === brandObj.key)) {
      brands.push(brandObj);
    }
    if (!categories.some((item) => item.key === categoryObj.key)) {
      categories.push(categoryObj);
    }
    if (!subcategories.some((item) => item.key === subcategoryObj.key)) {
      subcategories.push(subcategoryObj);
    }
    if (!skus.some((item) => item.key === skuObj.key)) {
      skus.push(skuObj);
    }

    dataExplorerStore.setAsins(asins);
    dataExplorerStore.setBrands(brands);
    dataExplorerStore.setCategories(categories);
    dataExplorerStore.setSubcategories(subcategories);
    dataExplorerStore.setSkus(skus);
  });
};

const validateAndStartQuery = () => {
  if (showComparison.value) {
    if (
      dashboardStore.currentSellerId &&
      dashboardStore.startDate &&
      dashboardStore.endDate &&
      selectedDataGroup.value &&
      selectedMetrics.value.length > 0 &&
      dashboardStore.comparisonStartDate &&
      dashboardStore.comparisonEndDate
    ) {
      startQuery();
    } else {
      formSubmitted.value = true;
      console.error("Error: Required fields not selected");
    }
  } else {
    if (
      dashboardStore.currentSellerId &&
      dashboardStore.startDate &&
      dashboardStore.endDate &&
      selectedDataGroup.value &&
      selectedMetrics.value.length > 0
    ) {
      startQuery();
    } else {
      formSubmitted.value = true;
      console.error("Error: Required fields not selected");
    }
  }
};

const clearQuery = () => {
  selectedMetrics.value = [];
  selectedCompositeMetrics.value = [];
  selectedFilter.value = null;
  selectedFilterValues.value = [];
  selectedDataGroup.value = null;
  selectedDateGranularity.value = null;
  seeByDate.value = false;
  showComparison.value = false;
};

const startQuery = async () => {
  try {
    loading.value = true; // Show loading spinner
    // ADD CHECK FOR INPUTS ALL REQUIRED
    const startdate = format(new Date(dashboardStore.startDate), "yyyy-MM-dd");
    const enddate = format(new Date(dashboardStore.endDate), "yyyy-MM-dd");

    const comparison_startdate = format(
      new Date(dashboardStore.comparisonStartDate),
      "yyyy-MM-dd"
    );

    const comparison_enddate = format(
      new Date(dashboardStore.comparisonEndDate),
      "yyyy-MM-dd"
    );

    selectCompostiteMetrics(
      selectedMetrics.value,
      selectedCompositeMetrics.value
    );

    // Get User Session Details
    const userSessionDetails = await getCurrentUserSessionDetails();
    // setting the filter type to asin
    if (selectedFilter.value === "ASIN SETS") {
      selectedFilter.value = "ASINS";
    }
    const params = {
      queryParams: {
        username: userSessionDetails.username,
        email: userSessionDetails.email,
        jwtToken: userSessionDetails.jwtToken,
        payloadSub: userSessionDetails.payloadsub,
        metrics: selectedMetrics.value,
        composite_metrics: selectedCompositeMetrics.value,
        filters: selectedFilter.value,
        filter_values: selectedFilterValues.value,
        datagroup: [selectedDataGroup.value],
        sellertype: sellerType.value,
        sellerid: dashboardStore.currentSellerId,
        startdate: startdate,
        enddate: enddate,
        comparison_startdate: comparison_startdate,
        comparison_enddate: comparison_enddate,
        see_by_date: seeByDate.value,
        show_comparison: showComparison.value,
        date_granularity: selectedDateGranularity.value,
        currency: dashboardStore.currency,
      },
    };

    const result = await getExplorerData(params);
    dataExplorerStore.setExplorerData(result);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    loading.value = false; // Hide loading spinner
    toggleModal();
  }
};

watch(
  () => dashboardStore.currentSellerId,
  async (newVal) => {
    try {
      // Call getFilters function when currentSellerId changes
      await getFilterVars(newVal);
      const rawAsinSets = await dataExplorerStore.RAW_ASIN_SETS;
      // Set ASIN Sets for Seller ID
      dataExplorerStore.setAsinSetsForSellerid(rawAsinSets, newVal);
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  }
);

watch(
  () => dashboardStore.currentDashboardId,
  (newVal) => {
    try {
      if (newVal == "7899d9c6-721a-4657-9719-b8902f4701d0") {
        sellerType.value = "vendors";
      } else {
        sellerType.value = "sellers";
      }
    } catch (error) {
      console.error("Error fetching filters:", error);
    }
  }
);

const getFilterVars = async (sellerId) => {
  try {
    // filtersLoading.value = true;
    // Get User Session Details
    const userSessionDetails = await getCurrentUserSessionDetails();
    const params = {
      queryParams: {
        username: userSessionDetails.username,
        email: userSessionDetails.email,
        jwtToken: userSessionDetails.jwtToken,
        payloadSub: userSessionDetails.payloadsub,
        sellerid: sellerId,
      },
    };
    const result = await getFilters(params);
    updateFilterValues(result);
  } catch (error) {
    console.error("Error fetching filters:", error);
  } finally {
    // filtersLoading.value = false;
  }
};
</script>

<style lang="scss" scoped></style>
