<script setup>
import { ref, watch, defineProps } from "vue";
import { format, sub, differenceInDays, subYears } from "date-fns";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import { useDashboardStore } from "@/stores/DashboardStore";

const comparison = ref("LP");
let previousDates = [];
const dashboardStore = useDashboardStore();
const props = defineProps({
  selectedDates: {
    type: Object,
    default: () => ({ startDate: new Date(), endDate: new Date() }),
  },
});
const dateValue = ref({
  startDate: "",
  endDate: "",
});
const formatter = ref({
  date: "YYYY-MM-DD",
  month: "MMM",
});
const initialPlaceholder = ref("");

// Disable dates in the future
function disableFutureDates(date) {
  return date > new Date();
}

// date shortcuts available in the datepicker
const customShortcuts = () => {
  return [
    {
      label: "LAST PERIOD",
      atClick: () => {
        comparison.value = "LP";
        if (
          !props.selectedDates ||
          !props.selectedDates.startDate ||
          !props.selectedDates.endDate
        ) {
          console.error("Selected dates are not properly initialized.");
          return []; // Return an empty array or some default value
        }
        const [startDate, endDate] = previousPeriodCalculator(
          props.selectedDates.startDate,
          props.selectedDates.endDate
        );
        updateDashboardDateParams(startDate, endDate);
        updatePlaceholder(startDate, endDate);
        return [startDate, endDate];
      },
    },
    {
      label: "LAST YEAR",
      atClick: () => {
        comparison.value = "LY";
        if (
          !props.selectedDates ||
          !props.selectedDates.startDate ||
          !props.selectedDates.endDate
        ) {
          console.error("Selected dates are not properly initialized.");
          return [];
        }
        const [startDate, endDate] = previousYearCalculator(
          props.selectedDates.startDate,
          props.selectedDates.endDate
        );
        updateDashboardDateParams(startDate, endDate);
        updatePlaceholder(startDate, endDate);
        return [startDate, endDate];
      },
    },
  ];
};

const previousYearCalculator = (startDate, endDate) => {
  try {
    const formattedStartDate = format(new Date(startDate), "yyyy-MM-dd");
    const formattedEndDate = format(new Date(endDate), "yyyy-MM-dd");
    const previousStartDate = subYears(new Date(formattedStartDate), 1);
    const previousEndDate = subYears(new Date(formattedEndDate), 1);
    return [previousStartDate, previousEndDate];
  } catch (error) {
    console.error("Error calculating previous year: ", error);
  }
};

const previousPeriodCalculator = (startDate, endDate) => {
  try {
    const daysInPeriod = differenceInDays(
      new Date(endDate),
      new Date(startDate)
    );
    const previousStartDate = sub(new Date(startDate), { days: daysInPeriod });
    const previousEndDate = sub(new Date(startDate), { days: 1 });
    return [previousStartDate, previousEndDate];
  } catch (error) {
    console.error("Error calculating previous period: ", error);
  }
};

const updateDashboardDateParams = (comparisonStartDate, comparisonEndDate) => {
  const dashboard = dashboardStore.embeddedDashboard;
  dashboard.setParameters([
    {
      Name: "ComparisonPeriodStart",
      Values: comparisonStartDate,
    },
  ]);
  dashboard.setParameters([
    {
      Name: "ComparisonPeriodEnd",
      Values: comparisonEndDate,
    },
  ]);
};

const updatePlaceholder = (comparisonStartDate, comparisonEndDate) => {
  try {
    initialPlaceholder.value = `${format(
      new Date(comparisonStartDate),
      "yyyy-MM-dd"
    )} - ${format(new Date(comparisonEndDate), "yyyy-MM-dd")}`;
  } catch (error) {
    console.error(error);
  }
};

// When DatePicker dateValue changes, calculate date, update dash params and placeholder
watch(
  () => props.selectedDates,
  // eslint-disable-next-line no-unused-vars
  (newDates, oldDates) => {
    if (newDates.startDate === "" && newDates.endDate === "") {
      return;
    }
    if (comparison.value === "Custom") {
      return;
    } else if (comparison.value === "LP") {
      previousDates = previousPeriodCalculator(
        newDates.startDate,
        newDates.endDate
      );
    } else if (comparison.value === "LY") {
      previousDates = previousYearCalculator(
        newDates.startDate,
        newDates.endDate
      );
    }
    updateDashboardDateParams(previousDates[0], previousDates[1]);
    updatePlaceholder(previousDates[0], previousDates[1]);
  }
);

// When dates are directly changed,update the dashboard params
// eslint-disable-next-line no-unused-vars
watch(dateValue, (newValue, oldValue) => {
  updateDashboardDateParams(newValue.startDate, newValue.endDate);
});

// When dates changed in dashboard store, update the placeholder (happens on initial get params in DashMain)
watch(
  () => [dashboardStore.comparisonStartDate, dashboardStore.comparisonEndDate],
  ([comparisonStartDate, comparisonEndDate]) => {
    if (comparisonStartDate === "" && comparisonEndDate === "") {
      return;
    }
    updatePlaceholder(comparisonStartDate, comparisonEndDate);
  }
);
</script>

<template>
  <vue-tailwind-datepicker
    v-model="dateValue"
    :disable-date="disableFutureDates"
    :shortcuts="customShortcuts"
    :formatter="formatter"
    :placeholder="initialPlaceholder"
    as-single
    use-range
    :weekNumber="true"
    class="h-8"
  />
</template>
