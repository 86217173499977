import { get } from "aws-amplify/api";
import { useDashboardStore } from "@/stores/DashboardStore";

async function getDashboardList(params) {
  try {
    const dashboardStore = useDashboardStore();
    const dashboards = get({
      apiName: "quicksightembed",
      path: "/getQuickSightDashboardList",
      options: params,
    });
    const { body } = await dashboards.response;
    const json = await body.json();
    dashboardStore.setDashboardList(json.data.DashboardSummaryList);
    return json.data.DashboardSummaryList;
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

async function getDashboardEmbedUrl(params) {
  try {
    const dashboards = get({
      apiName: "quicksightembed",
      path: "/getQuickSightDashboardEmbedURL",
      options: params,
    });
    const { body } = await dashboards.response;
    const json = await body.json();
    return json.data.EmbedUrl;
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

async function listQuicksightUserGroups(params) {
  try {
    const dashboardStore = useDashboardStore();
    const groups = get({
      apiName: "quicksightembed",
      path: "/listQuicksightUserGroups",
      options: params,
    });
    const { body } = await groups.response;
    const json = await body.json();
    dashboardStore.setUserGroups(json.data.GroupList);
    return json.data.GroupList;
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

async function addUserToQuicksightGroup(params) {
  try {
    const res = get({
      apiName: "quicksightembed",
      path: "/addUserToQuicksightGroup",
      options: params,
    });
    const { body } = await res.response;
    const json = await body.json();
    return json.data;
  } catch (error) {
    console.log("GET call failed: ", error);
    throw error;
  }
}

async function listQuicksightGroupMembers(params) {
  try {
    const dashboardStore = useDashboardStore();
    const members = get({
      apiName: "quicksightembed",
      path: "/listQuicksightGroupMembers",
      options: params,
    });
    const { body } = await members.response;
    const json = await body.json();
    dashboardStore.setGroupMembers(
      params.queryParams.groupName,
      json.data.GroupMemberList
    );
    return json.data.GroupMemberList;
  } catch (error) {
    console.log("GET call failed: ", error);
  }
}

async function deleteUserFromQuicksightGroup(params) {
  try {
    const res = get({
      apiName: "quicksightembed",
      path: "/deleteUserFromQuicksightGroup",
      options: params,
    });
    const { body } = await res.response;
    const json = await body.json();
    return json.data;
  } catch (error) {
    console.log("GET call failed: ", error);
    throw error;
  }
}

export {
  getDashboardList,
  getDashboardEmbedUrl,
  listQuicksightUserGroups,
  addUserToQuicksightGroup,
  listQuicksightGroupMembers,
  deleteUserFromQuicksightGroup,
};
