// Code in this file is adapted from the Amazon Selling Partner API Node.js SDK
// https://github.com/amzn/selling-partner-api-samples/blob/main/use-cases/listings/app/sdk/listingsItems_2021-08-01/

/**
 * Parses an ISO-8601 string representation of a date value.
 * @param {String} str The date value as a string.
 * @returns {Date} The parsed date object.
 */
export function parseDate(str) {
  return new Date(str);
}

export function convertToType(data, type) {
  if (data === null || data === undefined) return data;

  switch (type) {
    case "Boolean":
      return Boolean(data);
    case "Integer":
      return parseInt(data, 10);
    case "Number":
      return parseFloat(data);
    case "String":
      return String(data);
    case "Date":
      return parseDate(String(data));
    case "Blob":
      return data;
    default:
      if (type === Object) {
        // generic object, return directly
        return data;
      } else if (typeof type === "function") {
        // for model type like: User
        return type.constructFromObject(data);
      } else if (Array.isArray(type)) {
        // for array type like: ['String']
        var itemType = type[0];

        return data.map((item) => {
          return convertToType(item, itemType);
        });
      } else if (typeof type === "object") {
        // for plain object type like: {'String': 'Integer'}
        var keyType, valueType;
        for (var k in type) {
          // eslint-disable-next-line no-prototype-builtins
          if (type.hasOwnProperty(k)) {
            keyType = k;
            valueType = type[k];
            break;
          }
        }

        var result = {};
        // eslint-disable-next-line no-redeclare
        for (var k in data) {
          // eslint-disable-next-line no-prototype-builtins
          if (data.hasOwnProperty(k)) {
            var key = convertToType(k, keyType);
            var value = convertToType(data[k], valueType);
            result[key] = value;
          }
        }

        return result;
      } else {
        // for unknown type, return the data directly
        return data;
      }
  }
}

/**
 * The PatchOperation model module.
 * @module listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/PatchOperation
 * @version 2021-08-01
 */
export class PatchOperation {
  /**
   * Constructs a new <code>PatchOperation</code>.
   * Individual JSON Patch operation for an HTTP PATCH request.
   * @alias module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/PatchOperation
   * @class
   * @param op {module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/PatchOperation.OpEnum} Type of JSON Patch operation. Supported JSON Patch operations include add, replace, and delete. See <https://tools.ietf.org/html/rfc6902>.
   * @param path {String} JSON Pointer path of the element to patch. See <https://tools.ietf.org/html/rfc6902>.
   */
  constructor(op, path) {
    this.op = op;
    this.path = path;
  }

  /**
   * Constructs a <code>PatchOperation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/PatchOperation} obj Optional instance to populate.
   * @return {module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/PatchOperation} The populated <code>PatchOperation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PatchOperation();
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty("op"))
        obj.op = convertToType(data["op"], "String");
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty("path"))
        obj.path = convertToType(data["path"], "String");
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty("value"))
        obj.value = convertToType(data["value"], [Object]);
    }
    return obj;
  }
}

/**
 * Allowed values for the <code>op</code> property.
 * @enum {String}
 * @readonly
 */
PatchOperation.OpEnum = {
  /**
   * value: "add"
   * @const
   */
  add: "add",

  /**
   * value: "replace"
   * @const
   */
  replace: "replace",

  /**
   * value: "delete"
   * @const
   */
  _delete: "delete",
};

/**
 * Type of JSON Patch operation. Supported JSON Patch operations include add, replace, and delete. See <https://tools.ietf.org/html/rfc6902>.
 * @member {module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/PatchOperation.OpEnum} op
 */
PatchOperation.prototype.op = undefined;

/**
 * JSON Pointer path of the element to patch. See <https://tools.ietf.org/html/rfc6902>.
 * @member {String} path
 */
PatchOperation.prototype.path = undefined;

/**
 * JSON value to add, replace, or delete.
 * @member {Array.<Object>} value
 */
PatchOperation.prototype.value = undefined;

/**
 * The ListingsItemPatchRequest model module.
 * @module listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/ListingsItemPatchRequest
 * @version 2021-08-01
 */
export class ListingsItemPatchRequest {
  /**
   * Constructs a new <code>ListingsItemPatchRequest</code>.
   * The request body schema for the patchListingsItem operation.
   * @alias module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/ListingsItemPatchRequest
   * @class
   * @param productType {String} The Amazon product type of the listings item.
   * @param patches {Array.<module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/PatchOperation>} One or more JSON Patch operations to perform on the listings item.
   */
  constructor(productType, patches) {
    this.productType = productType;
    this.patches = patches;
  }

  /**
   * Constructs a <code>ListingsItemPatchRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/ListingsItemPatchRequest} obj Optional instance to populate.
   * @return {module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/ListingsItemPatchRequest} The populated <code>ListingsItemPatchRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ListingsItemPatchRequest();
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty("productType"))
        obj.productType = convertToType(data["productType"], "String");
      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty("patches"))
        obj.patches = convertToType(data["patches"], [PatchOperation]);
    }
    return obj;
  }
}

/**
 * The Amazon product type of the listings item.
 * @member {String} productType
 */
ListingsItemPatchRequest.prototype.productType = undefined;

/**
 * One or more JSON Patch operations to perform on the listings item.
 * @member {Array.<module:listingsItems_2021-08-01/js-client.listingsItems_2021-08-01.model/PatchOperation>} patches
 */
ListingsItemPatchRequest.prototype.patches = undefined;
