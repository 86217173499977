<template>
  <!-- <DataExplorerControls :source="'data-explorer'" /> -->
  <div class="data-output">
    <div class="data-output-header"><Toast /></div>
    <div v-if="!dataExplorerData" class="border-t border-b px-4 py-3">
      <p class="font-bold">Welcome!</p>
      <p class="text-sm">Start exploring to see the data.</p>
    </div>
    <div v-else class="data-output-body p-6">
      <DataTable
        :value="dataExplorerData"
        ref="dt"
        tableStyle="min-width: 50rem"
        paginator
        :rows="20"
        :rowsPerPageOptions="[25, 50, 100]"
      >
        <template #header>
          <div style="text-align: left; display: flex">
            <Button
              icon="pi pi-external-link"
              label="Export"
              @click="exportCSV($event)"
              class="mr-2"
            />
            <span>Data Columns</span>
            <MultiSelect
              :modelValue="selectedColumns"
              :options="columns"
              optionLabel="header"
              @update:modelValue="onToggle"
              display="chip"
              placeholder="Select Columns"
              class="w-full"
            />
          </div>
        </template>
        <Column
          v-for="col in selectedColumns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          sortable
          class="uppercase"
        ></Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup>
// import DataExplorerControls from "./DataExplorerControls.vue";
import { useDataExplorerStore } from "@/stores/DataExplorerStore";
import { useToast } from "primevue/usetoast";
import { defineProps, ref, watchEffect } from "vue";

defineProps({
  dataExplorerActive: {
    type: Boolean,
    default: false,
  },
});

const dataExplorerStore = useDataExplorerStore();
const toast = useToast();
const dataExplorerData = ref();
const columns = ref([]);
const dt = ref();
const selectedColumns = ref(columns.value);

const exportCSV = () => {
  dt.value.exportCSV();
};

const onToggle = (val) => {
  selectedColumns.value = columns.value.filter((col) => val.includes(col));
};

const dxSuccessMessage = (n) => {
  toast.add({
    severity: "success",
    summary: "Success",
    detail: `Successfully retrieved ${n} rows`,
    life: 3000,
  });
};

const dxErrorMessage = () => {
  toast.add({
    severity: "error",
    summary: "Error",
    detail: "No rows found. Please try again.",
    life: 3000,
  });
};

watchEffect(() => {
  // Update dataExplorerData when data in the store changes
  dataExplorerData.value = dataExplorerStore.data;
  if (dataExplorerData.value) {
    columns.value = Object.keys(dataExplorerData.value[0]).map((col) => {
      return {
        field: col,
        header: col,
      };
    });
    selectedColumns.value = columns.value;
    dxSuccessMessage(dataExplorerData.value.length);
  } else {
    dxErrorMessage();
  }
});
</script>

<style lang="scss" scoped></style>
