<template>
  <Dropdown
    v-model="dashboardStore.currentAccountName"
    :options="accountList"
    :optionLabel="(group) => group.FormattedGroupName + ' - ' + group.Type"
    filter
    :placeholder="dropdownPlaceholder"
    :loading="accountList.length === 0"
    @change="selectAccount($event.value), accountNavigation($event.value)"
    class="h-full"
  />
</template>

<script setup>
import { useDashboardStore } from "@/stores/DashboardStore";
import { useDataExplorerStore } from "@/stores/DataExplorerStore";
import { ref, computed } from "vue";

const dashboardStore = useDashboardStore();
const dataExplorerStore = useDataExplorerStore();
const accountList = ref(dashboardStore.userGroups);

const dropdownPlaceholder = computed(() => {
  if (accountList.value.length === 0) {
    return "Loading accounts...";
  } else if (!dashboardStore.currentAccountName) {
    return "Select an account";
  } else {
    return dashboardStore.currentAccountName;
  }
});

const selectAccount = (group) => {
  const accountName = group.FormattedGroupName + " - " + group.Type;
  dashboardStore.setCurrentAccountName(accountName);
};

const accountNavigation = (account) => {
  try {
    const dashboard = dashboardStore.embeddedDashboard;
    if (
      dashboardStore.currentDashboardId ===
      dashboardStore.dashboardIds[account.Type]
    ) {
      dashboard.setParameters([
        {
          Name: "sellerid",
          Values: [account.Sellerid],
        },
      ]);
      dashboardStore.setCurrentSellerId(account.Sellerid);
      dataExplorerStore.setAsinSetsForSellerid(
        dataExplorerStore.RAW_ASIN_SETS,
        dashboardStore.currentSellerId
      );
    } else {
      const dashboardId = dashboardStore.dashboardIds[account.Type];
      dashboard
        .navigateToDashboard(dashboardId)
        .then(() => {
          // Set parameters after navigation is finished
          const dashboard = dashboardStore.embeddedDashboard;
          dashboard.setParameters([
            {
              Name: "sellerid",
              Values: [account.Sellerid],
            },
          ]);
          // Update current dashboard and seller ID
          dashboardStore.setCurrentDashboardId(dashboardId);
          dashboardStore.setCurrentSellerId(account.Sellerid);
          dataExplorerStore.setAsinSetsForSellerid(
            dataExplorerStore.RAW_ASIN_SETS,
            dashboardStore.currentSellerId
          );
        })
        .catch((error) => {
          console.error("Error navigating to dashboard:", error);
        });
    }
  } catch (error) {
    console.error("Error navigating to dashboard:", error);
  }
};
</script>
