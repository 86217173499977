<script setup>
import { ref, watch, defineExpose } from "vue";
import { format } from "date-fns";
import VueTailwindDatepicker from "vue-tailwind-datepicker";
import { useDashboardStore } from "@/stores/DashboardStore";

const dashboardStore = useDashboardStore();

const dateValue = ref({
  startDate: "",
  endDate: "",
});
const formatter = ref({
  date: "YYYY-MM-DD",
  month: "MMM",
});
const initialPlaceholder = ref("");

// Disable dates in the future
function disableFutureDates(date) {
  return date > new Date();
}

// date shortcuts available in the datepicker
const customShortcuts = () => {
  return [
    {
      label: "YESTERDAY",
      atClick: () => {
        const date = new Date();
        const yesterday = date.setDate(date.getDate() - 1);
        return [yesterday, yesterday];
      },
    },
    {
      label: "LAST WEEK (SUN - SAT)",
      atClick: () => {
        const date = new Date();
        const currentDay = date.getDay(); // 0 = Sunday, ..., 6 = Saturday
        const lastSunday = new Date(date);
        lastSunday.setDate(date.getDate() - currentDay - 7);
        const lastSaturday = new Date(lastSunday);
        lastSaturday.setDate(lastSunday.getDate() + 6);
        return [lastSunday, lastSaturday];
      },
    },
    {
      label: "LAST MONTH",
      atClick: () => {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
        return [firstDay, lastDay];
      },
    },
    {
      label: "LAST 30 DAYS",
      atClick: () => {
        const date = new Date();
        return [date.setDate(date.getDate() - 30), new Date()];
      },
    },
    {
      label: "LAST QUARTER",
      atClick: () => {
        const date = new Date();
        const quarter = Math.floor(date.getMonth() / 3) - 1;
        const firstDay = new Date(date.getFullYear(), quarter * 3, 1);
        const lastDay = new Date(date.getFullYear(), quarter * 3 + 3, 0);
        return [firstDay, lastDay];
      },
    },
    {
      label: "LAST YEAR",
      atClick: () => {
        const date = new Date();
        const firstDay = new Date(date.getFullYear() - 1, 0, 1);
        const lastDay = new Date(date.getFullYear() - 1, 11, 31);
        return [firstDay, lastDay];
      },
    },
    {
      label: "WEEK TO DATE",
      atClick: () => {
        const date = new Date();
        const currentDay = date.getDay(); // 0 = Sunday, ..., 6 = Saturday
        const sunday = new Date(date);
        sunday.setDate(date.getDate() - currentDay);
        return [sunday, date];
      },
    },
    {
      label: "MONTH TO DATE",
      atClick: () => {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return [firstDay, date];
      },
    },
    {
      label: "QUARTER TO DATE",
      atClick: () => {
        const date = new Date();
        const quarter = Math.floor(date.getMonth() / 3);
        const firstDay = new Date(date.getFullYear(), quarter * 3, 1);
        return [firstDay, date];
      },
    },
    {
      label: "YEAR TO DATE",
      atClick: () => {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), 0, 1);
        return [firstDay, date];
      },
    },
  ];
};

defineExpose({
  dateValue,
});

// Watch for changes in dateValue
// eslint-disable-next-line no-unused-vars
watch(dateValue, (newValue, oldValue) => {
  const dashboard = dashboardStore.embeddedDashboard;
  dashboard.setParameters([
    {
      Name: "StartDate",
      Values: dateValue.value.startDate,
    },
    {
      Name: "EndDate",
      Values: dateValue.value.endDate,
    },
  ]);
  dashboardStore.setStartDate(dateValue.value.startDate);
  dashboardStore.setEndDate(dateValue.value.endDate);
});

// Watch for changes in the embeddedDashboard
watch(
  () => [dashboardStore.startDate, dashboardStore.endDate],
  ([startDate, endDate]) => {
    if (startDate === "" && endDate === "") {
      return;
    }
    try {
      const startDateFormatted = format(new Date(startDate), "yyyy-MM-dd");
      const endDateFormatted = format(new Date(endDate), "yyyy-MM-dd");

      // Update dateValue
      dateValue.value.startDate = startDateFormatted;
      dateValue.value.endDate = endDateFormatted;

      // Update placeholder
      initialPlaceholder.value = `${startDateFormatted} - ${endDateFormatted}`;
      dashboardStore.setStartDate(startDateFormatted);
      dashboardStore.setEndDate(endDateFormatted);
    } catch (error) {
      console.log(error);
    }
  }
);
</script>

<template>
  <vue-tailwind-datepicker
    v-model="dateValue"
    :disable-date="disableFutureDates"
    :shortcuts="customShortcuts"
    :placeholder="initialPlaceholder"
    :formatter="formatter"
    as-single
    use-range
    class="h-8"
  />
</template>
