const getCurrencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return "";
  }
};

const formatCurrency = (currency, value) => {
  const symbol = getCurrencySymbol(currency);

  return `${symbol}${value.toFixed(2)}`;
};

function getLastFullMonthDates() {
  const now = new Date();

  // Get the last day of the previous month
  const endDate = new Date(now.getFullYear(), now.getMonth(), 0);

  // Get the first day of the previous month
  const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);

  const formatDate = (date) => date.toISOString().split("T")[0];

  return [formatDate(startDate), formatDate(endDate)];
}

export { formatCurrency, getLastFullMonthDates };
