<template>
  <aside :class="`${sidebar_is_expanded && 'is-expanded'}`">
    <div class="menu-toggle-wrap">
      <button class="menu-toggle" @click="toggleMenu">
        <span class="material-icons">keyboard_double_arrow_right</span>
      </button>
    </div>

    <div class="menu">
      <div
        class="button"
        data-sheet-name="Analytics"
        :class="{ 'is-active-link': is_active_parent === 'Analytics' }"
        @click="toggleChildrenVisibility('Analytics')"
      >
        <span class="pi pi-fw pi-chart-bar mr-2"></span>
        <span class="text">Analytics</span>
      </div>
      <div
        class="children"
        :class="`${visibleChildren === 'Analytics' && 'visible-children'}`"
      >
        <li
          v-for="child in analyticsLinks.children"
          :key="child.label"
          class="child"
          :class="{
            'visible-children': visibleChildren === 'Analytics',
            'is-active-link': is_active_link === child.label,
          }"
          @click="handleItemClick(child, 'Analytics')"
        >
          {{ child.label }}
        </li>
      </div>
      <div
        class="button"
        data-sheet-name="catalog"
        :class="{ 'is-active-link': is_active_parent === 'catalog' }"
        @click="
          setServiceVisibility('Catalog', 'Catalog');
          toggleActiveLink('Catalog', 'Catalog');
        "
      >
        <span class="pi pi-fw pi-book mr-2"></span>
        <span class="text">Catalog</span>
        <Badge value="New" class="ml-4" />
      </div>
      <!-- <div
        class="button"
        data-sheet-name="reporting"
        :class="{ 'is-active-link': is_active_parent === 'Reporting' }"
        @click="
          setServiceVisibility('Reporting', 'Reporting');
          toggleActiveLink('Reporting', 'Reporting');
        "
      >
        <span class="material-icons">menu_book</span>
        <span class="text">Reporting</span>
      </div>
      <div
        class="button"
        data-sheet-name="overview"
        :class="{ 'is-active-link': is_active_parent === 'Overview' }"
        @click="
          setServiceVisibility('Overview', 'Overview');
          toggleActiveLink('Overview', 'Overview');
        "
      >
        <span class="material-icons">public</span>
        <span class="text">Overview</span>
      </div> -->
      <div
        class="button"
        data-sheet-name="Strategy"
        :class="{ 'is-active-link': is_active_parent === 'Strategy' }"
        @click="toggleChildrenVisibility('Strategy')"
      >
        <span class="pi pi-fw pi-chart-line mr-2"></span>
        <span class="text">Strategy</span>
      </div>
      <div
        class="children"
        :class="`${visibleChildren === 'Strategy' && 'visible-children'}`"
      >
        <li
          v-for="child in dashboardLinksStore.dashboardLinks.strategy.children"
          :key="child.label"
          class="child"
          :class="{
            'visible-children': visibleChildren === 'Strategy',
            'is-active-link': is_active_link === child.label,
          }"
          @click="handleItemClick(child, 'Strategy')"
        >
          {{ child.label }}
        </li>
      </div>
      <!-- <div
        class="button"
        data-sheet-name="stock"
        :class="{ 'is-active-link': is_active_parent === 'Stock Forecast' }"
        @click="
          setServiceVisibility('Stock Forecast', 'Stock Forecast');
          toggleActiveLink('Stock Forecast', 'Stock Forecast');
        "
      >
        <span class="material-icons">inventory</span>
        <span class="text">Stock Forecast</span>
      </div> -->
    </div>
  </aside>
</template>

<script setup>
import { ref, computed, defineEmits } from "vue";
import { useDashboardLinksStore } from "@/stores/DashboardLinksStore";
import { useDashboardStore } from "@/stores/DashboardStore";

// Toggles visibility of different services on myAccounts page
const emit = defineEmits([
  "toggle-data-explorer",
  "toggle-analytics",
  "toggle-catalog",
]);

// Getting the dashboard links and enriching with sheetIds
const dashboardStore = useDashboardStore();
const dashboardLinksStore = useDashboardLinksStore();
const analyticsLinks = computed(() => {
  return dashboardStore.currentDashboardId ===
    "7899d9c6-721a-4657-9719-b8902f4701d0"
    ? dashboardLinksStore.dashboardLinks.vendor_analytics
    : dashboardLinksStore.dashboardLinks.seller_analytics;
});

// Sidebar UX
const sidebar_is_expanded = ref(
  localStorage.getItem("sidebar_is_expanded") === "true"
);
const is_active_link = ref("Data Explorer");
const is_active_parent = ref("Strategy");

const visibleChildren = ref("Strategy");

const toggleMenu = () => {
  sidebar_is_expanded.value = !sidebar_is_expanded.value;

  localStorage.setItem("sidebar_is_expanded", sidebar_is_expanded.value);
};

const toggleChildrenVisibility = (label) => {
  if (visibleChildren.value === label) {
    visibleChildren.value = null;
  } else {
    visibleChildren.value = label;
  }
};

const toggleActiveLink = (label, parent) => {
  is_active_link.value = label;
  is_active_parent.value = parent;
};

const goToSheet = (sheetId, label) => {
  dashboardStore.embeddedDashboard.setSelectedSheetId(sheetId);
  dashboardStore.setCurrentDashboardSheet(label);
};

const setServiceVisibility = (item, child) => {
  switch (item) {
    case "Strategy":
      emit("toggle-data-explorer", true);
      emit("toggle-analytics", false);
      emit("toggle-catalog", false);
      break;
    case "Catalog":
      emit("toggle-catalog", true);
      emit("toggle-analytics", false);
      emit("toggle-data-explorer", false);
      break;
    case "Analytics":
      emit("toggle-analytics", true);
      emit("toggle-data-explorer", false);
      emit("toggle-catalog", false);
      goToSheet(child.sheetId, child.label);
      break;
  }
};

const handleItemClick = (child, item) => {
  setServiceVisibility(item, child);
  toggleActiveLink(child.label, item);
};
</script>

<style lang="scss" scoped>
aside {
  display: flex;
  flex-direction: column;
  width: calc(2rem + 32px);
  overflow: scroll;
  min-height: 92vh;
  padding-left: 1rem;
  background-color: var(--across-black);
  color: var(--white);

  transition: 0.2s ease-out;

  .flex {
    flex: 1 1 0;
  }

  .menu-toggle-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: -1rem;
    background-color: var(--across-purple);
    position: relative;
    transition: 0.2s ease-out;
    height: 4rem;
    padding-right: 0.75rem;

    .menu-toggle {
      transition: 0.2s ease-out;

      .material-icons {
        font-size: 2rem;
        color: var(--white);
        transition: 0.2s ease-out;
      }

      &:hover {
        .material-icons {
          color: var(--across-yellow);
          transform: translateX(0.5rem);
        }
      }
    }
  }

  .button .text,
  .children {
    opacity: 0;
    transition: 0.3s ease-out;
    list-style: none;
  }

  .children {
    display: none;
  }

  .menu {
    margin: 0 -1rem;
    margin-top: 2rem;

    .button {
      display: flex;
      align-items: center;
      text-decoration: none;

      padding: 0.5rem 1rem;
      transition: 0.2s ease-out;

      .material-icons {
        font-size: 2rem;
        color: var(--white);
        transition: 0.2s ease-out;
      }

      .text {
        color: var(--white);
        transition: 0.2s ease-out;
      }

      &:hover,
      &.is-active-link {
        background-color: var(--grey);

        .material-icons,
        .text {
          color: var(--across-yellow);
        }
      }

      &.is-active-link {
        border-right: 5px solid var(--across-yellow);
      }
    }
  }

  &.is-expanded {
    width: 15rem;

    .menu-toggle-wrap {
      .menu-toggle {
        transform: rotate(-180deg);
      }
    }

    .button .text,
    .children {
      opacity: 1;
      cursor: pointer;
    }

    .children {
      list-style: none;
      display: none;
      flex-direction: column;
      align-items: flex-start;

      .child {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        cursor: pointer;
        padding-left: 3.5rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }

    .visible-children {
      display: flex;
    }

    .button {
      .material-icons {
        margin-right: 1rem;
      }
    }

    .child {
      &:hover,
      &.is-active-link {
        background-color: var(--grey);
        color: var(--across-yellow);
      }
    }
  }

  @media (max-width: 768px) {
    position: fixed;
    z-index: 99;
  }
}
button {
  cursor: pointer;
  appearance: none;
  border: none;
  outline: none;
  background: none;
}
</style>
