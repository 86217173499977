<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

const formFields = {
  signIn: {
    username: {
      placeholder: "cooper",
      isRequired: true,
      label: "Username:",
    },
  },
};
</script>

<template>
  <authenticator hide-sign-up :form-fields="formFields">
    <template v-slot:sign-in-header>
      <div style="padding: var(--amplify-space-large); text-align: center">
        <img
          class="amplify-image"
          alt="Amplify logo"
          src="@/assets/AX-black.png"
        />
      </div>
      <h3 class="amplify-heading">Login To Your Account</h3>
    </template>
    <template v-slot="{ user, signOut }">
      <h1>Hello {{ user.username }}!</h1>
      <button @click="signOut">Sign Out</button>
    </template>
  </authenticator>
</template>

<style Lang="scss">
.amplify-button[data-variation="primary"] {
  background: var(--across-purple);
}

[data-amplify-authenticator] {
  --amplify-colors-background-primary: var(--across-purple);
  --amplify-colors-background-secondary: var(--across-purple);
  --amplify-colors-primary-10: var(--across-purple);
  --amplify-colors-primary-80: var(--across-purple);
  --amplify-colors-primary-90: var(--across-purple);
  --amplify-colors-primary-100: var(--across-purple);
  --amplify-colors-font-interactive: var(--across-purple);
  --amplify-components-tabs-item-active-color: var(--across-purple);
  --amplify-components-tabs-item-focus-color: var(--across-purple);
  --amplify-components-tabs-item-hover-color: var(--light-green);
  height: 100vh;
  padding-top: 12vh;
  background-color: #dddddd;
}
[data-amplify-authenticator] [data-amplify-container] {
  width: 500px;
  height: 590px;
}

[data-amplify-authenticator] [data-amplify-router] {
  width: 500px;
  height: 590px;
  background-color: #ffffff;
  border-radius: 15px;
  border-color: #ffffff;
  padding-top: 35px;
  padding-left: 80px;
  padding-right: 80px;
}

.amplify-button--link {
  --amplify-internal-button-color: var(--dark-green);
}

.amplify-tabs__item--active {
  border-color: var(--dark-green);
}

.amplify-image {
  width: 200px;
  height: 100px;
}

.amplify-heading {
  font-size: 1.5rem;
  font-family: "Abel", Inter, sans-serif;
  color: #3f434a;
}

.amplify-field-group :not(:first-child) .amplify-input {
  border-radius: 14px;
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
  border: 1px solid #e8e9eb;
}

.amplify-label {
  align-self: flex-start;
  color: #8a9099;
  font-family: "Abel", Inter, sans-serif;
}

.amplify-input {
  border-color: #e8e9eb;
  border-radius: 14px;
}
</style>
