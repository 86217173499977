<template>
  <div v-if="loading" class="h-full overflow-y-scroll p-6">
    <ProgressSpinner />
  </div>
  <div v-else class="h-full">
    <template v-if="!notifications || notifications.length == 0">
      <div v-if="subscribedToNotifications" class="flex flex-col">
        <p class="font-bold mb-3">No notifications found</p>
        <div class="flex justify-center items-center">
          <Button
            label="Refresh Data"
            icon="pi pi-refresh"
            class="p-button-secondary"
            @click="refreshData"
          />
        </div>
      </div>
      <div v-else class="flex flex-col">
        <p class="font-bold mb-3">
          This account is not subscribed to notifications, please contact
          administrator to subscribe.
        </p>
      </div>
    </template>
    <template v-else>
      <div class="flex justify-between items-center mb-4">
        <div class="flex items-center">
          <Button
            label="All"
            @click="shownNotificationType = 'ALL'"
            class="mr-2"
          />
          <Button
            label="Unread"
            @click="shownNotificationType = 'UNREAD'"
            class="mr-2"
          />
          <Button label="Read" @click="shownNotificationType = 'READ'" />
        </div>
        <Button
          label="Refresh Data"
          icon="pi pi-refresh"
          class="p-button-secondary"
          @click="refreshData"
        />
      </div>
      <div v-if="!shownNotifications" class="flex flex-col">
        <p class="font-bold mb-3">
          No {{ shownNotificationType }} notifications found
        </p>
      </div>
      <div v-else class="flex flex-col h-full overflow-y-scroll">
        <Card
          v-for="notification in shownNotifications"
          :key="notification.notificationId.S"
          class="shadow-md border border-gray-200 p-4 mb-4 bg-white"
        >
          <template #header>
            <div class="flex justify-between items-center">
              <div class="flex items-center">
                <span
                  v-if="notification.notificationStatus.S === 'UNREAD'"
                  class="block h-4 w-4 bg-purple-500 rounded-full mr-3"
                ></span>
                <span
                  v-else
                  class="block h-4 w-4 bg-gray-500 rounded-full mr-3"
                ></span>
                <h2 class="text-lg font-semibold">{{ notification.asin.S }}</h2>
              </div>
              <span class="text-gray-500 flex items-center">
                <i class="pi pi-clock mr-2"></i>
                {{ formatNotificationDate(notification.notificationDate.S) }}
              </span>
            </div>
          </template>
          <template #content>
            <div class="flex flex-wrap mt-2">
              <span
                v-for="attr in notification.attributesChanged.L"
                :key="attr.S"
                class="bg-purple-500 text-white px-6 py-3 rounded text-sm font-medium mr-2 mb-2"
                >New {{ removeUnderscores(attr.S) }}</span
              >
            </div>
          </template>
          <template #footer>
            <div
              class="mt-4 flex justify-between items-center text-sm text-gray-600"
            >
              <div
                v-if="notification.notificationStatus.S === 'UNREAD'"
                class="flex gap-4"
              >
                <button
                  class="hover:underline"
                  @click="
                    changeNotificationReadStatus(
                      notification.notificationId.S,
                      notification.notificationStatus.S
                    )
                  "
                >
                  Mark as Read
                </button>
              </div>
              <div v-else class="flex gap-4">
                <button
                  class="hover:underline"
                  @click="
                    changeNotificationReadStatus(
                      notification.notificationId.S,
                      notification.notificationStatus.S
                    )
                  "
                >
                  Mark as Unread
                </button>
              </div>
              <span>Changed by: {{ notification.matchingChanges.S }}</span>
            </div>
          </template>
        </Card>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, watch } from "vue";
import { useCustomFetcher } from "@/utils/callersForBackendApi";
import { scanDynamoDb } from "@/utils/dynamodb_functions";
import { useDashboardStore } from "@/stores/DashboardStore";

const props = defineProps({
  asin: String,
});

const emit = defineEmits(["notificationCount"]);

const dashboardStore = useDashboardStore();
const asin = ref(props.asin);
const notificationSubscriptionId = ref();
const notifications = ref();
const unreadNotifications = ref();
const readNotifications = ref();
const shownNotifications = ref();
const loading = ref(false);
const shownNotificationType = ref("UNREAD");
const subscribedToNotifications = ref(true);

const sortNotifications = (notifications) => {
  const unread = [];
  const read = [];
  notifications.forEach((notif) => {
    if (notif.notificationStatus.S === "UNREAD") {
      unread.push(notif);
    } else {
      read.push(notif);
    }
  });
  unreadNotifications.value = unread;
  readNotifications.value = read;
};

watch(
  () => shownNotificationType.value,
  (type) => {
    if (type === "ALL") {
      shownNotifications.value = notifications.value;
    } else if (type === "UNREAD") {
      shownNotifications.value = unreadNotifications.value;
    } else {
      shownNotifications.value = readNotifications.value;
    }
    sortNotificationsByDate(shownNotifications.value);
  }
);

// Fetch product type schema when component is mounted
onMounted(async () => {
  await fetchNotifications();
});

const fetchNotifications = async () => {
  try {
    loading.value = true;
    subscribedToNotifications.value = true;

    notificationSubscriptionId.value = await scanDynamoDb(
      createGetNotificationSubscriptionIdDynamoDbScanPayload(),
      dashboardStore.currentSellerId
    );

    // If no subscription ID is found, return
    if (!notificationSubscriptionId.value.length) {
      loading.value = false;
      subscribedToNotifications.value = false;
      return;
    }

    const getNotificationListPayload =
      createGetNotificationListDynamoDbScanPayload(
        notificationSubscriptionId.value[0]["subscriptionId"]["S"]
      );
    notifications.value = await scanDynamoDb(
      getNotificationListPayload,
      dashboardStore.currentSellerId
    );
    shownNotifications.value = notifications.value;
    sortNotifications(notifications.value);
    sortNotificationsByDate(shownNotifications.value);
    emit("notificationCount", unreadNotifications.value.length);
    loading.value = false;
  } catch (error) {
    loading.value = false;
    console.error("Error fetching notifications: ", error);
  }
};

// Function to sort notifications by date
const sortNotificationsByDate = (notifications) => {
  notifications.sort((a, b) => {
    const dateA = new Date(a.notificationDate.S);
    const dateB = new Date(b.notificationDate.S);
    return dateB - dateA; // Sort descending (most recent first)
  });
};

const refreshData = async () => {
  await fetchNotifications();
};

watch(
  () => dashboardStore.currentSellerId,
  async () => {
    try {
      // When the seller ID changes, fetch notifications
      loading.value = true;
      await fetchNotifications();
      loading.value = false;
      // await getAttributes(newVal);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }
);

const createGetNotificationListDynamoDbScanPayload = (subscriptionId) => {
  const table_name = "amazonNotifications";
  let filters;
  if (!asin.value) {
    filters = {
      subscriptionId: subscriptionId,
    };
  } else {
    filters = {
      asin: asin.value,
      subscriptionId: subscriptionId,
    };
  }

  return {
    table_name: table_name,
    filters: filters,
  };
};

const createGetNotificationSubscriptionIdDynamoDbScanPayload = () => {
  const table_name = "notificationSubscriptions";
  const filters = {
    sellerId: dashboardStore.currentSellerId,
  };

  return {
    table_name: table_name,
    filters: filters,
  };
};

const changeNotificationReadStatus = async (notificationId, readStatus) => {
  try {
    await toggleNotificationReadStatus(notificationId, readStatus);
    await refreshData();
  } catch (error) {
    console.error("Error marking notification as read:", error);
  }
};

const toggleNotificationReadStatus = async (notificationId, readStatus) => {
  try {
    const newReadStatus = readStatus === "UNREAD" ? "READ" : "UNREAD";

    const params = {
      queryParams: {
        notificationId: notificationId,
        newReadStatus: newReadStatus,
      },
    };

    const result = await useCustomFetcher("/markNotificationRead", params);
    return result.body;
  } catch (error) {
    console.error("Error fetching metrics:", error);
  } finally {
    // filtersLoading.value = false;
  }
};

const removeUnderscores = (str) => {
  return str.replace(/_/g, " ");
};

const formatNotificationDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  return formattedDate;
};
</script>

<style lang="scss" scoped></style>
